export const en = {
  yes: 'Yes',
  no: 'No',
  appname: 'RCH',
  lxMedical: 'LX Medical',
  signin: 'Sign In',
  signout: 'Sign Out',
  logout: 'Logout',
  createAccount: 'Create an Account',
  createAccountCap: 'Create An Account',
  forgotpassword: 'Forgot Password?',
  fullname: 'Full Name',
  email: 'Email',
  'new.email': 'New Email',
  password: 'Password',
  confirmPassword: 'Confirm Password',
  resetPassword: 'Reset Password',
  setPassword: 'Set Password',
  createPassword: 'Create Password',
  selectPlan: 'Select Plan',
  submit: 'Submit',
  forgotPassword: 'Forgot Password? ',
  continue: 'Continue',
  getStarted: 'Get Started',
  adminpassword: 'Admin Password',
  profile: 'Profile',
  'profile.details': 'Profile Details',
  adminprofile: 'Admin Profile',
  settings: 'Settings',
  dashboard: 'Dashboard',
  notifications: 'Notifications',
  users: 'Users',
  cancel: 'Cancel',
  previous: 'Previous',
  save: 'Save',
  confirm: 'Confirm',
  verify: 'Verify',
  deny: 'Deny',
  update: 'Update',
  edit: 'Edit',
  action: 'Action',
  firstname: 'First Name',
  lastname: 'Last Name',
  userID: 'User ID',
  searchUserID: 'Search User ID',
  'search.user': 'Search User',
  search: 'Search',
  preferredName: 'Preferred Name',
  'preferredName.optional': 'Preferred Name (Optional)',
  mobile: 'Mobile Number',
  phone: 'Phone Number',
  'phone.optional': 'Phone Number (Optional)',
  dob: 'Date of Birth',
  gender: 'Gender',
  'email.notification': 'Email Notifications',
  'text.notification': 'Text Notifications',
  'same.address': 'Same as account address',
  'same.subscription': 'Carry Account Subscription',
  active: 'Active',
  book: 'Book',
  inActive: 'In-Active',
  'your.address': 'Your Address',
  address: 'Address',
  'are.you.patient': 'Are you the patient? ',
  'how.did.you.find': 'How did you find out about us?',
  'entry.instruction': 'Special entry instruction',
  address1: 'Address Line 1',
  address2: 'Address Line 2',
  'address2.optional': 'Address Line 2 (Optional)',
  postalCode: 'Postal Code',
  city: 'City',
  state: 'State',
  country: 'Country',
  'profile.detail': 'Profile Details',
  'profile.detail.edit': 'Edit Profile Details',
  'profile.image.upload': 'Change Profile Image',
  'upload.new.picture': 'Upload',
  resend: 'Resend',
  'email.phone': 'Email/Phone',
  salesForceId: 'salesforce id',
  'email.exist': 'Eamil already exists.',

  'remember.me': 'Remember me',

  //Web Form Request Appointment
  'request.appointment': 'Request Appointment',
  'patient.info': 'Patient Info',
  'date.and.time': 'Date and Location',
  'contact.info': 'Contact Info',
  'important.info': 'Important Information',
  'form.heading.1': 'Tell us about the patient',
  'form.heading.2': 'Select Primary Services',
  'form.heading.3': 'When do you want us to come?',
  'form.heading.4': 'Where will we meet?',
  'info.emrgency.message': 'If this is an emergency, please call 911',
  'info.point.1': 'Standard service hours are Monday - Friday - 8:00 AM - 5:00 PM.',
  'info.point.2':
    'Requests made before 4 PM CST will receive a same-day reply. Requests made after 4 PM CST will be recieve a reply the following day.',
  'info.point.3':
    'Please allow for a 30 minute window from appointment time for our team to arrive.',

  'web.admin.appointment': 'Web Appointments',
  'web.admin.appointment.list': 'Web Appointments List',
  'web.admin.appointment.detail': 'Web Appointment Details',
  'web.attach.patient': 'Attach Patient',
  'web.add.new.patient': 'Add New Patient',
  'created.at': 'Created At',
  'attach.patient': 'Attach Patient',
  'add.appointment.patient': 'Add Patient',
  'web.user.detail': 'User Details',
  'location.type': 'Location Type',
  'entry.instruction.2': 'Entry Instructions',
  'web.appointment.cancel': 'Appointment Cancelled',
  'web.search.bar': 'Search by patient, email or phone...',
  'web.appointment.cancel.notify': 'Do you want to notify patient?',

  //Super Admin Menu

  'super.admin.logs': 'Admin Logs',
  'admin.log.date': 'Date',
  'admin.log.time': 'Time',
  'super.admin.logs.list': 'Admin Logs List',
  'super.admin.accounts': 'Admin Accounts',
  'super.admin.account.list': 'Admin Accounts List',
  'super.admin.create': 'Create Admin',
  'super.admin.create.account': 'Create Admin Account',
  'super.admin.edit.account': 'Edit Admin Details',
  'super.admin.search.placeholder': 'Search for name or email.',
  'search.super.admin.logs': 'Search in logs...',
  'super.admin.apply.filter': 'Apply Filters',
  'super.admin.edit.filter': 'Edit Filters',
  'super.admin.export.list': 'Reports',
  'super.admin.add.data': 'Add Data',
  'super.admin.download.csv': 'Download CSV',
  'super.admin.download.services.csv': 'Download Services CSV',
  'disable.export.services.csv': 'No service was selected at the time of adding filters',
  'super.admin.sales.tax': 'Sales Tax',
  'super.admin.sales.tax.history': 'Sales Tax History',
  'view.tax.history': 'View Tax History',
  'add.tax.rate': 'Add Tax Rate',
  'edit.tax.rate': 'Edit Tax Rate',
  'total.sales.tax': 'Total Sales Tax is',
  'total.sales.tax.rate': 'Total Sales Tax',
  'update.tax.applicability.date': 'Update TAD',
  'set.current.date': 'Choose current date',

  'admin.actions': 'Admin Actions',

  'admin.active': 'Are you sure, you want to set this Admin as "Active"?',
  'admin.inactive': 'Are you sure, you want to set this Admin as "In-Active"?',
  'admin.remove': 'Are you sure, you want to delete this admin profile?',

  'export.category1': 'Category 1',
  'export.category2': 'Category 2',
  'inOffice.addresses': 'In Office Addresses',
  invoice: 'Invoice',
  'select.all': 'Select All',
  'super-admin.inHome.visit': 'In Home Visit',
  'export.preview': 'Save and Preview',
  'select.appointment.date.range': 'Appointment Date Range',
  select: 'Select...',
  'super.admin.PII.confirmation': 'CONFIDENTIAL HEALTH INFORMATION ALERT',
  'super.admin.hippa.confirmation':
    'You are attempting to access Protected Health Information (PHI), which is subject to strict privacy regulations under the Health Insurance Portability and Accountability Act (HIPAA).By continuing, you acknowledge your responsibility to uphold HIPAA standards and protect the privacy and security of individuals health information.',
  'appointment.status': 'Appointment Status',
  'select.gender': 'Select Gender',
  'include.all.null.appointment': 'Include all the appointments without appointment date',
  'update.sales.tax.confirmation': 'Are you sure you want to update the Sales Tax?',
  'percentage.validation.error': 'Sum of percentage should not be greater than 100',
  'include.cancelled.appointments': 'Include cancelled appointments',

  // Admin Menu

  'admin.users': 'Users',
  'admin.providers': 'Providers',
  'admin.provider.details': 'Provider Details',

  'admin.invitation': 'Invitations',
  'admin.pending.invites': 'Pending Invitations',

  'admin.chat': 'Chat',
  'admin.patients': 'Patients',
  'admin.appointment': 'Appointments',
  'admin.billing': 'Billings',
  'admin.billing.details': 'Billing Account Details',
  'admin.consented.billing.accounts': 'Consented Billing Accounts',
  'admin.primary.account.history': 'Primary Billing Accounts History',
  'admin.billing.account': 'Add Billing Account',
  'add.new.stripe.account.Id': 'Stripe Account ID',
  'add.stripe.account': 'Add Account',
  'stripe.account.name': 'Account Name',
  'admin.patient.details': 'Patient Details',
  'admin.patient.info': 'Info',
  'admin.notifications': 'Notifications',
  'admin.dashboard': 'Dashboard',
  'admin.cashRequest': 'Cash Requests',
  'admin.payment_Request': 'Payment Requests',
  'admin.request.payment': 'Request Payment',
  'admin.request.cost': 'Cost',
  'admin.last.request': 'Last Payment Request',
  'admin.card.payment': 'Card Payment',
  'card.on.file': 'Card on File',
  'admin.unVerified_payments': 'Unverified Payments',
  'admin.bml_payments': 'Bill Me Later Requests',
  'admin.profile': 'Profile',
  'admin.password': 'Change Password',
  'admin.settings': 'Settings',
  'admin.chat.with.lxmedical': 'Chat with LxMedical',
  'admin.soap.note.templates': 'SOAP Note Templates',
  'admin.soap.note.templates.list': 'SOAP Note Templates List',
  'admin.soap.note.templates.editor': 'SOAP Note Template',
  'admin.soap.note.templates.heading': 'SOAP Note Templates',
  'admin.create.soap.note.template': 'Create SOAP Note Template',
  'admin.edit.soap.note.template': 'Edit SOAP Note Template',
  'admin.create.template': 'Create Template',
  'template.name': 'Template Name',
  'template.delete.confirmation': 'Are you sure, you want to delete this SOAP note template?',
  'invoice.remove': 'Are you sure, you want to delete this invoice?',
  'invoice.id': 'Invoice ID',

  // Admin Dashboard
  'total.patients': 'Total number of Patients',
  'total.providers': 'Total number of Providers',
  'total.appointments': 'Total Appointments',
  'complete.appointments': 'Completed Appointments',
  'requested.appointments': 'Pending Approval Appointments',
  'accepted.appointments': 'Awaiting Time Appointments',
  'cancelled.appointments': 'Cancelled Appointments',
  'total.cash.amount': 'Total Cash Payments',
  'total.payments': 'Total Payments',
  'total.online.amount': 'Total Online Payments',
  'total.card_on_file.amount': 'Total Card On File Payments',
  'total.pos.amount': 'Total Square POS Payments',
  'total.bml.amount': 'Total Bill Me Later Payments',
  'total.verifiedPayments': 'Total Verified Payments',
  'total.unverifiedPayments': 'Total Unverified Payments',
  'total.bml_requests': 'Total Bill Me Later Requests',
  'view.all': 'View all',

  // Header Section
  'header.home': 'Home',
  'header.appointments': 'Appointments',
  'header.charts': 'Charts',
  'header.chat': 'Chat',
  'header.notifications': 'Notifications',
  'header.contact': 'Contact',

  // Charts Section
  'charts.addmember': 'Add Family Member',
  'charts.members': 'Members',

  'charts.patient.delete': 'Are you sure, you want to remove this member?',
  'charts.remove': 'Remove',
  'charts.history': 'Medical History',
  'charts.reports': 'Reports',
  'charts.history.past': 'Past Medical History',
  'charts.history.family': 'Family History',
  'charts.history.addfamily': 'Add Family History',
  'charts.history.allergies': 'Allergies',
  'charts.history.medications': 'Current Medications',
  'charts.history.surgical': 'Surgical History',
  'charts.select_your.illness': 'Select your Illness',
  'charts.select.illness': 'Select Illness',
  'charts.other.illness': 'Add other Illness...',
  'charts.describe.allergies': 'Describe your allergies',
  'charts.add.allergies': 'Add your allergies',
  'charts.describe.illness': 'Describe your Illness',
  'charts.describe.medications': 'Describe Current Medications',
  'charts.add.medications': 'Add Current Medications',
  'charts.describe.surgical': 'Describe Surgical History',
  'charts.add.surgical': 'Add Surgical History',
  'save.details': 'Save Details',
  appointment: 'Save Details',

  'save.and.continue': 'Save & Continue',
  'save.and.exit': 'Save & Exit',
  'checkbox.none': 'None',
  // 'book':'Book',
  'charts.textarea.max500': 'Max Limit is 500 Characters',
  'charts.textarea.max250': 'Max Limit is 250 Characters',
  'name.max25': 'Max Limit is 25 Characters',

  // Provider Section
  providers: 'Providers',
  'provider.invited': 'Invited Providers ',
  'provider.invite': 'Invite Provider',
  invite: 'Invite',
  'provider.source': 'Select Invite Source',
  'invited.on': 'Invited On',

  // Appointments
  'upcoming.appointments': 'Upcoming Appointments',
  'completed.appointments': 'Completed Appointments',
  'requested.on': 'Requested On',
  'scheduled.on': 'Scheduled On',
  'completed.on': 'Completed On',
  'requested.for': 'Requested For',
  'patient.provider.chat': 'Chat With Provider',

  // Payments
  'pay.now': 'Pay Now',

  //Invoice
  'admin.invoice.list': 'Custom Invoice List',
  'admin.invoice.details': 'Invoice Details',
  'service.list': 'Service List',
  'invoice.name': 'Invoice Name',
  'add.new.invoice': 'Add Invoice',
  'create.new.invoice': 'Create New Invoice',
  'type.of.services': 'Type of Services',
  'add.custom.service': 'Add Custom Service',

  // services
  units: 'Units',
  'admin.dx.codes': 'DX-Codes',
  'admin.dx.codes.list': 'DX-Codes & Symptoms',
  'admin.add.dx.codes': 'Add DX-Code',
  'dx.codes.name': 'DX-Codes',
  'dx.codes.symptoms': 'Symptoms',
  'dxcode.delete.confirm': 'Are you sure, you want to delete this DX-Code?',
  'label.dx.code': 'DX-Code',
  'label.dx.code.symptom': 'Symptom',
  'search.dxcode': 'Search by DX-code or Symptoms...',
  services: 'Services',
  'services.patient': 'Patient Services',
  'services.provider': 'Provider Services',
  'unitise.provider.service': 'Unit Based Service',
  'add.service': 'Add Service',
  'add.discount': 'Add Discount',
  'update.discount': 'Update Discount',
  'add.another.service': 'Add Another Service',
  'inactive.provider.service': 'Provider Inactive Services',
  'inactive.patient.service': 'Patient Inactive Services',
  'inactive.service': 'Inactive Services',
  'update.service': 'Update Service',
  'list.services': 'Services List',
  'list.services.patient': 'Patient Services List',
  'list.services.provider': 'Provider Services List',
  'search.services': 'Search Services',
  'service.name': 'Service Name',
  'service.description': 'Service Description',
  'service.category': 'Service Category',
  'service.category2': 'Service Category 2',
  'symptoms.list': 'Symptoms List',
  'service.rate': 'Service Rate',
  'service.type': 'Service Type',
  'service.CPTCode': 'CPT Code',
  'service.CPTCode.max15': 'Max Limit is 15 Characters',
  'service.textarea.max50': 'Max Limit is 50 Characters',
  'enable.service': 'Are you sure, you want to enable this service?',
  'disable.service': 'Are you sure, you want to disable this service?',
  'active.Provider': 'Are you sure, you want to set this provider as "Active"?',
  'inActive.Provider': 'Are you sure, you want to set this provider as "In-Active"?',
  'active.Patient': 'Are you sure, you want to set this patient as "Active"?',
  'inActive.Patient': 'Are you sure, you want to set this patient as "In-Active"?',
  CPTCode: 'CPT Code',

  // Landing Page
  'sign.in.sign.up': 'Sign In / Sign Up',
  welcome: 'Welcome to LX Medical',
  'concierge.medicine.to.home': 'Concierge medicine to the comfort of your home',
  becomeMember: 'Become a Member',
  'become.member.benefits':
    'When you become an LX Medical member, you get access to all that we have to offer at discounted prices, and priority scheduling.',
  'services.we.offer': 'Services we offer',
  'best.in.convenience.quality': 'Best in convenience. Best in quality. No more waiting rooms!',
  'featured.on.and.associations': 'Featured on & Associations',
  'download.app': 'Download our App',

  // Landing Page services tiles
  telemedicine: 'Telemedicine',
  'telemedicine.text':
    'Our providers are available to our members via telemedicine 7 days a week with priority access.',
  'inhome.urgent.care': 'In-Home Urgent Care',
  'inhome.urgent.care.text':
    'Our medical team is ready to come to you, whenever you need us, for all your urgent care needs.',
  'employee.health.benefits': 'Employee Health Benefits',
  'employee.health.benefits.text':
    "Your company's employees and key contributors have never seen a health benefit like this!",
  'inhome.labs.and.diagnostics': 'In-Home Labs & Diagnostics',
  'inhome.labs.and.diagnostics.text':
    'We bring the lab to you. Any blood work, rapid COVID/strep/flu/mono testing, and much more.',
  'inhome.aesthetics': 'In-Home Aesthetics',
  'inhome.aesthetics.text':
    'In-Home, discreet, customizable services for in-home aesthetics/cosmetics including BOTOX, fillers, Kybella, and facial products by Zo',
  'inhome.health.wellness.infusions': 'In-Home Heath and wellness infusions',
  'inhome.health.wellness.infusions.text':
    'In-Home infusions to get you better faster, and to get your mind and body optimized.',

  //Payment Card
  'admin.payment.cards': 'Manage Cards',
  'payment.card': 'Payment Card',
  'select.another.card': 'Select Another Card',
  'add.card.info': 'Add Payment Card',
  'add.card.details': 'Add Card Details',
  'add.card.number': 'Card Number',
  'add.card.name': 'Card Holder',
  'add.card.expiry': 'Expiry Date',
  'add.card.cvv': 'CVV Details',
  'add.card': 'Add Card',
  'mark.as.default': 'Mark as Default',
  'set.as.primary': 'Set as Primary',
  'card.number': 'Card Number :',
  'expirty.date': 'Expiry Date :',

  'cvv.required': 'CVV is required',
  'cvv.invalid': 'CVV is invalid',

  'card.required': 'Card number is required',
  'card.invalid': 'Card number is invalid',

  'card.expiry.required': 'Expiry date is required',
  'card.expiry.month.invalid': 'Month should not exceed 12',
  'card.expiry.invalid': 'Expiry date is invalid',

  'card.name.required': 'Card Holder name is required',
  'card.name.invalid': 'Card Holder name is invalid',

  'card.remove.confirm': 'Are you sure, you want to remove this Card?',

  //Patient Section
  'notfication.status': 'Notifications For All',
  'notfication.settings': 'Notification Settings',
  'notification.change.message': 'You can change notification settings for all patients.',
  patients: 'Patients',
  'add.patient': 'Add New Patient',
  'add.patient.details': 'Add Patient',
  'edit.patient': 'Edit Patient',
  'unlock.account': 'Unlock Account',
  'lock.account': 'Lock Account',
  'unlock.minor.account': 'Unlock Minor Account',
  'lock.minor.account': 'Lock Minor Account',
  'unlock.minor.confirmation': 'Are you sure, you want to unlock this minor account?',
  'lock.minor.confirmation': 'Are you sure, you want to lock this minor account?',
  'resend.set.Password': 'Resend Set Password Email',
  'resend.set.Password.confirmation':
    'You are about to resend a set password email to this account.',
  'patient.deceased': 'Deceased',
  'mark.as.deceased': 'Mark as Deceased',
  'mark.undeceased': 'Mark Un-Deceased',
  'mark.as.undeceased.confirmation':
    'Are you sure, you want to mark this patient as <b>Un-Deceased</b>?',
  'mark.as.deceased.confirmation':
    'Are you sure, you want to mark this patient as <b>Deceased</b>?',

  'account.activation.mail': 'Account Setup Email',
  'edit.patient.address': 'Edit Address',
  'patient.list': 'Patients',
  'joined.on': 'Joined On',
  'date.of.birth': 'DOB',

  'dea.num': 'DEA Number',
  'npi.num': 'NPI Number',
  'license.num': 'Licenese Number',
  'provider.type': 'Provider Type',
  appointment: 'Appointment',
  subscription: 'Subscription',
  'book.appointment': 'Book Appointment',
  'admin.change.membership': 'Are you sure, you want to change the membership of this patient?',
  'admin.new.mebership': 'New Membership',
  'admin.current.mebership': 'Current Membership',
  'patient.primary.symptoms': 'Primary Symptoms',
  'patient.no.primary.symptoms': 'No symptoms were added.',
  'patients.services.list': 'Patients Services List',
  'provider.services.list': 'Provider Services List',
  'apply.filters': 'Apply Filters',

  //Document
  'admin.patient.documents.list': 'Documents',
  'add.new.document': 'Add Document',
  'document.name': 'Filename',
  'document.category': 'Category',
  'document.source': 'Source',
  'document.type': 'File Type',
  'document.uploadAt': 'Uploaded Date',
  'category.SYMPTOM_IMAGES': 'Symptom Images',
  'category.REPORT': 'Report',
  'category.SOAP_NOTES': 'Soap Notes',
  'category.PATIENT_DOCUMENTS': 'Patient Documents',
  'category.OTHER_DOCUMENTS': 'Other Documents',
  'document.upload': 'Upload',
  'upload.document': 'Upload Document',
  'edit.document': 'Edit Document',
  'document.local.upload': 'Local Upload',
  'document.google.upload': 'Google Drive Upload',
  'document.upload.from.local.drive': 'Click here to upload from local drive',
  'view.document.report': 'View Documents Access Logs',
  'appointment.id.optional': 'Appointment ID (Optional)',

  //Consent Section
  'admin.consents': 'Consents',
  'consent.list.heading': 'Consents List',
  'consent.request.button': 'Send Request',
  'consent.give.button': 'Send Consent',
  'consent.from': 'Consent from',
  'consent.to': 'Consent to',
  'consent.mode': 'Consent Mode',

  'consent.form.heading': 'Consent Form Details',

  'consent.for': 'Consent for',
  'consent.request.type': 'Consent Type',

  'requesting.consent.heading': 'Request',

  'incoming.consent': 'Incoming',
  'outgoing.consent': 'Outgoing',

  'consent.approve': 'Approve',
  'consent.reject': 'Reject',
  'consent.withdraw': 'Withdraw',

  'heading.consent': 'Patient Consent to Treatment',
  'consent.validation': 'Please accept to continue',
  'patient.give.consent': 'I give my consent.',
  'consent.note':
    '**ONLY SIGN FOR THE PORTION OF THE CONSENT FORM THAT PERTAINS TO YOUR VISIT WITH US**',
  'approve.consent.confirm': 'Are you sure, you want to approve this Consent?',
  'approve.request.consent.confirm': 'Are you sure, you want to approve this Request?',
  'reject.consent.confirm': 'Are you sure, you want to reject this Consent?',
  'reject.request.consent.confirm': 'Are you sure, you want to reject this Request?',
  'withdraw.consent.confirm': 'Are you sure, you want to withdraw this Approved Consent?',
  'withdraw.request.consent.confirm': 'Are you sure, you want to withdraw this Approved Request?',
  //Patient Homepage
  greetings: 'Greetings',
  'how.can.we.help': 'How can we help you?',
  'urgent.care': 'In Home Urgent Care',
  'covid.testing': 'In Home Covid Testing',
  'wellness.infusions': 'In Home Wellness Infusions',
  'wellness.aesthetics': 'In Home Wellness Aesthetics',

  //Appointment booking section
  'edit.address': 'Edit Address',
  'save.address': 'Save Address',
  'service.address': 'Service Address',
  'primary.symptoms': 'Please describe your primary symptoms…',
  'time.slot': 'Preferred Time Slot',
  'preferred.date': 'Preferred Appointment Date',
  'appointment.date': 'Appointment Date',
  'preferred.time': 'Preferred Time',
  'appointment.time': 'Appointment Time',
  'telemedicineTime.time': 'Telemedicine Time',
  'select.patient': 'Select Patient',
  'select.address': 'Select Address',
  'morning.checkbox.label': 'Morning',
  'afternoon.checkbox.label': 'Afternoon',
  'evening.checkbox.label': 'Evening',
  'asap.checkbox.label': 'ASAP',
  'select.service': 'Select Service',
  'select.date.time': 'Select Schedule',
  'services.additional.fee.text':
    'Members receive 15% discount & $150 travel fee waived on invoice.',
  'add.family.member': 'Add Family Member',
  'add.family.member_symptom': 'Add Family Member & Symptoms',
  'add.another_family.member': 'Add Another Family Member',
  'add.another.member': 'Add Another Member',
  'edit.family.member': 'Edit Family Member',
  'add.minor.member_symptom': 'Add Family Member & Symptoms',
  'add.minor': 'Add Family Member',
  relationship: 'Relationship',
  'appointment.requested': 'Appointment Requested!',
  'appointment.request.message':
    'Your appointment request has been sent, we will be assigning a provider to you soon.',

  //appointment details
  'add.paymentRequestType': 'Payment Mode',
  'add.paymentRemarks': 'Payment Remarks (Optional)',
  'payment.remarks': 'Payment Remarks',
  'patient.esign': 'Patient E-sign',
  'add.filter': 'Add Filter',
  'calender.event': 'Calendar Event',
  'calender.event.description': 'Admin Calendar Note',
  'calender.event.update': 'Update Calendar',
  'payment.filter': 'Filter By Payment',
  'status.filter': 'Filter By Status',
  'appointment.details': 'Appointment Details',
  'services.availed': 'Services Availed',
  'services.requested': 'Services Requested',
  'services.ordered': 'Services Ordered',
  'vitals.and.medical.directives': 'Vitals and Provider Notification',
  'test.results': 'Test Results',
  'view.results': 'View Results',
  'reschedule.appointment': 'Reschedule Appointment',
  'change.provider': 'Change Provider',
  'add.comments': 'Add Comment…',
  'add.cancellationReason': 'Reason For Cancellation',
  'result.awaited': 'Results Awaited',
  'result.awaited.msg': 'We will notify you as soon as the results are uploaded.',
  'select.date': 'Select Date',
  'appointment.id': 'Appointment ID',
  'make.payment': 'Make Payment',
  'process.payment': 'Process Payment',
  'select.pos.reader': 'Please Select the Reader',
  'confirm.payment': 'Confirm Payment',
  'payment.details': 'Payment Details',
  'add.payment.details': 'Add Payment Details',
  'payment.method': 'Payment Method',
  'select.payment.method': 'Select your payment method',
  'payment.successful': 'Payment Successful!',
  'notify.soon': 'Thank you for choosing LX Medical! We will notify you with the results soon.',
  'tell.about.experience': 'Tell us a bit about your Experience...',
  'pay.online': 'Pay Online',
  'travel.fee': 'Travel Fee',
  'discount.15': 'Membership Benefits',
  'membership.savings': 'Membership Savings',
  'discount.heading': 'Additional Discount',
  'pay.with.cash': 'Pay By Cash',
  'pay.with.pos': 'Pay By Square POS',
  'pay.with.bml': 'Bill Me Later',
  'bml.pay.mode.confirmation': 'Are you sure, you want to generate Bill Me Later Request for',
  'total.payable': 'Total Payable',
  'total.paid': 'Total Paid',
  'total.invoice.amount': 'Total Amount',
  'appointment.cancel.confirmation': 'Are you sure, want to cancel this appointment?',
  'change.appointment.type': 'Are you sure, you want to change the appointment type ?',
  'change.appointment': 'Change Appointment Type',

  'change.appointment.status': 'Are you sure, you want to change the appointment status ?',
  'cancel.appointment': 'Cancel Appointment',
  'reactivate.appointment': 'Re-activate',
  'download.invoice': 'Download Invoice',
  'view.invoice': 'View Invoice',
  'view.invoice.history': 'View Invoice History',
  'invoice.history': 'Invoice History',
  'cancel.invoice': 'Cancel Invoice',
  'download.reimbursement': ' Download Insurance Reimbursement Form',
  'appointment.cancel.message': 'Your appointment has been cancelled.',
  'chat.patient.provider': 'Chat between Patient and Provider',

  //Prometheus Section
  'admin.promoetheus': 'Predictive 360',
  'send.questionnaire': 'Send Questionnaire',
  'resend.questionnaire': 'Resend',
  'view.test.report': 'View Test Reports',
  'view.questionare': 'View Questionare',
  'status.confirmation.text': 'Are you sure you want to change the status? ',
  'send.to.text': 'Send as Text',
  'send.to.email': 'Send to Email',
  'validate.email.or.phone': 'Either email or phone field should be selected',
  send: 'Send',
  'predictive.360': 'LX Predictive 360',
  'predictive.description': 'Guides user through a health questionnaire',
  'required.question': '* Indicates required question',
  'add.lab.result': 'Add Lab Result',
  'send.date': 'Send Date',
  'add.response': 'Add Response',
  'edit.response': 'Edit Response',
  'no.response': 'No Questions Found',
  prev: 'Prev',
  next: 'Next',
  'view.lab.result': 'Lab Results',
  'view.questionnaire.result': 'Questionnaire',
  'predictive.assessment': 'Predictive 360 Assessment',
  'download.and.attach': 'Download and Attach',
  'attach.appointment': 'Attach to Appointment',
  'download.and.attach.appointments': 'Download and Attach to Appointments',
  'search.patient': 'Searh by patient name, email',
  'add.other.field': 'Add Lab Test',
  'autosave.message': 'Responses are autosaved, submission is not required',
  'patient.name': 'Patient Name',

  //SignIN section
  'signin.detail': 'Please enter your credentials to sign in.',

  //SignOut message
  'signout.message': 'Are you sure, you want to sign out?',

  //forgotpassword section
  'forgotPassword.detail':
    'A 6 digit verification code will be sent to your registered Email Address.',
  'verify.email': 'Enter Verification Code',
  'verify.email.error': 'Verfication Code is not valid, please re-enter or resend Verfication code',
  'verify.email.content': 'A 6 digit verification code has been sent to your email address.',
  'verify.email_phone.content':
    'A 6 digit verification code has been sent to your email address and phone number.',
  'password.change.success': 'Your password has been set successfully.',
  'resend.code.info': "Haven't received the OTP?",
  'resend.code': 'Resend OTP',
  'resend.otp.info': "Haven't received the code?",
  'resend.otp': 'Resend Code',

  // validation
  'validation.password.min': 'Password should have min. 8 characters',
  'validation.password.invalid.space': 'Space not allowed',
  'validation.password.required': 'Required',
  'validation.email.alreadypresent': 'An account already exists with this email',
  'validation.phone.alreadypresent': 'An account already exists with this phone number',
  'validation.postalcode.min.digit': 'Minimum 3 digit required',
  'validation.postalcode.max.digit': 'Maximum limit is 5 digit',
  'validation.postalcode.number': 'Enter only numbers',
  'validation.postalcode.required': 'Postal Code required',
  'email.invalid': 'Please enter valid email',
  'validation.address.field': 'Address Not Valid',
  'validation.address.maxfield': 'Maximum limit is 90 characters',
  'validation.address.minfield': 'Minimum 4 characters required',
  'validation.firstname.invalid': 'First Name is not valid',
  'validation.firstname.required': 'First Name is required',
  'validation.firstname.min.digit': 'First Name must be between 2 to 32 characters',
  'validation.email.required': 'Required',
  'validation.email': 'Email is not valid',
  'validation.userid.alreadypresent': 'UserId already is in use',
  'validation.userid.not.found': 'User does not exist',
  'validation.userid.required': 'Required',
  'validation.userid.validate': 'Invalid. Only hyphen(-) and underscore(_) is allowed',
  'validation.userid.start.validate': 'Can not start with numbers & special characters',
  'max.validate.userid': 'Maximum 30 character are allowed',
  'min.validate.userid': 'Minimum 5 character are required',
  'validation.email.or.phone': 'At least one field is required(Email or Phone)',

  'validation.lastname.invalid': 'Last Name is not valid',
  'validation.lastname.required': 'Last Name is required',
  'validation.lastname.min.digit': 'Last Name must be between 3 to 32 characters',

  'password.validation.message':
    'Password should be atleast 8 characters and should contain combination of uppercase, lowercase & numbers',
  'validation.OldNewpassword.notsame': 'Old and new password can not be the same',
  'validation.OldNewpassword.same': 'Both passwords need to be the same',
  'validation.oldpassword.required': 'Old Password is required',
  'validation.confirmpassword.required': 'Confirm Password is required',
  'validation.newpassword.required': 'New Password is required',
  'validation.password.min.digit': 'Password is too short - should be 8 chars minimum',

  'validation.phone.invalid': 'Min. 10 digit required',
  'validation.phone.required': 'Required',
  required: 'Required',

  changePassword: 'Change Password',
  oldPassword: 'Old Password',
  newPassword: 'New Password',
  // confirmPassword : "Confirm Password",

  user: 'User',
  role: 'Role',
  createdAt: 'Created At',
  cancelledAt: 'Cancelled At',
  cancelledBy: 'Cancelled By',
  'cancelled.invoices': 'Cancelled Invoices',
  'active.invoice': 'Active Invoice',
  'paid.invoice': 'Paid Invoice',
  amount: 'Amount',
  updatedAt: 'Updated At',
  status: 'Status',

  //errors
  'file.type.error': 'Only jpeg, jpg, png, .pdf, doc, and docx files are allowed.',

  // searchbar
  'search.placeholder': 'Search...',
  'search.patient.placeholder': 'Search for name, preferred name, user Id or email.',
  'search.invoice.placeholder': 'Search by Patient Name / Invoice Name',
  'Patient.name': 'Patient Name',
  'search.patient.doc.placeholder': 'Search for filename and Appointment ID',
  'search.provider.placeholder': 'Search for name or email.',
  'search.invitedProvider.placeholder': 'Search for phone or email.',
  'search.booking.service.placeholder': 'Search a service...',
  'search.appointments': 'Search by patient or provider..',
  'search.by.patient.invoice': 'Search by patient or invoice Id...',
  'filter.date.range': 'Filter by Date Range..',

  noNotification: 'No notification yet.',
  noResult: 'No result found.',
  noCashRequest: 'No Cash Request found.',
  nopayment_request: 'No Payment Request found.',
  noData: 'No data found.',
  confirmation: 'Confirmation',
  'change.email': 'Change Email',
  'confirmation.logout': 'Are you sure, want to logout?',
  'confirmation.cancel.invoice': 'Are you sure you want to cancel the invoice?',

  //Signup
  'heading.disclosureForm': 'Disclosure of Out of Network',
  'condition.above': 'I accept the above conditions',
  'tnc.validation': 'Please accept Terms of Use & Privacy Policy',
  'signup.tnc': 'I accept to the Terms & conditions & Privacy Policies.',
  tnc: 'Terms & conditions',
  pnp: 'Privacy Policies',
  'about.pnp': 'Privacy Policy',
  'I.accept': 'I accept to the',
  'disclosure.validation': 'Please accept to continue',

  // profile detail
  'profile.information': 'Personal Information',
  'email.address': 'Email Address',
  'phone.number': 'Phone Number',
  'profile.edit': 'Edit Details',
  'profile.save': 'Save Details',

  // profile sidebar
  'profile.dropdown.heading': 'My Profile',
  'profile.dorpdown.viewprofile': 'View Profile',
  'profile.sidebar.heading.section1': 'Personal Information',
  'profile.sidebar.edit.heading.section1': 'Edit Personal Information',
  'profile.sidebar.heading.section2': 'Manage Membership Plan',
  'profile.sidebar.heading.section3': 'Account Settings',
  'profile.sidebar.heading.section4': 'About',

  // admin Patient

  'admin.patient.address': 'Address',

  'admin.patient.household': 'Household',
  'admin.patient.invoices': 'Invoices',
  'add.new.member': 'Add New Member',
  'add.existing.member': 'Add Existing Member',
  'confirm.delete.service': 'Are you sure, you want to delete this service?',
  'confirm.delete.Address': 'Are you sure, you want to delete this address?',

  //admin Patient Insurance
  'admin.patient.insurance.id': 'Insurance ID',
  'admin.patient.insurance.info': 'Insurance Details',
  'admin.patient.insurance.id.only': 'ID',
  'admin.patient.edit.insurance': 'Edit Insurance',
  'admin.patient.insurance.insurer': 'Insurer',
  'admin.patient.insurance.group': 'Group',
  'admin.patient.insurance.plan.name': 'Plan Name',
  'admin.patient.insurance.createdBy': 'Created By',
  'admin.patient.insurance.add': 'Add',
  'admin.patient.insurance.add2': 'Add Insurance',
  'remove.pharmacy.details': 'Remove Pharmacy',
  'admin.patient.insurance.delete.confirm':
    'Are you sure, you want to delete this insurance details?',
  'admin.patient.insurance.action.yes': 'Yes',
  'admin.patient.insurance.action.no': 'No',

  // pharmacy
  'admin.patient.pharmacy.details': 'Pharmacy Details',
  'admin.patient.pharmacy.name': 'Name of the pharmacy',
  'edit.pharmacy.details': 'Edit pharmacy details',
  'admin.patient.pharmacy.address1': 'Address1',
  'admin.patient.pharmacy.address2': 'Address2',
  'admin.patient.pharmacy.city': 'City',
  'admin.patient.pharmacy.state': 'State',
  'admin.patient.pharmacy.zip': 'Zip',
  'admin.patient.pharmacy.country': 'Country',
  'pharmacy.name': 'Pharmacy Name',
  'admin.patient.pharmacy.address': 'Pharmacy Address',
  'add.pharmacy': 'Add Pharmacy',
  'edit.pharmacy': 'Edit Pharmacy',
  'delete.pharmacy': 'Delete Pharmacy',
  'remove.pharmacy.confirmation': 'Are you sure, you want to remove the pharmacy?',

  //admin Appointment
  'insurance.and.pharmacy': 'Insurance and Pharmacy',
  reset: 'Reset',
  'admin.appointment.cash.received': 'Payment Received',
  'admin.appointment.address': 'Service Address',
  'admin.appointment.inOffice': 'In Office Visit',
  'admin.change.appointmentType': 'Change Appointment Type',
  'admin.change.appointmentStatus': 'Change Appointment Status',
  'create.update.callender.event': 'Update Calendar',
  'admin.appointment.cash.not_received': 'Payment Not Received',
  'admin.appointment.list': 'Appointments',
  'admin.notification': 'Notifications',
  'admin.book.appointment': 'Book Appointment',
  'admin.appointment.cashRequest.list': 'Cash Requests List',
  'admin.appointment.payment_request.list': 'Payment Requests List',
  'admin.unVerified.payments.list': 'Unverified Payments List',
  'admin.bml.request.list': 'Bill Me Later Requests List',
  'admin.bml.appointment.list': 'Appointments',
  'admin.bml.custom.list': 'Invoices',
  'admin.appointment.test': 'Services',
  'admin.appointment.status': 'Status',
  'admin.appointment.patients': 'Patient account',
  'admin.appointment.payment_mode': 'Payment Mode',
  'admin.appointment.confirm.payment': 'Please Confirm Payment Status For - ',
  'admin.appointment.verify.payment': 'Please Verify Payment For - ',
  'admin.appointment.deny.Cash':
    'Are you sure, you want to update the payment status to "Payment Not Received" by marking Deny?',
  'admin.appointment.provider': 'Provider',
  'admin.appointment.payment.status': 'Update Payment Status',
  'admin.appointment.confirm.bml.payment': 'Confirm Payment',
  'admin.verify.payment': 'Verify Payment',
  'admin.appointment.id': 'Appointment ID',
  'admin.appointment.requestedFor': 'Requested For',
  'admin.appointment.scheduledOn': 'Scheduled On',
  'admin.invoices': 'Custom Invoices',
  transactions: 'Transactions',
  'admin.appointment.appointmentDate': 'Appointment Date',
  'admin.appointment.email': 'Email',
  'admin.appointment.action': 'Actions',
  'admin.appointment.accept': 'Accept',
  'admin.appointment.reject': 'Reject',
  'admin.appointment.assign.provider': 'Assign Provider',
  'admin.appointment.add.report': 'Add Report',
  'admin.appointment.select.report': 'Select Report',
  'admin.appointment.report.added': 'Report Added',
  'admin.appointment.add.report.name': 'Report Name',
  'admin.appointment.add.comment': 'Add Comment For Provider',
  'admin.appointment.add.telemedicineLink': 'Telemedicine Link',
  'admin.service.filter': 'Add Services Filter',
  'edit.admin.service.filter': 'Edit Services Filter',
  'payment.pos': 'Pay by POS',
  'select.reader': 'Select Reader',
  'process.pos-payment.confirmation':
    'Are you sure, you want to process the payment for selected reader ?',
  //discount
  'admin.discount': 'Discount',
  'admin.discount.label': 'Discount Label',
  'discount.in.percentage': 'Discount <b>(in %)</b>',
  'discount.in.numbers': 'Discount <b>(in $)</b>',
  'discount.percentage.validation': 'Discount must be less than or equal to 100',
  'discount.dollar.validation': 'Discount must be less than or equal to total service amount',

  'admin.appointment.assign': 'Assign',
  'admin.appointment.select.provider': 'Select Provider',
  'admin.appointment.select.patient': 'Select Patient',
  'assigned.provider': 'Assigned Provider',
  'provider.assigned': 'Provider Assigned',
  'admin.appointment.reschedule': 'Reschedule Appointment',
  'admin.appointment.for': 'Appointment for',
  'admin.reschedule': 'Reschedule',
  'admin.chat.patient': 'Patient chats',
  'admin.chat.provider': 'Provider chats',
  'no.chat.patient.provider': 'No chat has been done yet.',
  'correct.url': 'Enter correct url!',
  'correct.num': 'Enter correct Phone number !',
  'meeting.link': 'Meeting Link / Phone number',
  'copy.link': 'Copy',
  'telemedicine.visit': 'Telemedicine Visit',
  'telemedicine.visit.description':
    'Please visit the meeting link below on the specified time or call on the phone number provided.',
  'phone.desc': 'patients Phone number',
  'provider.comment': 'Provider Comment',
  // 'adminComments.provider': 'Comment for Provider',
  'adminComments.medical.orders': 'Medical Orders',
  // 'adminComments.add.providerComment': 'Add Comment for Provider',
  'adminComments.noComments': 'No Comments were added',
  'providerNotes.admin': 'Provider Notes For Admin',
  'providerSummary.admin': 'Provider Summary For Admin',
  'appointment.notes': 'Appointment Notes',
  'provider.comment.patient': 'Provider → Patient Comments',
  'provider.soapnote': ' SOAP Note',
  'patient.request': 'Patient Request For Reschedule',
  'patient.rescheduleReason': 'Patient Reschedule Reason',
  'patient.cancellationReason': 'Patient Cancellation Reason',
  cancellationReason: 'Cancellation Reason',
  payments: 'Payments',
  'payment.type': 'Payment Type',
  'payment.stripeId': 'Stripe ID',
  'filter.type': 'Filter By Type',
  price: 'Price',
  remarks: 'Remarks',
  'provider.tax': 'Provider Tax',
  'sales.tax': 'Sales Tax',
  'update.provider.tax': 'Update Provider Tax',
  'provider.tax.field': 'Enter Provider Tax',
  'save.provider.tax': 'Are you sure, you want to save provider tax rate?',
  'action.on.payment': 'Action On Payment',
  'send.text': 'Send Text',
  'send.mail': 'Send Mail',

  'validation.service.rate': 'Service Rate must be a positive number',
  'payment.for': 'Payment For',
  'payment.date': 'Payment Date',
  'payment.updated.at': 'Last Updated At',

  'provider.tax.applied': 'Provider Tax Applied',
  'sales.tax.applied': 'Sales Tax Applied',
  'view.sales.tax.report': 'View Sales Tax Report',
  'appointment.book.tooltip': 'Patient is Inactive',
  'soapnote.filter': 'Filter By SOAP Note',
  'soapnote.submitted': 'Soap Note Submitted',
  'soapnote.not.submitted': 'Soap notes is not filled by provider',
  'select.office.address': 'Select Office Address',
  'office.address.text': 'Select office address from below',
  'appointment.type.text': 'Select Appointment Type From Below',
  'non-member.price': 'Non Member Price',
  'member.price': 'Member Price',

  'exclude.taxes': 'Excluding Taxes',
  'zip.code': 'Zip Code',
  'tax.applicability.date': 'Tax Applicability Date',
  label: 'Label',
  'tax.type': 'Tax Type',
  'tax.percentage': 'Tax Percentage',

  //p360
  'status.filter.incompleted':'Incompleted',
  'status.filter.completed':'Completed',

  //memberships 
  'admin.patient.memberships': 'Memberships',
};

export type LocaleString = keyof typeof en;
