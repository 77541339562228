import { useCallback, useReducer } from 'react';
import { toast } from 'react-toastify';
import { createContainer, createReducer, createAsyncActions, createAction } from 'utils/context';
import axiosService from '../utils/api/axios';

const initialState = {
  appointmentsList: { data: [], meta: {} },
  appointmentsLoading: false,
  bmlInvoiceList: { data: [], meta: {} },
  bmlInvoiceListLoading: false,
  webAppointmentList: { data: [], meta: {} },
  webAppointmentLoading: false,
  AppointmentDetails: '',
  webAppointmentDetails: '',
  appointmentStatusLoading: false,
  reactivateLoading: false,
  serviceAddressLoading: false,
  taxRateObj: {},
  taxRateLoading: false,
};
const actions = {
  getAppointmentList: createAsyncActions('GET_APPOINTMENT_LIST'),
  getBmlInvoiceList: createAsyncActions('GET_BML_INVOICE_LIST'),
  getWebAppointmentList: createAsyncActions('GET_WEB_APPOINTMENT_LIST'),
  appointmentDetails: createAsyncActions('APPOINTMENT_DETAILS'),
  webAppointmentDetails: createAsyncActions('WEB_APPOINTMENT_DETAILS'),
  setAppointmentStatusLoader: createAction('SET_APPOINTMENT_STATUS'),
  setReactivateLoader: createAction('SET_REACTIVATE_LOADER'),
  setServiceAddressLoader: createAction('SET_SERVICE_ADDRESS'),
  getTaxRate: createAsyncActions('GET_TAX_RATE'),
};

const appointmentReducer = createReducer({
  [`${actions.getAppointmentList.loading}`]: (state) => ({
    ...state,
    appointmentsLoading: true,
  }),
  [`${actions.getAppointmentList.success}`]: (state, { payload }) => ({
    ...state,
    appointmentsList: payload,
    appointmentsLoading: false,
  }),
  [`${actions.getAppointmentList.failure}`]: (state) => ({
    ...state,
    appointmentsLoading: false,
  }),

  [`${actions.getBmlInvoiceList.loading}`]: (state) => ({
    ...state,
    bmlInvoiceListLoading: true,
  }),
  [`${actions.getBmlInvoiceList.success}`]: (state, { payload }) => ({
    ...state,
    bmlInvoiceList: payload,
    bmlInvoiceListLoading: false,
  }),
  [`${actions.getBmlInvoiceList.failure}`]: (state) => ({
    ...state,
    bmlInvoiceListLoading: false,
  }),

  [`${actions.getWebAppointmentList.loading}`]: (state) => ({
    ...state,
    webAppointmentLoading: true,
  }),
  [`${actions.getWebAppointmentList.success}`]: (state, { payload }) => ({
    ...state,
    webAppointmentList: payload,
    webAppointmentLoading: false,
  }),
  [`${actions.getWebAppointmentList.failure}`]: (state) => ({
    ...state,
    webAppointmentLoading: false,
  }),
  [`${actions.appointmentDetails.loading}`]: (state) => ({
    ...state,
    appointmentsLoading: true,
  }),
  [`${actions.appointmentDetails.success}`]: (state, { payload }) => ({
    ...state,
    AppointmentDetails: payload,
    appointmentsLoading: false,
  }),
  [`${actions.appointmentDetails.failure}`]: (state) => ({
    ...state,
    appointmentsLoading: false,
  }),

  [`${actions.webAppointmentDetails.loading}`]: (state) => ({
    ...state,
    webAppointmentLoading: true,
  }),
  [`${actions.webAppointmentDetails.success}`]: (state, { payload }) => ({
    ...state,
    webAppointmentDetails: payload,
    webAppointmentLoading: false,
  }),
  [`${actions.webAppointmentDetails.failure}`]: (state) => ({
    ...state,
    webAppointmentLoading: false,
  }),
  [actions.setAppointmentStatusLoader.toString()]: (state, { payload }) => ({
    ...state,
    appointmentStatusLoading: payload,
  }),
  [actions.setReactivateLoader.toString()]: (state, { payload }) => ({
    ...state,
    reactivateLoading: payload,
  }),
  [actions.setServiceAddressLoader.toString()]: (state, { payload }) => ({
    ...state,
    serviceAddressLoading: payload,
  }),

  // setting tax rate state
  [`${actions.getTaxRate.loading}`]: (state) => ({
    ...state,
    taxRateLoading: true,
  }),
  [`${actions.getTaxRate.success}`]: (state, { payload }) => ({
    ...state,
    taxRateObj: payload,
    taxRateLoading: false,
  }),
  [`${actions.getTaxRate.failure}`]: (state) => ({
    ...state,
    taxRateLoading: false,
  }),
});

export const { useContext: useAdminAppointment, Provider: AdminAppointmentProvider } =
  createContainer(() => {
    //
    const [state, dispatch] = useReducer(appointmentReducer, initialState);

    const getAppointmentList = useCallback(
      async (
        pageNum = '1',
        take?: string | number,
        query?: string,
        startDate?: string,
        endDate?: string,
        category?: any,
        paymentMode?: string,
        soapNoteSubmitted?: string,
        paymentVerified?: boolean,
        paymentRequest?: boolean,
        requestType?: string,
        sortVariable?: string,
        sortOrder?: string,
        active?: boolean,
        providerServiceIds?: String[],
        patientServiceIds?: String[],
      ) => {
        try {
          dispatch(actions.getAppointmentList.loading());
          let api = `/appointment?searchQuery=${query}&category=${category || ''}&startDate=${
            startDate || ''
          }&endDate=${endDate || ''}&page=${pageNum}&take=${take || 10}`;

          if (typeof paymentVerified === 'boolean')
            api = `/appointment?searchQuery=${query}&category=${category || ''}&startDate=${
              startDate || ''
            }&endDate=${endDate || ''}&paymentVerified=${paymentVerified}&page=${pageNum}&take=${
              take || 10
            }`;

          if (paymentMode) api = api + `&paymentMode=${paymentMode}`;

          if (soapNoteSubmitted)
            api = api + `&soapNoteSubmitted=${soapNoteSubmitted === 'SUBMITTED' ? true : false}`;

          if (paymentRequest) api = api + `&paymentRequest=${paymentRequest}`;

          if (requestType) api = api + `&requestType=${requestType}`;
          if (sortVariable && sortOrder) {
            api = api + `&sortVariable=${sortVariable}&sortOrder=${sortOrder}`;
          }
          if (active) api = api + `&active=${false}`;
          if (providerServiceIds?.length) api = api + `&providerServiceIds=${providerServiceIds}`;
          if (patientServiceIds?.length) api = api + `&patientServiceIds=${patientServiceIds}`;
          else api = api + `&active=${true}`;
          //  else {
          //   if ((startDate && endDate) || category || paymentMode)
          //     api = api + `&sortOrder=ASC`;
          // }
          const { data } = await axiosService?.get(api);
          dispatch(actions.getAppointmentList.success(data?.data?.appointments));

          return data;
        } catch (e: any) {
          toast.error(e?.response?.data?.message);
          dispatch(actions.getAppointmentList.failure());
        }
        return undefined;
      },
      [],
    );

    const getInvoiceBMLList = useCallback(
      async (
        pageNum = '1',
        take?: string | number,
        query?: string,
        startDate?: string,
        endDate?: string,
        category?: any,
        paymentMode?: string,
        paymentVerified?: boolean,
        paymentRequest?: boolean,
        requestType?: string,
        sortVariable?: string,
        sortOrder?: string,
        active?: boolean,
      ) => {
        try {
          dispatch(actions.getBmlInvoiceList.loading());
          let api = `/appointment/invoices?invoiceStatus=BML&searchQuery=${query}&category=${
            category || ''
          }&startDate=${startDate || ''}&endDate=${endDate || ''}&page=${pageNum}&take=${
            take || 10
          }`;

          if (typeof paymentVerified === 'boolean')
            api = `/appointment/invoices?invoiceStatus=BML&searchQuery=${query}&category=${
              category || ''
            }&startDate=${startDate || ''}&endDate=${
              endDate || ''
            }&paymentVerified=${paymentVerified}&page=${pageNum}&take=${take || 10}`;

          if (paymentMode) api = api + `&paymentMode=${paymentMode}`;

          if (paymentRequest) api = api + `&paymentRequest=${paymentRequest}`;

          if (requestType) api = api + `&requestType=${requestType}`;
          if (sortVariable && sortOrder) {
            api = api + `&sortVariable=${sortVariable}&sortOrder=${sortOrder}`;
          }

          if (active) api = api + `&active=${false}`;
          else api = api + `&active=${true}`;
          //  else {
          //   if ((startDate && endDate) || category || paymentMode)
          //     api = api + `&sortOrder=ASC`;
          // }
          const { data } = await axiosService?.get(api);
          dispatch(actions.getBmlInvoiceList.success(data?.data?.invoices));

          return data;
        } catch (e: any) {
          toast.error(e?.response?.data?.message);
          dispatch(actions.getBmlInvoiceList.failure());
        }
        return undefined;
      },
      [],
    );

    const getWebAppointmentList = useCallback(
      async (
        pageNum = '1',
        take?: string | number,
        query?: string,
        startDate?: string,
        endDate?: string,
        status?: any,
        sortVariable?: string,
        sortOrder?: string,
      ) => {
        try {
          dispatch(actions.getWebAppointmentList.loading());
          let api = `/appointment/web-appointments?searchQuery=${query}&startDate=${
            startDate || ''
          }&endDate=${endDate || ''}&page=${pageNum}&take=${take || 10}`;

          if (status.length > 0) {
            api = api + `&status=${status[0]}`;
          }
          if (sortVariable && sortOrder) {
            api = api + `&sortVariable=${sortVariable}&sortOrder=${sortOrder}`;
          } else {
            api = api + `&sortVariable=createdAt&sortOrder=DESC`;
          }
          //  else {
          //   if ((startDate && endDate) || category || paymentMode)
          //     api = api + `&sortOrder=ASC`;
          // }
          const { data } = await axiosService?.get(api);
          dispatch(actions.getWebAppointmentList.success(data?.data?.appointments));

          return data;
        } catch (e: any) {
          toast.error(e?.response?.data?.message);
          dispatch(actions.getWebAppointmentList.failure());
        }
        return undefined;
      },
      [],
    );

    const getPatientAppointmentList = useCallback(
      async (
        PageNum = '1',
        take?: string | number,
        query?: string,
        startDate?: string,
        endDate?: string,
        category?: any,
        paymentMode?: string,
        sortVariable?: string,
        sortOrder?: string,
        patientID?: string,
      ) => {
        try {
          dispatch(actions.getAppointmentList.loading());
          let api = `/appointment?patientId=${patientID}&page=${PageNum}&take=${take || 10}`;
          if (query) api = api + `&searchQuery=${query}`;
          if (startDate) api = api + `&startDate=${startDate}`;
          if (endDate) api = api + `&endDate=${endDate}`;
          if (category) api = api + `&category=${category}`;
          if (paymentMode) api = api + `&paymentMode=${paymentMode}`;
          if (sortVariable && sortOrder) {
            api = api + `&sortVariable=${sortVariable}&sortOrder=${sortOrder}`;
          }
          // if (sortBy) {
          //   api = api + `&requestedDateSort=${sortBy}`;
          // } else {
          //   if ((startDate && endDate) || category || paymentMode)
          //     api = api + `&requestedDateSort=ASC`;
          // }
          const { data } = await axiosService?.get(api);
          dispatch(actions.getAppointmentList.success(data?.data?.appointments));

          return data;
        } catch (e: any) {
          toast.error(e?.response?.data?.message);
          dispatch(actions.getAppointmentList.failure());
        }
        return undefined;
      },
      [],
    );

    const getAppointmentDetails = useCallback(async (id: string) => {
      try {
        dispatch(actions.appointmentDetails.loading());

        const { data } = await axiosService?.get(`/appointment/detail/${id}`);
        dispatch(actions.appointmentDetails.success(data?.data));

        return data?.data;
      } catch (e: any) {
        toast.error(e?.response?.data?.message);
        dispatch(actions.appointmentDetails.failure());
      }
      return undefined;
    }, []);

    const cancelInvoice = useCallback(async (id: string) => {
      try {
        const { data } = await axiosService?.get(`/appointment/${id}/cancel-stripe-invoice`);
        // toast.success(data.message);
        return data;
      } catch (e: any) {
        toast.error(e?.response?.data?.message);
      }
      return undefined;
    }, []);

    const getWebAppointmentDetails = async (id: string) => {
      try {
        dispatch(actions.webAppointmentDetails.loading());

        const { data } = await axiosService?.get(`/appointment/web-appointment-detail/${id}`);
        dispatch(actions.webAppointmentDetails.success(data?.data));
        return data;
      } catch (e: any) {
        toast.error(e?.response?.data?.message);
        dispatch(actions.webAppointmentDetails.failure());
      }
      return undefined;
    };

    const attachPatient = useCallback(async (formData, callback) => {
      try {
        const { data } = await axiosService?.post(`/appointment/attach-patient`, {
          ...formData,
        });

        toast.success(data.message);
        if (typeof callback === 'function') {
          callback();
        }
        return data;
      } catch (e: any) {
        toast.error(e?.response?.data?.message);
      }
      return undefined;
    }, []);

    const createPatient = useCallback(async (formData, callback) => {
      try {
        const { data } = await axiosService?.post(`/appointment/create-patient`, {
          ...formData,
        });

        toast.success(data.message);
        if (typeof callback === 'function') {
          callback();
        }
        return data;
      } catch (e: any) {
        toast.error(e?.response?.data?.message);
      }
      return undefined;
    }, []);

    const cancelWebAppointment = async (formData: any) => {
      try {
        const { data } = await axiosService?.post(`appointment/cancel-web-appointment`, {
          ...formData,
        });
        toast.success(data?.message);
        getWebAppointmentDetails(formData?.webAppointmentId);
        return data;
      } catch (e: any) {
        toast.error(e?.response?.data?.message);
      }
      return undefined;
    };

    const get_Chats_Patient_Provider = async (id: string) => {
      try {
        const { data } = await axiosService?.get(`/appointment/${id}/chats`);
        return data?.data?.chats;
      } catch (e: any) {
        toast.error(e?.response?.data?.message);
      }
      return undefined;
    };

    const changeAppointmentStatus = async (id: string, status: string) => {
      dispatch(actions.setAppointmentStatusLoader(true));
      try {
        const { data } = await axiosService?.post(`/appointment/change-status`, {
          appointmentId: id,
          appointmentStatus: status,
        });
        dispatch(actions.setAppointmentStatusLoader(false));
        getAppointmentDetails(id);
        return data;
      } catch (e: any) {
        dispatch(actions.setAppointmentStatusLoader(false));

        toast.error(e?.response?.data?.message);
      }
      return undefined;
    };

    const changeAppointmentType = async (id: string, selectedOption: String) => {
      try {
        const response = await axiosService?.post(
          `/appointment/change-appointment-type/${id}?type=${selectedOption}`,
          {},
        );
        toast.success(response?.data?.message);
        return response;
      } catch (e: any) {
        toast.error(e?.response?.data?.message);
      }
      return undefined;
    };

    const updateAppointmentAddress = async (id: string, address: any) => {
      dispatch(actions.setServiceAddressLoader(true));
      try {
        const { data } = await axiosService?.post(`/appointment/update-appointment-address`, {
          id: id,
          serviceAddress: address,
        });
        dispatch(actions.setServiceAddressLoader(false));
        getAppointmentDetails(id);
        return data;
      } catch (e: any) {
        dispatch(actions.setServiceAddressLoader(false));

        toast.error(e?.response?.data?.message);
      }
      return undefined;
    };

    const AdminConfirmBMLPayment = async (fomrData: any) => {
      try {
        const { data } = await axiosService?.post(`/appointment/confirm-payment`, {
          ...fomrData,
        });
        toast.success(data?.message);
        return data;
      } catch (e: any) {
        toast.error(e?.response?.data?.message);
      }
      return undefined;
    };

    const AdminConfirmCustomInvoicePayment = async (fomrData: any) => {
      try {
        const { data } = await axiosService?.post(`/appointment/confirm-payment-for-invoice`, {
          ...fomrData,
        });
        toast.success(data?.message);
        return data;
      } catch (e: any) {
        toast.error(e?.response?.data?.message);
      }
      return undefined;
    };

    const AdminVerifyPayment = async (id: string, formData: string) => {
      try {
        const { data } = await axiosService?.put(`/appointment/verify-payment/${id}`, {
          paymentRemarks: formData,
        });
        toast.success(data?.message);
        return data;
      } catch (e: any) {
        toast.error(e?.response?.data?.message);
      }
      return undefined;
    };

    const assignProvider = async (formData: any) => {
      try {
        const { data } = await axiosService?.post(`/appointment/assign-provider`, {
          ...formData,
        });

        getAppointmentDetails(formData?.appointmentId);
        return data;
      } catch (e: any) {
        toast.error(e?.response?.data?.message);
      }
      return undefined;
    };

    const ReAssignProvider = async (formData: any) => {
      try {
        const { data } = await axiosService?.post(`/appointment/change-provider`, {
          ...formData,
        });
        toast.success(data?.message);

        getAppointmentDetails(formData?.appointmentId);
        return data;
      } catch (e: any) {
        toast.error(e?.response?.data?.message);
      }
      return undefined;
    };
    const changeComment = async (formData: any, id: any) => {
      try {
        const { data } = await axiosService?.put(`/appointment/update-admin-comment/${id}`, {
          ...formData,
        });
        toast.success(data?.message);

        data && getAppointmentDetails(id);
        return data;
      } catch (e: any) {
        toast.error(e?.response?.data?.message);
      }
      return undefined;
    };

    const AddReports = async (formData: any) => {
      try {
        const { data } = await axiosService?.post(`/appointment/add-report`, {
          ...formData,
        });
        toast.success(data?.message);
        getAppointmentDetails(formData?.appointmentId);
        return true;
      } catch (e: any) {
        toast.error(e?.response?.data?.message);
        return false;
      }
    };

    const reactivateAppointment = async (id: string) => {
      dispatch(actions.setReactivateLoader(true));
      try {
        const { data } = await axiosService?.put(`appointment/reactivate/${id}`, {});
        toast.success(data?.message);
        getAppointmentDetails(id);
        dispatch(actions.setReactivateLoader(false));

        return data;
      } catch (e: any) {
        dispatch(actions.setReactivateLoader(false));

        toast.error(e?.response?.data?.message);
      }
      return undefined;
    };

    const rescheduleAppointment = async (formData: any) => {
      try {
        const { data } = await axiosService?.post(`/appointment/reschedule-appointment`, {
          ...formData,
        });

        getAppointmentDetails(formData?.appointmentId);
        return data;
      } catch (e: any) {
        toast.error(e?.response?.data?.message);
      }
      return undefined;
    };

    const getTaxRate = async () => {
      try {
        dispatch(actions.getTaxRate.loading());
        const response = await axiosService?.get('/appointment/tax-rate');
        dispatch(actions.getTaxRate.success(response?.data?.data?.getTaxRate));
        return response;
      } catch (e: any) {
        dispatch(actions.getTaxRate.failure());
        toast.error(e?.response?.data?.message);
      }
      return undefined;
    };

    const updateTaxRate = async (taxRate: number) => {
      try {
        const response = await axiosService?.put('/appointment/update-tax-rate', {
          taxRate,
        });
        toast.success(response?.data?.message);
        return response;
      } catch (e: any) {
        toast.error(e?.response?.data?.message);
      }
      return undefined;
    };

    return {
      state,
      actions: {
        AddReports,
        AdminConfirmBMLPayment,
        AdminConfirmCustomInvoicePayment,
        AdminVerifyPayment,
        get_Chats_Patient_Provider,
        getAppointmentList,
        getInvoiceBMLList,
        getWebAppointmentList,
        getAppointmentDetails,
        getWebAppointmentDetails,
        attachPatient,
        createPatient,
        cancelWebAppointment,
        changeAppointmentStatus,
        changeAppointmentType,
        assignProvider,
        ReAssignProvider,
        changeComment,
        rescheduleAppointment,
        reactivateAppointment,
        getPatientAppointmentList,
        updateAppointmentAddress,
        cancelInvoice,
        getTaxRate,
        updateTaxRate,
      },
    };
  });

export default useAdminAppointment;
