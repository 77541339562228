import { useCallback, useReducer } from 'react';
import { toast } from 'react-toastify';
import { createContainer, createReducer, createAsyncActions } from 'utils/context';
import axiosService from '../utils/api/axios';
import { salesTaxObjType } from 'typings/appointments';

export type SuperAdminType = {
  adminsList: any;
  adminsListLoading: boolean;
  appointmentKeysLoading: boolean;
  invoiceKeysLoading: boolean;
  appointmentKeysArray: [];
  invoiceKeysArray: [];
  previewData: {};
  previewDataLoading: boolean;
  downloadCSVLoading: boolean;
  downloadServicesCSVLoading: boolean;
  salesTaxDetails: salesTaxObjType;
  salesTaxDetailsLoading: boolean;
  salesTaxHistory: [];
  salesTaxHistoryLoading: boolean,
};
const initialState: SuperAdminType = {
  adminsList: [],
  adminsListLoading: false,
  appointmentKeysLoading: false,
  invoiceKeysLoading: false,
  appointmentKeysArray: [],
  invoiceKeysArray: [],
  previewData: {},
  previewDataLoading: false,
  downloadCSVLoading: false,
  downloadServicesCSVLoading: false,
  salesTaxDetails: {} as salesTaxObjType,
  salesTaxDetailsLoading: false,
  salesTaxHistory: [],
  salesTaxHistoryLoading: false,
};
const actions = {
  allAdminsList: createAsyncActions('GET_ADMIN_LIST'),
  allAppointmentKeys: createAsyncActions('GET_APPOINTMENT_KEYS'),
  allInvoiceKeys: createAsyncActions('GET_INVOICE_KEYS'),
  postExportFormData: createAsyncActions('POST_EXPORT_FORM_DATA'),
  postCSVData: createAsyncActions('POST_EXPORT_CSV_DATA'),
  postServicesCSVData: createAsyncActions('POST_EXPORT_SERVICES_CSV_DATA'),
  getSalesTaxDetails: createAsyncActions('GET_SALES_TAX_DETAILS'),
  getSalesTaxHistory: createAsyncActions('GET_SALES_TAX_HISTORY'),
};

const superAdminReducer = createReducer({
  [`${actions.allAdminsList.loading}`]: (state) => ({
    ...state,
    adminsListLoading: true,
  }),
  [`${actions.allAdminsList.success}`]: (state, { payload }) => ({
    ...state,
    adminsList: payload,
    adminsListLoading: false,
  }),
  [`${actions.allAdminsList.failure}`]: (state) => ({
    ...state,
    adminsListLoading: false,
  }),
  [`${actions.allAppointmentKeys.loading}`]: (state) => ({
    ...state,
    appointmentKeysLoading: true,
  }),
  [`${actions.allAppointmentKeys.success}`]: (state, { payload }) => ({
    ...state,
    appointmentKeysArray: payload,
    appointmentKeysLoading: false,
  }),
  [`${actions.allAppointmentKeys.failure}`]: (state) => ({
    ...state,
    appointmentKeysLoading: false,
  }),
  [`${actions.allInvoiceKeys.loading}`]: (state) => ({
    ...state,
    invoiceKeysLoading: true,
  }),
  [`${actions.allInvoiceKeys.success}`]: (state, { payload }) => ({
    ...state,
    invoiceKeysArray: payload,
    invoiceKeysLoading: false,
  }),
  [`${actions.allInvoiceKeys.failure}`]: (state) => ({
    ...state,
    invoiceKeysLoading: false,
  }),
  // saves export form response
  [`${actions.postExportFormData.loading}`]: (state) => ({
    ...state,
    previewDataLoading: true,
  }),
  [`${actions.postExportFormData.success}`]: (state, { payload }) => ({
    ...state,
    previewData: payload,
    previewDataLoading: false,
  }),
  [`${actions.postExportFormData.failure}`]: (state) => ({
    ...state,
    previewDataLoading: false,
  }),
  // download CSV actions
  [`${actions.postCSVData.loading}`]: (state) => ({
    ...state,
    downloadCSVLoading: true,
  }),
  [`${actions.postCSVData.success}`]: (state, { payload }) => ({
    ...state,
    downloadCSVLoading: false,
  }),
  [`${actions.postCSVData.failure}`]: (state) => ({
    ...state,
    downloadCSVLoading: false,
  }),
  // download only services CSV actions
  [`${actions.postServicesCSVData.loading}`]: (state) => ({
    ...state,
    downloadServicesCSVLoading: true,
  }),
  [`${actions.postServicesCSVData.success}`]: (state, { payload }) => ({
    ...state,
    downloadServicesCSVLoading: false,
  }),
  [`${actions.postServicesCSVData.failure}`]: (state) => ({
    ...state,
    downloadServicesCSVLoading: false,
  }),
  // saves sales tax rates and devisions
  [`${actions.getSalesTaxDetails.loading}`]: (state) => ({
    ...state,
    salesTaxDetailsLoading: true,
  }),
  [`${actions.getSalesTaxDetails.success}`]: (state, { payload }) => ({
    ...state,
    salesTaxDetails: payload,
    salesTaxDetailsLoading: false,
  }),
  [`${actions.getSalesTaxDetails.failure}`]: (state) => ({
    ...state,
    salesTaxDetailsLoading: false,
  }),
  [`${actions.getSalesTaxHistory.loading}`]: (state) => ({
    ...state,
    salesTaxHistoryLoading: true,
  }),
  [`${actions.getSalesTaxHistory.success}`]: (state, { payload }) => ({
    ...state,
    salesTaxHistory: payload,
    salesTaxHistoryLoading: false,
  }),
  [`${actions.getSalesTaxHistory.failure}`]: (state) => ({
    ...state,
    salesTaxHistoryLoading: false,
  }),
});

export const { useContext: useSuperAdmin, Provider: SuperAdminProvider } = createContainer(() => {
  const [state, dispatch] = useReducer(superAdminReducer, initialState);

  const createNewAdmin = useCallback(async (values) => {
    try {
      const { data } = await axiosService?.post('auth/register', { ...values });
      toast.success(data?.message);

      return data;
    } catch (e) {
      toast.error(e?.response?.data?.message);
    }
    return undefined;
  }, []);

  const updateAdmin = useCallback(async (values) => {
    try {
      const { data } = await axiosService?.put('users/update-admin', { ...values });
      toast.success(data?.message);

      return data;
    } catch (e) {
      toast.error(e?.response?.data?.message);
    }
    return undefined;
  }, []);

  const deleteAdmin = useCallback(async (id) => {
    try {
      const { data } = await axiosService?.delete(`users/admin/${id}`, {});
      toast.success(data?.message);

      return data;
    } catch (e) {
      toast.error(e?.response?.data?.message);
    }
    return undefined;
  }, []);

  const resendInviteAdmin = useCallback(async (userId) => {
    try {
      const { data } = await axiosService?.put(`/users/resend-set-password-email/${userId}`, {});
      toast.success(data?.message);

      return data;
    } catch (e) {
      toast.error(e?.response?.data?.message);
    }
    return undefined;
  }, []);

  const getAllAdminsList = useCallback(async () => {
    try {
      dispatch(actions.allAdminsList.loading());

      const { data } = await axiosService?.get(`/users/admin-list`);
      dispatch(actions.allAdminsList.success(data?.data));
      return data;
    } catch (e) {
      toast.error(e?.response?.data?.message);
      dispatch(actions.allAdminsList.failure());
    }
    return undefined;
  }, []);
  const getAdminLogs = useCallback(
    async (
      pageNumber = 1,
      searchParamQuery: string,
      adminId?: string | null,
      strDate?: string,
      enDate?: string,
    ) => {
      let api = `/users/admin-logs?page=${pageNumber.toString()}&take=30&order=DESC`;
      if (searchParamQuery) {
        api = api + `&searchQuery=${searchParamQuery}`;
      }
      if (adminId) {
        api = api + `&adminId=${adminId}`;
      }
      if (strDate && enDate) {
        const changeDateWithTime = (inputDate: string) => {
          let year = new Date(inputDate).getFullYear();
          let month = new Date(inputDate).getMonth() + 1; // Jan is 0, dec is 11
          let day = new Date(inputDate).getDate();
          let dateString = '' + year + '-' + month + '-' + day;
          return dateString.toString();
        };
        const stDate = changeDateWithTime(strDate);
        const eDate = changeDateWithTime(enDate);
        api = api + `&startDate=${stDate}&endDate=${eDate}`;
      }
      try {
        const { data } = await axiosService.get(api);
        return data;
      } catch (e) {
        toast.error(e?.response?.message);
      }
    },
    [],
  );

  const getAllAppointmentKeys = useCallback(async () => {
    let api = `/list/keys?type=APPOINTMENT`;
    try {
      dispatch(actions.allAppointmentKeys.loading());
      const { data } = await axiosService.get(api);
      dispatch(actions.allAppointmentKeys.success(data?.data?.keys));
      return data;
    } catch (error) {
      toast.error(error?.response?.message);
      dispatch(actions.allAppointmentKeys.failure());
    }
  }, []);

  const getAllInvoiceKeys = useCallback(async () => {
    let api = `/list/keys?type=INVOICE`;
    try {
      dispatch(actions.allInvoiceKeys.loading());
      const { data } = await axiosService.get(api);
      dispatch(actions.allInvoiceKeys.success(data?.data?.keys));
      return data;
    } catch (error) {
      toast.error(error?.response?.message);
      dispatch(actions.allInvoiceKeys.failure());
    }
  }, []);

  const postExportedData = useCallback(async (formData: any, page = 1, take = 10) => {
    let api = `/appointment/export?page=${page}&take=${take}`;
    try {
      dispatch(actions.postExportFormData.loading());
      const { data } = await axiosService.post(api, formData);
      dispatch(actions.postExportFormData.success(data?.data?.exportList));
      return data;
    } catch (error) {
      toast.error(error?.response?.message);
      dispatch(actions.postExportFormData.failure());
    }
  }, []);

  const downloadCSV = useCallback(async (formData: any) => {
    try {
      dispatch(actions.postCSVData.loading());
      const { data } = await axiosService.downloadFile(`/appointment/download-export-list`, formData);
      toast.success('Downloaded Successfuly');
      dispatch(actions.postCSVData.success());
      const csvData = data;
      const blob = new Blob([csvData], { type: 'text/csv' });
      const downloadLink = document.createElement('a');
      downloadLink.href = window.URL.createObjectURL(blob);
      downloadLink.download = 'LX-Medical_export_report.csv';
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
      return data;
    } catch (error) {
      toast.error("Something went wrong");
      dispatch(actions.postCSVData.failure());
    }
  }, []);

  const downloadServicesCSV = useCallback(async (formData: any) => {
    try {
      dispatch(actions.postServicesCSVData.loading());
      const { data } = await axiosService.downloadFile(`/appointment/download-services-export-list`, formData);
      toast.success('Downloaded Successfuly');
      dispatch(actions.postServicesCSVData.success());
      const csvData = data;
      const blob = new Blob([csvData], { type: 'text/csv' });
      const downloadLink = document.createElement('a');
      downloadLink.href = window.URL.createObjectURL(blob);
      downloadLink.download = 'LX-Medical_services_report.csv';
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
      return data;
    } catch (error) {
      toast.error("Something went wrong");
      dispatch(actions.postServicesCSVData.failure());
    }
  }, []);

  const getSalesTaxDetails = useCallback(async () => {
    try {
      dispatch(actions.getSalesTaxDetails.loading());
      const { data } = await axiosService.get(`/appointment/get-sales-tax-detail`);
      dispatch(actions.getSalesTaxDetails.success(data?.data?.salesTaxDetail));
      return data;
    } catch (error) {
      toast.error(error?.response?.message);
      dispatch(actions.getSalesTaxDetails.failure());
    }
  }, []);

  const getSalesTaxHistory = useCallback(async () => {
    try {
      dispatch(actions.getSalesTaxHistory.loading());
      const { data } = await axiosService.get(`/appointment/sales-tax-history`);
      dispatch(actions.getSalesTaxHistory.success(data?.data?.salesTaxHistory));
      return data;
    } catch (error) {
      toast.error(error?.response?.message);
      dispatch(actions.getSalesTaxHistory.failure());
    }
  }, []);
  
  const updateSalesTax = useCallback(async (formData) => {
    try {
      const { data } = await axiosService.post(`/appointment/update-sales-tax-detail`, formData);
      toast.success(data?.message);
      return data;
    } catch (error) {
      toast.error(error?.response?.message);
    }
  }, []);

  return {
    state,
    actions: {
      createNewAdmin,
      updateAdmin,
      deleteAdmin,
      resendInviteAdmin,
      getAllAdminsList,
      getAdminLogs,
      getAllAppointmentKeys,
      getAllInvoiceKeys,
      postExportedData,
      downloadCSV,
      downloadServicesCSV,
      getSalesTaxDetails,
      getSalesTaxHistory,
      updateSalesTax,
    },
  };
});

export default useSuperAdmin;
