import { useReducer } from 'react';
import { toast } from 'react-toastify';
import { createContainer, createReducer, createAsyncActions } from 'utils/context';
import axiosService from '../utils/api/axios';


const initialState = {
  NotificationsList: { data: [], meta: {} },
  NotificationsLoading: false,
};

const actions = {
  NotificationsList: createAsyncActions('Notifications_LIST'),
};

const notificationReducer = createReducer({
  [`${actions.NotificationsList.loading}`]: (state) => ({
    ...state,
    NotificationsLoading: true,
  }),
  [`${actions.NotificationsList.success}`]: (state, { payload }) => ({
    ...state,
    NotificationsList: payload,
    NotificationsLoading: false,
  }),
  [`${actions.NotificationsList.failure}`]: (state) => ({
    ...state,
    NotificationsLoading: false,
  }),
});

export const { useContext: useNotification, Provider: NotificationProvider } = createContainer(
  () => {
    const [state, dispatch] = useReducer(notificationReducer, initialState);


    const getNotifications = async (PageNum: string | number) => {
      
      try {
        dispatch(actions.NotificationsList.loading());

        const { data } = await axiosService?.get(
          `/list/push-notifications/?page=${Number(PageNum)}&take=10`,
        );
        dispatch(actions.NotificationsList.success(data?.data?.notificationList));

        return data;
      } catch (e) {
        toast.error(e?.response?.data?.message);
        dispatch(actions.NotificationsList.failure());
      }
      return undefined;
    };
   
    

    const clearData = ()=>{
      dispatch(actions.NotificationsList.success({ data: [], meta: {} }));
    }

   
    

    return {
      state,
      actions: {
        getNotifications,
        clearData
      },
    };
  },
);

export default useNotification;
