import { useCallback, useReducer } from 'react';
import { toast } from 'react-toastify';
import { createContainer, createReducer, createAsyncActions } from 'utils/context';
import axiosService from '../utils/api/axios';

const initialState = {
  PaymentCardsList: { data: [], meta: {} },
  PaymentCardsLoading: false,
};

const actions = {
  PaymentCardsList: createAsyncActions('Notifications_LIST'),
};

const paymentCardsReducer = createReducer({
  [`${actions.PaymentCardsList.loading}`]: (state) => ({
    ...state,
    PaymentCardsLoading: true,
  }),
  [`${actions.PaymentCardsList.success}`]: (state, { payload }) => ({
    ...state,
    PaymentCardsList: payload,
    PaymentCardsLoading: false,
  }),
  [`${actions.PaymentCardsList.failure}`]: (state) => ({
    ...state,
    PaymentCardsLoading: false,
  }),
});

export const { useContext: usePaymentCards, Provider: PaymentCardsProvider } = createContainer(
  () => {
    const [state, dispatch] = useReducer(paymentCardsReducer, initialState);

    const getPaymentCards = useCallback(async (id: string) => {
      try {
        dispatch(actions.PaymentCardsList.loading());

        const { data } = await axiosService?.get(`/payments/${id}/card-details`);
        dispatch(actions.PaymentCardsList.success(data?.data));
      } catch (e) {
        toast.error(e?.response?.data?.message);
        dispatch(actions.PaymentCardsList.failure());
      }
    }, []);

    const deletePaymentCards = useCallback(async (cardId: string) => {
      try {
        const { data } = await axiosService?.post(`/payments/detach/payment-method`, {
          paymentMethodId: cardId,
        });
        toast.success(data.message);
        return data;
      } catch (e) {
        toast.error(e?.response?.data?.message);
      }
    }, []);

    const clearData = () => {
      dispatch(actions.PaymentCardsList.success({ data: [], meta: {} }));
    };

    const makeDefaultCard = useCallback(async (cardId: string, patientId: string) => {
      try {
        const { data } = await axiosService?.post(`/payments/attach/payment-method`, {
          paymentMethodId: cardId,
          patientId: patientId,
        });
        toast.success(data.message);
        return data;
      } catch (e) {
        toast.error(e?.response?.data?.message);
      }
    }, []);

    const makeAdminPayment = useCallback(async (formData: any) => {
      try {
        const { data } = await axiosService?.post(`/payments/card-on-file-payment`, {
          ...formData,
        });
        toast.success(data.message);
        return data;
      } catch (e) {
        toast.error(e?.response?.data?.message);
      }
    }, []);

    const makeAdminInvoicePayment = useCallback(async (formData: any) => {
      try {
        const { data } = await axiosService?.post(`/payments/card-on-file-payment-for-invoice`, {
          ...formData,
        });
        toast.success(data.message);
        return data;
      } catch (e) {
        toast.error(e?.response?.data?.message);
      }
    }, []);

    return {
      state,
      actions: {
        getPaymentCards,
        deletePaymentCards,
        makeDefaultCard,
        makeAdminPayment,
        makeAdminInvoicePayment,
        clearData,
      },
    };
  },
);

export default usePaymentCards;
