import { useCallback, useReducer } from 'react';
import { toast } from 'react-toastify';
import { createContainer, createReducer, createAsyncActions } from 'utils/context';
import { API_URL } from 'utils/settings/constants';
import axiosService from '../utils/api/axios';

const initialState = {
  patientsList: { data: [], meta: {} },
  linkdata: { data: [], meta: {} },
  patientsLoading: false,
  linkLoading: false,
};
const actions = {
  getPatientList: createAsyncActions('GET_PATIENTS_LIST'),
  sendLink: createAsyncActions('SEND_LINK'),
};

const patientReducer = createReducer({
  [`${actions.getPatientList.loading}`]: (state) => ({
    ...state,
    patientsLoading: true,
  }),
  [`${actions.getPatientList.success}`]: (state, { payload }) => ({
    ...state,
    patientsList: payload,
    patientsLoading: false,
  }),
  [`${actions.getPatientList.failure}`]: (state) => ({
    ...state,
    patientsLoading: false,
  }),
  [`${actions.sendLink.loading}`]: (state) => ({
    ...state,
    linkLoading: true,
  }),
  [`${actions.sendLink.success}`]: (state, { payload }) => ({
    ...state,
    linkdata: payload,
    linkLoading: false,
  }),
  [`${actions.sendLink.failure}`]: (state) => ({
    ...state,
    linkLoading: false,
  }),
});

export const { useContext: useAdminPatient, Provider: AdminPatientProvider } = createContainer(
  () => {
    const [state, dispatch] = useReducer(patientReducer, initialState);

    const getPatientList = async (
      PageNum = '1',
      query?: string,
      sortBy?: string,
      membership?: string,
      take?: string | number,
    ) => {
      try {
        dispatch(actions.getPatientList.loading());
        let api = `/users?role=PATIENT&page=${PageNum}&take=${take || 10}`;
        if (query) {
          api = api + `&query=${query}`;
        }
        if (sortBy) {
          api = api + `&order=${sortBy}`;
        }
        if (membership) api = api + `&membershipType=${membership || ''}`;

        const { data } = await axiosService?.get(api);
        dispatch(actions.getPatientList.success(data));

        return data;
      } catch (e) {
        toast.error(e?.response?.data?.message);
        dispatch(actions.getPatientList.failure());
      }
      return undefined;
    };

    const changeMembership = async (formData: any) => {
      try {
        const { data } = await axiosService?.post(`/patient/change-membership`, formData);

        const temp_state = state?.patientsList?.data?.map((val: any) =>
          val?.id === formData?.patientId
            ? { ...val, membershipType: formData?.membershipType }
            : val,
        );

        dispatch(
          actions.getPatientList.success({ data: temp_state, meta: state?.patientsList?.meta }),
        );
        toast.success(data.message);

        return data;
      } catch (e) {
        toast.error(e?.response?.data?.message);
      }
      return undefined;
    };

    const AdminPatientSignup = async (formData: any) => {
      try {
        const { data } = await axiosService.post(API_URL?.ADMIN_PATIENT_REGISTER, {
          ...formData,
        });
        toast.success(data?.message);
        return data;
      } catch (e) {
        // @ts-ignore
        toast.error(e?.response?.data?.message);
        return undefined;
      }
    };
    const sendSetLink = async (formData: any) => {
      try {
        dispatch(actions.sendLink.loading());
        const { data } = await axiosService.post(`/users/set-account-link/${formData}`);
        toast.success(data?.message);
        dispatch(actions.sendLink.success());
        return data;
      } catch (e) {
        toast.error(e?.response?.data?.message);
        dispatch(actions.sendLink.failure());

        return undefined;
      }
    };
    const sendDirectLoginLink = async (formData: any) => {
      try {
        const { data } = await axiosService.post(`/users/send-direct-login-link/${formData}`);
        toast.success(data?.message);
        return data;
      } catch (e) {
        toast.error(e?.response?.data?.message);

        return undefined;
      }
    };

    const autoGenerateUserId = useCallback(
      async (fName?: string, lName?: string, userId?: string) => {
        try {
          let idWithName;
          if (fName) {
            const firstName = fName.trim().replace(/  +/g, ' ');
            if (lName) {
              idWithName = `${firstName.split(' ').join('-')}-${lName}`.toLocaleLowerCase();
            } else {
              idWithName = fName.trim().toLocaleLowerCase();
            }
          } else if (!fName && lName) {
            idWithName = lName.trim().toLocaleLowerCase();
          } else if (userId) {
            idWithName = userId.trim().split(' ').join('-');
          }
          const { data } = await axiosService.post('/auth/generate-username', { seed: idWithName });
          return data;
        } catch (e) {
          // @ts-ignore
          toast.error(e?.response?.data?.message);
          return e;
        }
      },
      [],
    );

    return {
      state,
      actions: {
        getPatientList,
        AdminPatientSignup,
        changeMembership,
        autoGenerateUserId,
        sendSetLink,
        sendDirectLoginLink,
      },
    };
  },
);

export default useAdminPatient;
