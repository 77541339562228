import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { DuplicateIcon } from '@heroicons/react/outline';
import { Text } from 'molecules/Text';
import { Button } from 'molecules/Button';
import { Image } from 'atoms/Image/Image';
import dummyProfile from 'assets/images/dummy-profile-image.png';
import { useLocation } from 'react-router';
import { AppointmentStatus, travelFeeValue } from 'utils/settings/constants';
import Loader from 'atoms/Loader';
import { getFormatDate } from 'utils/utility';
import AppointmentPatientCard from 'organisms/AppointmentPatientCard/AppointmentPatientCard';
import useVisibleState from 'utils/hooks/useVisibleStates';
import { Link } from 'react-router-dom';
import CommentCard from 'organisms/CommentCard';
import { ClipboardCheckIcon } from '@heroicons/react/outline';
import CheckoutModal from 'modules/PatientPanel/PatientAppointments/Components/BookAppointmentFlow/CheckoutModal';
import axios from 'axios';
import { toast } from 'react-toastify';
axios.defaults.baseURL = process.env.REACT_APP_API_HOST;
const PaymentFromPatients = () => {
  const { pathname } = useLocation();
  const id = pathname.split('/')[2];

  const [AppointmentDetails, setAppointmentDetails] = useState<any>();
  const [appointmentsLoading, setAppointmentsLoading] = useState(false);

  const getAppointmentDetails = useCallback(async (id: string) => {
    try {
      setAppointmentsLoading(true);
      const { data } = await axios.get(
        `${axios.defaults.baseURL}/appointment/appointmentDetails/${id}`,
      );
      data && setAppointmentDetails(data?.data);
      setAppointmentsLoading(false);

      return data;
    } catch (e) {
      toast.error(e?.response?.data?.message);
      setAppointmentsLoading(false);
    }
  }, []);

  useEffect(() => {
    id && getAppointmentDetails(id);
  }, [getAppointmentDetails, id]);

  const color = useMemo(
    () =>
      AppointmentDetails?.appointmentStatus?.toLowerCase() === 'requested' ||
      AppointmentDetails?.appointmentStatus?.toLowerCase() === 'rejected' ||
      AppointmentDetails?.appointmentStatus?.toLowerCase() === 'cancelled'
        ? '#FF7F40'
        : AppointmentDetails?.appointmentStatus?.toLowerCase() === 'accepted' ||
          AppointmentDetails?.appointmentStatus === 'PAID'
        ? '#3B4D75'
        : AppointmentDetails?.appointmentStatus === 'COMPLETED'
        ? '#4770CC'
        : '#54BAB2',
    [AppointmentDetails],
  );

  const showPayment = useMemo(
    () =>
      AppointmentDetails?.appointmentStatus === 'AWAITING_PAYMENT' ||
      AppointmentDetails?.appointmentStatus === 'PAID' ||
      AppointmentDetails?.appointmentStatus === 'COMPLETED'
        ? true
        : false,
    [AppointmentDetails?.appointmentStatus],
  );

  // const showInvoice = useMemo(
  //   () =>
  //     AppointmentDetails?.appointmentStatus === 'PAID' ||
  //     AppointmentDetails?.appointmentStatus === 'COMPLETED'
  //       ? true
  //       : false,
  //   [AppointmentDetails?.appointmentStatus],
  // );

  const {
    visible: onlinePaymentModal,
    hide: hideOnlinePaymentModal,
    show: showOnlinePaymentModal,
  } = useVisibleState(false);

  // const [invoice, setInvoice] = useState('');

  return (
    <div className="max-w-5xl mx-auto ">
      <div className="min-h-screen">
        {onlinePaymentModal && (
          <CheckoutModal
            open={onlinePaymentModal}
            onClose={hideOnlinePaymentModal}
            appointmentId={id}
            totalAmount={AppointmentDetails?.totalAmount}
            onSuccessfulCheckout={() => {
              hideOnlinePaymentModal();
              setAppointmentsLoading(true);
              setTimeout(() => {
                getAppointmentDetails(id);
              }, 4000);
            }}
          />
        )}

        <div className="flex items-center space-x-4 border-b-2 py-3 mx-4 sm:mx-6 mt-2">
          <Text className="font-semibold" id="appointment.details" />
        </div>

        {appointmentsLoading ? (
          <Loader />
        ) : (
          <div className="bg-white rounded shadow mx-4 sm:mx-2 lg:mx-0 my-3">
            <div className="border-b py-2 px-2 sm:px-6 gap-2 flex itmes-center justify-between">
              <div className="text-primary font-semibold capitalize">
                {AppointmentDetails?.services?.[0]?.name?.toLowerCase()}
                {AppointmentDetails?.services?.length > 1 &&
                  ` & ${AppointmentDetails?.services?.length - 1} more`}
              </div>

              <div className={`font-semibold flex gap-1 items-center`} style={{ color: color }}>
                <span>&#9679; </span>
                <span className="text-sm capitalize">
                  {AppointmentStatus[AppointmentDetails?.appointmentStatus]?.toLowerCase()}
                </span>
              </div>
            </div>

            <div className="py-3 mx-6 border-b text-sm md:flex md:flex-wrap md:justify-between md:items-center font-semibold">
              <div className="flex">
                <Text className="text-gray-500 md:pr-4 pr-7" id="appointment.id" />
                <span>{AppointmentDetails?.appointmentKey}</span>
              </div>

              <div className="flex capitalize">
                <Text className="text-gray-500 md:pr-4 pr-10" id={'requested.for'} />

                <span>
                  {getFormatDate(AppointmentDetails?.preferedAppointmentDate)} |{' '}
                  {AppointmentDetails?.appointmentSlot?.length > 1
                    ? AppointmentDetails?.appointmentSlot?.[0]?.toLowerCase() +
                      ' | ' +
                      AppointmentDetails?.appointmentSlot?.[1]?.toLowerCase()
                    : AppointmentDetails?.appointmentSlot?.[0]?.toLowerCase()}{' '}
                  {AppointmentDetails?.telemedicineTime &&
                    ' | ' + AppointmentDetails?.telemedicineTime?.toUpperCase()}
                </span>
              </div>

              {AppointmentDetails?.providerAssigned && (
                <div className="w-full flex capitalize md:mt-4">
                  {AppointmentDetails?.appointmentStatus === 'PAID' ||
                  AppointmentDetails?.appointmentStatus === 'COMPLETED' ? (
                    <Text className="text-gray-500 md:pr-4 pr-10" id={'completed.on'} />
                  ) : (
                    <Text className="text-gray-500 md:pr-4 pr-10" id={'scheduled.on'} />
                  )}

                  <span>
                    {getFormatDate(AppointmentDetails?.appointmentDate)}
                    {AppointmentDetails?.appointmentTime &&
                      ' | ' + AppointmentDetails?.appointmentTime?.toUpperCase()}
                  </span>
                </div>
              )}
            </div>

            {/* results awaited */}
            {AppointmentDetails?.appointmentStatus === 'PAID' && (
              <div className="py-2 mt-1 mx-6 border-b">
                <div className="mt-3 mb-3 text-sm md:text-base grid grid-cols-4 gap-x-7 gap-y-6">
                  <div className="col-span-4 sm:col-span-2">
                    <Text id="result.awaited" className="font-medium" />
                    <Text
                      id="result.awaited.msg"
                      className="font-medium mt-3 text-gray-600 text-sm"
                    />
                  </div>
                </div>
              </div>
            )}

            {/* patients cancel reason */}
            {AppointmentDetails?.cancellationReason && (
              <div className="py-2 mt-1 mx-6 border-b">
                <div className="mt-3 mb-3 text-sm md:text-base grid grid-cols-4 gap-x-7 gap-y-6">
                  <div className="col-span-4 sm:col-span-2">
                    <CommentCard
                      labelId="cancellationReason"
                      comment={AppointmentDetails?.cancellationReason}
                    />
                  </div>
                </div>
              </div>
            )}

            {/* patients Test listing */}
            {AppointmentDetails?.reports?.length > 0 && (
              <div className="py-1 mt-1 mx-6 border-b">
                <Text className="font-semibold" id="test.results" />
                <div className="my-2 text-sm md:text-base grid grid-cols-4 gap-x-7 gap-y-4">
                  {AppointmentDetails?.reports?.map((val: any, index: number) => (
                    <div className="col-span-4 sm:col-span-2 capitalize border-t border-gray-100 bg-white rounded shadow hover:shadow-md">
                      <div className=" font-medium text-primary p-1.5 px-2 border-b border-gray-100">
                        {val?.patientId === AppointmentDetails?.patient?.id
                          ? `${AppointmentDetails?.patient?.firstName} ${AppointmentDetails?.patient?.lastName}`
                          : // eslint-disable-next-line array-callback-return

                            (() => {
                              // eslint-disable-next-line array-callback-return
                              const d = AppointmentDetails?.familyMembers?.find((ele: any) => {
                                if (val?.patientId === ele?.id) return ele;
                              });
                              return `${d?.firstName} ${d?.lastName}`;
                            })()}
                      </div>
                      <Link to={{ pathname: val?.reportUrl }} target="_blank">
                        <div
                          key={index}
                          className="flex justify-between items-center font-semibold bg-white p-2 py-2 my-4 md:my-0"
                        >
                          <div className="text-primary w-3/5">{val?.reportName}</div>
                          <div className="flex items-center space-x-2">
                            <div className="flex items-center space-x-1 bg-lightBlue rounded-lg p-2">
                              <ClipboardCheckIcon className="text-primary w-6 h-6" />
                              <Text id="view.results" className="inline text-primary text-sm" />
                            </div>
                          </div>
                        </div>
                      </Link>
                    </div>
                  ))}
                </div>
              </div>
            )}

            {/* patients listing */}
            <div className="py-1 mt-1 mx-6 border-b">
              <Text className="font-semibold" id="patients" />
              <div className="my-2 text-sm md:text-base grid grid-cols-4 gap-x-7 gap-y-4">
                {(AppointmentDetails?.appointmentType === 'SELF_ONLY' ||
                  AppointmentDetails?.appointmentType === 'SELF_AND_FAMILY_MEMBER') && (
                  <div className="col-span-4 sm:col-span-2">
                    <AppointmentPatientCard
                      symptoms={
                        AppointmentDetails?.primarySymptoms?.find(
                          (val: any) => val.patientId === AppointmentDetails?.patient?.id,
                        )?.symptom
                      }
                      fullname={`${AppointmentDetails?.patient?.firstName} ${AppointmentDetails?.patient?.lastName}`}
                      relation="Self"
                    />
                  </div>
                )}

                {AppointmentDetails?.familyMembers?.map((patient: any, index: number) => (
                  <div className="col-span-4 sm:col-span-2" key={index}>
                    <AppointmentPatientCard
                      symptoms={
                        AppointmentDetails?.primarySymptoms?.find(
                          (val: any) => val.patientId === patient?.id,
                        )?.symptom
                      }
                      fullname={`${patient?.firstName} ${patient?.lastName}`}
                      relation={patient?.relationship?.toLowerCase()}
                    />
                  </div>
                ))}
              </div>
            </div>

            {/* Telemedicine Card */}
            {AppointmentDetails?.telemedicineLink && (
              <div className="py-1 mt-1 mx-6 border-b capitalize">
                <Text className="font-semibold" id="telemedicine.visit" />
                <Text className="text-gray-400 text-xs" id="telemedicine.visit.description" />
                <div className="my-2 text-sm md:text-base grid grid-cols-4 gap-x-7 gap-y-4">
                  <div className="col-span-4 sm:col-span-2">
                    <div className="border-t p-2 border-gray-100 font-semibold bg-white rounded shadow hover:shadow-md">
                      <div className="flex items-center justify-between ">
                        <Text className="font-semibold" id="meeting.link" />

                        <span className="font-medium text-sm">
                          {getFormatDate(AppointmentDetails?.appointmentDate)}
                          {AppointmentDetails?.appointmentTime &&
                            ' | ' + AppointmentDetails?.appointmentTime?.toUpperCase()}
                        </span>
                      </div>

                      <div className="flex items-center justify-between pt-3">
                        <Link
                          className=""
                          to={{
                            pathname: AppointmentDetails?.telemedicineLink,
                          }}
                          target="_blank"
                        >
                          <div className="lowercase text-blue-500 hover:underline">
                            {AppointmentDetails?.telemedicineLink?.slice(0, 22) + '..'}
                          </div>
                        </Link>

                        <div
                          className="flex items-center text-primary cursor-pointer"
                          onClick={() => {
                            navigator.clipboard.writeText(AppointmentDetails?.telemedicineLink);
                          }}
                        >
                          <DuplicateIcon className="w-6 h-6 mr-2" />
                          <Text id="copy.link" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {/* services listing */}
            <div className="py-1 mt-1 mx-6 border-b capitalize">
              <Text className="font-semibold" id="services.requested" />
              <div className="my-2 text-sm md:text-base grid grid-cols-4 gap-x-7 gap-y-4">
                {AppointmentDetails?.services?.map((ele: any, index: number) => (
                  <div className="col-span-4 sm:col-span-2" key={index}>
                    <div className=" text-sm md:text-base ">
                      <div className="flex hover:shadow-md flex-col font-md font-semibold text-gray-700 p-2.5 my-3 md:my-0 bg-gray-100 rounded">
                        <div className="flex flex-row place-content-between">
                          <div className="mr-2">{ele?.name}</div>
                          <div className="ml-2 text-primary">{ele?.rate}</div>
                        </div>
                        <div className="text-xs pt-2">{ele?.description}</div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>

            {/* Provider detail */}
            {AppointmentDetails?.providerAssigned && (
              <div className="py-1 mt-1 mx-6 ">
                <div className="mb-2 text-sm md:text-base grid grid-cols-4 gap-x-7 gap-y-4">
                  <div className="col-span-4 sm:col-span-2">
                    <Text className="font-semibold" id="admin.provider.details" />

                    <div>
                      <div className="border-t border-gray-100 flex hover:shadow-md justify-between items-center font-semibold text-sm bg-white p-3 rounded shadow">
                        <div className="flex items-center">
                          <Image
                            src={AppointmentDetails?.providerAssigned?.profileImage || dummyProfile}
                            width="30"
                          />
                          <span className="text-primary pl-2 pt-1 capitalize">{`${AppointmentDetails?.providerAssigned?.firstName} ${AppointmentDetails?.providerAssigned?.lastName}`}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-span-4 sm:col-span-2">
                    <Text className="font-semibold mb-3" id="provider.comment" />

                    {/* comment by provider */}
                    {AppointmentDetails?.commentsForPatient && (
                      <CommentCard comment={AppointmentDetails?.commentsForPatient} />
                    )}
                  </div>
                </div>
              </div>
            )}

            {/* payment detail */}
            {showPayment && (
              <div className="py-2 mx-6 border-t capitalize">
                <Text className="font-semibold" id="payment.details" />

                <div className="md:py-2 text-sm md:text-base md:w-1/2">
                  <div className="flex flex-col font-md font-semibold my-2 pr-4 md:my-0 space-y-2">
                    {AppointmentDetails?.servicesAvailed?.map((val: any, idx: number) => (
                      <div className="flex flex-row place-content-between" key={idx}>
                        <div className="mr-2 text-gray-400">{val?.serviceName}</div>
                        <div className="ml-2">${val?.serviceCost}</div>
                      </div>
                    ))}
                  </div>

                  {!AppointmentDetails?.telemedicineTime && (
                    <div className="flex flex-row place-content-between pt-2 font-md font-semibold my-3 pr-4 md:my-0">
                      <Text className="mr-2 text-gray-400" id="travel.fee" />
                      <div
                        className={`ml-2 ${
                          !AppointmentDetails?.travelFee && 'line-through text-gray-600'
                        }`}
                      >
                        {`$${travelFeeValue}`}
                      </div>
                    </div>
                  )}

                  {AppointmentDetails?.discountAmount && (
                    <div className="flex italic text-primary flex-row place-content-between pt-4 font-md font-semibold my-3 pr-4 md:my-0">
                      <span className="flex justify-start">
                        <Text className="mr-1" id="membership.savings" />
                      </span>
                      <div className="ml-2">${AppointmentDetails?.discountAmount}</div>
                    </div>
                  )}

                  {AppointmentDetails?.appointmentStatus === 'AWAITING_PAYMENT' ? (
                    <div className="flex flex-row place-content-between font-md font-bold pr-4 mt-3 md:mt-4 bg-blue-50 rounded p-3 mr-2 hover:shadow">
                      <Text className="mr-2" id="total.payable" />
                      <div className="ml-2 text-primary">${AppointmentDetails?.totalAmount}</div>
                    </div>
                  ) : (
                    <>
                      <div className="border-t mr-4 mt-4" />
                      <div className="flex flex-row place-content-between pt-2 font-md font-bold my-3 md:my-3 pr-4">
                        <Text className="mr-2" id="total.paid" />
                        <div className="ml-2 text-primary">${AppointmentDetails?.totalAmount}</div>
                      </div>
                    </>
                  )}
                </div>

                {AppointmentDetails?.paymentMode && (
                  <div className="pt-2 font-md font-bold my-1 pr-4">
                    <Text className="mr-2 inline text-gray-600" id="add.paymentRequestType" />
                    <div className="ml-2 text-primary inline" style={{ color: '#4770CC' }}>
                      {AppointmentDetails?.paymentMode === 'BML'
                        ? 'Bill Me Later'
                        : AppointmentDetails?.paymentMode}
                    </div>
                  </div>
                )}
              </div>
            )}

            {AppointmentDetails?.appointmentStatus === 'AWAITING_PAYMENT' && (
              <div className="border-t py-4 px-6">
                {/*                 
                {AppointmentDetails?.paymentRequestType && (
                  <div className="flex items-center space-x-2">
                    <ExclamationCircleIcon style={{ color: color }} className="w-4 h-4" />
                    <div className="text-sm font-semibold " style={{ color: color }}>
                      Awaiting payment confirmation.
                    </div>
                  </div>
                )} */}

                {AppointmentDetails?.paymentRequestType && (
                  <Button
                    type="button"
                    textid="make.payment"
                    className="w-full md:w-1/3 px-3 sm:px-10 py-3"
                    onClick={showOnlinePaymentModal}
                  />
                )}
              </div>
            )}

            {/* {showInvoice && (
              <div className="border-t py-4 px-6">
                {!invoice ? (
                  <Button
                    onClick={() => DownloadPDF(AppointmentDetails?.id, setInvoice)}
                    type="button"
                    textid="download.invoice"
                    className={`md:w-1/7 border-primary border-1 px-6 sm:px-16 py-3 text-primary hover:bg-primary hover:text-white bg-lightBlue ${
                      invoice && 'hidden'
                    }`}
                  />
                ) : (
                  <div className="h-11 w-2/6 bg-lightBlue relative border-primary border-2 rounded-md">
                    <div
                      className="h-full absolute  flex items-center text-lg justify-center bg-primary text-center font-bold"
                      style={{ width: invoice || '0%' }}
                    />
                    <div className="absolute flex items-center justify-center h-full w-full text-center font-semibold">
                      {invoice}
                    </div>
                  </div>
                )}
              </div>
            )} */}
            {/*  */}
          </div>
        )}
      </div>
    </div>
  );
};

export default PaymentFromPatients;
