import { Form, Formik } from 'formik';
import { Button } from 'molecules/Button';
import FormikSelect from 'molecules/FormikSelect';
import * as yup from 'yup';

import React, { useCallback, useRef } from 'react';
import { useFormSubmitWithLoading } from 'utils/hooks/useFormSubmitWithLoading';
import { InputEvent } from 'typings/syntheticEvents';
import { formatPhoneNumber } from 'utils/utility';
import { FormikInput } from 'molecules';
import { validateEmail, validatePhone } from 'utils/validators';

const initialValues = {
  email: '',
  phone: '',
  countryCode: '+1',
  isPatient: 'yes',
};

const requestContactInfoValidation = yup.object().shape({
  email: validateEmail().required('required'),
  phone: validatePhone().required('required'),
  isPatient: yup.string().required('required'),
});

function RequestContactInfo({ setFormStep, preInitialValues, onSubmit }: any) {
  const { onSubmitHandler, loading } = useFormSubmitWithLoading(onSubmit);
  const formRef = useRef<any>();
  const formInitialValues = { ...initialValues, ...preInitialValues };
  const handleInputPhone = useCallback((e: InputEvent) => {
    let currentValue = e.currentTarget.value;
    const formattedPhoneNumber = formatPhoneNumber(currentValue);
    formRef.current.setFieldValue('phone', formattedPhoneNumber);
  }, []);

  return (
    <div className="">
      <Formik
        initialValues={formInitialValues}
        onSubmit={onSubmitHandler}
        validationSchema={requestContactInfoValidation}
        innerRef={formRef}
        // validateOnChange
        // validateOnMount
      >
        {({ values }) => (
          <Form className="flex flex-col gap-4 sm:grid  mt-2">
            <div className="flex flex-col gap-4 sm:grid sm:gap-10 sm:grid-cols-2 ">
              <FormikSelect
                label="are.you.patient"
                name="isPatient"
                options={[
                  { label: 'Yes', value: 'yes' },
                  { label: 'No', value: 'no' },
                ]}
                fieldRequired
              />
            </div>

            <div className="flex flex-col gap-4 sm:grid sm:gap-10 sm:grid-cols-2 ">
              <FormikInput
                label="phone"
                type="phone"
                name="phone"
                // @ts-ignore
                countryCode="+1"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  handleInputPhone(e as InputEvent);
                }}
                maxLength={14}
                fieldRequired
              />
              <FormikInput label="email" name="email" fieldRequired />
            </div>
            <div className="flex justify-start gap-3 sm:gap-6 border-t-2 border-gray-100 py-6">
              <Button
                type="button"
                onClick={() => setFormStep(3)}
                textid="previous"
                className="border-primary border-1 w-full px-0 justify-center sm:w-auto sm:px-16 sm:py-3 text-primary hover:bg-primary hover:text-white bg-lightBlue"
              />
              <Button
                loading={loading}
                textid="continue"
                className="w-full px-0 justify-center sm:w-auto sm:px-16 sm:py-3"
                type="submit"
              />
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default RequestContactInfo;
