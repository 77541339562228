import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';

const ReactNotificationComponent = ({ title, body }) => {
  useEffect(() => {
    if (title) {
      toast.info(<Display />);
    }
  }, [title]);
  function Display() {
    return (
      <div>
        <h4>{title}</h4>
        <p>{body}</p>
      </div>
    );
  }

  return <div></div>;
};

ReactNotificationComponent.defaultProps = {
  title: 'This is title',
  body: 'Some body',
};

ReactNotificationComponent.propTypes = {
  title: PropTypes.string,
  body: PropTypes.string,
};

export default ReactNotificationComponent;
