import React, { FC } from 'react';

import imagePlaceholder from 'assets/images/imagePlaceholder.png';

export const Image: FC<any> = React.memo(({ src, alt, ...rest }) => {
  return (
    <img
      src={src || imagePlaceholder}
      alt={alt as string | undefined}
      width="100%"
      height="100%"
      {...rest}
    />
  );
});
