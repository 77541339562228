import firebase from 'firebase/app';
import 'firebase/messaging';
import { firebaseConfig } from 'utils/settings/constants';

// For Firebase JS SDK v7.20.0 and later, measurementId is optional

// firebase.initializeApp(firebaseConfig);

let messaging = null
if (firebase.messaging.isSupported()) {
  firebase.initializeApp(firebaseConfig)
  messaging = firebase.messaging()
} else {
  console.log('no-support :(')
}
// const messaging = firebase.messaging();

const { REACT_APP_VAPID_KEY } = process.env;
const publicKey = REACT_APP_VAPID_KEY;
export const getToken = async (setTokenFound) => {
  let currentToken = '';

  try {
    currentToken = await messaging.getToken({ vapidKey: publicKey });
    if (currentToken) {
      setTokenFound(currentToken);
    } else {
      setTokenFound('');
    }
  } catch (error) {
    console.log('An error occurred while retrieving token. ', error);
  }

  return currentToken;
};

export const onMessageListener = () =>
  new Promise((resolve) => {
    messaging.onMessage((payload) => {
      resolve(payload);
    });
  });
