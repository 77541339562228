import React from 'react';
import { RotateLoader } from 'react-spinners';

interface LoaderProps {
  children?: React.ReactNode;
  loading?: boolean;
  size?: number;
}

export const Loader = ({ loading, size, children }: LoaderProps) => {
  if (loading) {
    return (
      <div className="absolute inset-x-2/4 inset-y-2/4">
        <RotateLoader loading size={size || 11} color="#000" />
      </div>
    );
  }
  return <>{children || null} </>;
};

Loader.defaultProps = {
  loading: true,
};
