import useAuth from 'contexts/AuthenticationContext';
import { Form, Formik } from 'formik';
import { FormikCheckbox, FormikInput, FormikInputDateField, FormikTextArea } from 'molecules';
import { Button } from 'molecules/Button';
import FormikSelect from 'molecules/FormikSelect';
import { Text } from 'molecules/Text';
import * as yup from 'yup';

import React, { useCallback, useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { useFormSubmitWithLoading } from 'utils/hooks/useFormSubmitWithLoading';
import { deformatPhone, getCitiesList } from 'utils/utility';
import { validateMaxReachAddress, validateRequiredPostalCode } from 'utils/validators';
import { dropdownStyles } from 'utils/settings/constants';

const requestServiceAddressValidation = yup.object().shape({
  address1: validateMaxReachAddress().required('required'),
  address2: validateMaxReachAddress().nullable(),
  postalCode: validateRequiredPostalCode().required('required'),
  city: yup.string().required('required'),
  state: yup.string().required('required'),
  preferedAppointmentDate: yup.date().required('required').nullable(),
  locationType: yup.string().required('required'),
});

const initialValues = {
  MORNING: false,
  AFTERNOON: false,
  EVENING: false,
  ASAP: false,
  preferedAppointmentDate: '',
  address1: '',
  address2: '',
  postalCode: '',
  city: '',
  state: '',
  locationType: '',
  entryInstruction: '',
};
function RequestServiceAddress({ setFormStep, preInitialValues, onSubmit }: any) {
  const { onSubmitHandler, loading } = useFormSubmitWithLoading(onSubmit);
  const formRef = useRef<any>();
  const {
    actions: { postalCode },
  } = useAuth();
  const formInitialValues = { ...initialValues, ...preInitialValues };
  const [cityDisabled, setCityDisabled] = useState(true);
  const [cityList, setCityList] = useState<any[]>([]);

  const handlePostcodeBlur = useCallback(
    async (val: string) => {
      if (val) {
        const res = await postalCode(val);
        if (res.error) {
          toast.error(res.error);
          formRef?.current?.setFieldValue('city', '');
          formRef?.current?.setFieldValue('state', '');
          formRef?.current?.setFieldValue('postalCode', '');
        }
        if (res.success) {
          formRef?.current?.setFieldValue('city', res.message.city);
          formRef?.current?.setFieldValue('state', res.message.state);
          setCityList(getCitiesList(res.message.state));
          setCityDisabled(false);
        }
      }
    },
    [postalCode],
  );
  useEffect(() => {
    if (formInitialValues.state) {
      setCityList(getCitiesList(formInitialValues.state));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="">
      <div className="pb-4">
        <Text className="text-gray-800 font-bold" id="form.heading.3" />
      </div>
      <Formik
        initialValues={formInitialValues}
        onSubmit={onSubmitHandler}
        validationSchema={requestServiceAddressValidation}
        innerRef={formRef}
      >
        {({ isValid, dirty, setFieldValue, values, setFieldTouched, submitForm }) => (
          <Form className="flex flex-col gap-4 sm:grid  mt-2">
            <div className="flex flex-col gap-5 md:gap-10 md:grid  md:grid-cols-2 ">
              <div className="grid items-start">
                <FormikInputDateField
                  minDate={new Date()}
                  name="preferedAppointmentDate"
                  label="preferred.date"
                  fieldRequired
                />
              </div>
              <div className="grid items-start">
                <div>
                  <label className="flex text-sm font-medium text-primary">
                    <Text id="time.slot" />
                    <span className="text-red-600 ml-0.5">*</span>
                  </label>
                  <div className="flex flex-row  flex-wrap mt-3 justify-between">
                    <FormikCheckbox
                      type="checkbox"
                      disabled={(values['AFTERNOON'] && values['EVENING']) || values['ASAP']}
                      name="MORNING"
                      label="morning.checkbox.label"
                    />

                    <FormikCheckbox
                      disabled={(values['MORNING'] && values['EVENING']) || values['ASAP']}
                      type="checkbox"
                      name="AFTERNOON"
                      label="afternoon.checkbox.label"
                    />

                    <FormikCheckbox
                      type="checkbox"
                      disabled={(values['MORNING'] && values['AFTERNOON']) || values['ASAP']}
                      name="EVENING"
                      label="evening.checkbox.label"
                    />
                    <FormikCheckbox
                      type="checkbox"
                      name="ASAP"
                      label="asap.checkbox.label"
                      // @ts-ignore
                      onChange={() => {
                        setFieldValue('ASAP', !values.ASAP);

                        setFieldValue('MORNING', false);
                        setFieldValue('AFTERNOON', false);
                        setFieldValue('EVENING', false);
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="border my-4"></div>

            <div className="pb-4">
              <Text className="text-gray-800 font-bold" id="form.heading.4" />
            </div>
            <div className="flex flex-col gap-4 sm:grid sm:gap-10 sm:grid-cols-2 ">
              <FormikInput label="address1" name="address1" fieldRequired />

              <FormikInput label="address2.optional" name="address2" />
            </div>

            <div className="flex flex-col gap-4 sm:grid sm:gap-10 sm:grid-cols-2 ">
              <FormikInput
                label="postalCode"
                name="postalCode"
                // @ts-ignore
                onBlur={() => {
                  setFieldTouched('postalCode', true, true);
                  handlePostcodeBlur(values?.postalCode);
                }}
                onChange={(e: any) => {
                  const postalCodeValue = deformatPhone(e.currentTarget.value);
                  formRef.current.setFieldValue('postalCode', postalCodeValue);
                }}
                onFocus={() => {
                  setFieldValue('city', '');
                  setFieldValue('state', '');
                  setCityDisabled(true);
                }}
                // onChange={(v: React.ChangeEvent<HTMLInputElement>) =>
                //   handleZipCodeChange(v as InputEvent)
                // }
                fieldRequired
              />

              <div className="relative">
                <FormikSelect
                  label="city"
                  name="city"
                  customStyles={dropdownStyles}
                  disabled={cityDisabled}
                  options={cityList}
                  fieldRequired
                />
              </div>
            </div>

            <div className="flex flex-col gap-4 sm:grid sm:gap-10 sm:grid-cols-2 ">
              <FormikInput label="state" name="state" disabled fieldRequired />
              <FormikSelect
                label="location.type"
                name="locationType"
                options={[
                  { label: 'Home', value: 'Home' },
                  { label: 'Apartment', value: 'Apartment' },
                  { label: 'Office', value: 'Office' },
                  { label: 'Other', value: 'Other' },
                ]}
                fieldRequired
              />
            </div>

            <div className="grid gap-4 sm:gap-10">
              <FormikTextArea
                label="entry.instruction"
                name="entryInstruction"
                //@ts-ignore
                minRows={4}
                maxRows={6}
              />
            </div>

            <div className="flex justify-start gap-3 sm:gap-6 border-t-2 border-gray-100 py-6">
              <Button
                type="button"
                onClick={() => setFormStep(2)}
                textid="previous"
                className="border-primary border-1 w-full px-0 justify-center sm:w-auto sm:px-16 sm:py-3 text-primary hover:bg-primary hover:text-white bg-lightBlue"
              />
              <Button
                loading={loading}
                textid="continue"
                className="w-full px-0 justify-center sm:w-auto sm:px-16 sm:py-3"
                type="button"
                onClick={() => {
                  let timeSlotError =
                    values.AFTERNOON || values.EVENING || values.MORNING || values.ASAP
                      ? ''
                      : 'Preferred Time Slot is required.';
                  if (timeSlotError || !dirty || !isValid) {
                    toast.warn(<div className="mt-2">{timeSlotError}</div>);
                    if (!dirty || !isValid) {
                      submitForm();
                    }
                  } else {
                    submitForm();
                  }
                }}
              />
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default RequestServiceAddress;
