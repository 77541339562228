import React, { useState } from 'react';
import useAuth from 'contexts/AuthenticationContext';

import { appRoutes } from 'utils/settings/constants';
import OTPVerifyForm from 'organisms/ForgotPassword/OTPVerifyForm';
import ResetPasswordForm from 'organisms/ForgotPassword/ResetPasswordForm';
import { useHistory } from 'react-router';
import { useLocationQuery } from 'utils/hooks/useLocationQuery';
import { AdminAuthWrapper } from 'templates/AuthWrapper';
import { Text } from 'molecules/Text';
import { ThumbUpIcon } from '@heroicons/react/solid';

const SetNewAccountPassword = ({ redirectTo }: { redirectTo?: string }) => {
  const [formStep, setFormStep] = useState(1);
  const searchParams = useLocationQuery();
  const [tokenToVerify, setTokenToVerify] = useState('');
  const {
    actions: { resetPassword },
  } = useAuth();
  const router = useHistory();
  const onOTPFormConfirm = (token: string) => {
    if (token) {
      setTokenToVerify(token);
    }
    setFormStep(2);
  };
  const onResetPasswordFormSubmit = async (value: any) => {
    const formData = {
      newPassword: value?.password,
      token: tokenToVerify,
    };
    if (searchParams?.userId) {
      await resetPassword(formData);
    } else {
      await resetPassword(formData, () => router.push(redirectTo || appRoutes?.ADMIN));
    }
    setFormStep(3);
  };

  return (
    <AdminAuthWrapper>
      <div className="md:py-16 md:px-16 py-10 px-8">
        {(() => {
          if (formStep === 1) {
            return (
              <OTPVerifyForm
                onConfirm={onOTPFormConfirm}
                emailToVerify={searchParams?.userId}
                phoneText
                userId={true}
              />
            );
          } else if (formStep === 2) {
            return (
              <ResetPasswordForm
                onBackButton={() => setFormStep(1)}
                onSubmit={onResetPasswordFormSubmit}
              />
            );
          } else if (formStep === 3) {
            return (
              <div className="flex gap-2 items-start">
                <ThumbUpIcon className="w-8 h-8 text-primary" />
                <Text
                  className="text-gray-500 mt-1 text-lg font-bold"
                  id="password.change.success"
                />
              </div>
            );
          }
        })()}
      </div>
    </AdminAuthWrapper>
  );
};

export default SetNewAccountPassword;
