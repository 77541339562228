import React from 'react';
import { Text } from 'molecules/Text';
import { LocaleString } from 'locales';
import { ErrorMessage } from 'formik';

interface ErrorProps {
  text: LocaleString;
}
export const Error = ({ text }: ErrorProps) => {
  return <Text className="text-red-600 mt-1 text-xs" id={text} />;
};
export const FormikError = ({ name }: { name: string }) => {
  return (
    <ErrorMessage name={name}>{(error) => <Error text={error as LocaleString} />}</ErrorMessage>
  );
};
