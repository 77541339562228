import { useCallback, useReducer } from 'react';
import { toast } from 'react-toastify';
import { createContainer, createReducer, createAsyncActions } from 'utils/context';
import axiosService from '../utils/api/axios';
import { downloadFile } from 'utils/utility';
import axios from 'axios';

const initialState = {
  patientQuestionareDetails: {},
  patientQuestionareDetailsLoading: false,
  submittedQuestionsList: {},
  submittedQuestionareListLoading: false,
  downloadPatientReportLoading: false,
};

const actions = {
  patientQuestionareDetails: createAsyncActions('GET_QUESTIONARE_DETAILS'),
  sendQuestionareLink: createAsyncActions('POST_QUESTIONARE_LINK'),
  submittedQuestionsList: createAsyncActions('GET_CALCULATOR_LIST'),
  getPatientReport: createAsyncActions('GET_PATIENT_REPORT'),
  attachPatientReport: createAsyncActions('ATTACH_PATIENT_REPORT'),
  resendQuestionare: createAsyncActions('POST_RESEND_QUESTIONARE'),
  addStatus: createAsyncActions('POST_ADD_STATUS'),
};

const prometheusReducer = createReducer({
  [`${actions.patientQuestionareDetails.loading}`]: (state) => ({
    ...state,
    patientQuestionareDetailsLoading: true,
  }),
  [`${actions.patientQuestionareDetails.success}`]: (state, { payload }) => ({
    ...state,
    patientQuestionareDetails: payload,
    patientQuestionareDetailsLoading: false,
  }),
  [`${actions.patientQuestionareDetails.failure}`]: (state) => ({
    ...state,
    patientQuestionareDetailsLoading: false,
  }),

  [`${actions.submittedQuestionsList.loading}`]: (state) => ({
    ...state,
    submittedQuestionsListLoading: true,
  }),
  [`${actions.submittedQuestionsList.success}`]: (state, { payload }) => ({
    ...state,
    submittedQuestionsList: payload,
    submittedQuestionsListLoading: false,
  }),
  [`${actions.submittedQuestionsList.failure}`]: (state) => ({
    ...state,
    submittedQuestionsListLoading: false,
  }),

  [`${actions.getPatientReport.loading}`]: (state) => ({
    ...state,
    downloadPatientReportLoading: true,
  }),
  [`${actions.getPatientReport.success}`]: (state, { payload }) => ({
    ...state,
    documentUrl: payload,
    downloadPatientReportLoading: false,
  }),
  [`${actions.getPatientReport.failure}`]: (state) => ({
    ...state,
    downloadPatientReportLoading: false,
  }),

  [`${actions.resendQuestionare.loading}`]: (state) => ({
    ...state,
    resendQuestionareLoading: true,
  }),
  [`${actions.resendQuestionare.success}`]: (state) => ({
    ...state,
    resendQuestionareLoading: false,
  }),
  [`${actions.resendQuestionare.failure}`]: (state) => ({
    ...state,
    resendQuestionareLoading: false,
  }),
  [`${actions.addStatus.loading}`]: (state) => ({
    ...state,
    addStatusLoading: true,
  }),
  [`${actions.addStatus.success}`]: (state, { payload }) => ({
    ...state,
    // Optionally update the state with new data if needed
    addStatusLoading: false,
  }),
  [`${actions.addStatus.failure}`]: (state) => ({
    ...state,
    addStatusLoading: false,
  }),
});

export const { useContext: usePrometheus, Provider: PrometheusProvider } = createContainer(() => {
  const [state, dispatch] = useReducer(prometheusReducer, initialState);

  const addStatus = useCallback(async (id: any) => {
    try {
      dispatch(actions.addStatus.loading());
      const res = await axiosService.post(`/list/update-p360-form-status/${id}`);
      toast.success(res?.data?.message);
      getSubmittedQuestionsList();
      dispatch(actions.addStatus.success(res?.data));
      return res;
    } catch (e) {
      toast.error(e?.response?.data?.message);
      dispatch(actions.addStatus.failure());
    }
  }, []);
  const sendQuestionareLink = useCallback(async (formData: any) => {
    try {
      const res = await axiosService.post(`/list/prometheus/send-questionare`, formData);
      toast.success(res?.data?.message);
      getSubmittedQuestionsList();
      return res;
    } catch (e) {
      toast.error(e?.response?.data?.message);
    }
  }, []);

  const getPatientQuestionareDetails = useCallback(async (id) => {
    try {
      dispatch(actions.patientQuestionareDetails.loading());
      const { data } = await axiosService.get(`/list/prometheus/patient-questionare-detail/${id}`);

      dispatch(actions.patientQuestionareDetails.success(data?.data?.response));
      return data;
    } catch (e) {
      toast.error(e?.response?.data?.message);
      dispatch(actions.patientQuestionareDetails.failure());
    }
  }, []);
  const deleteQuestionareForm = useCallback(async (id) => {
    try {
      const { data } = await axiosService.delete(`/list/delete-p360-form/${id}`);
      getSubmittedQuestionsList();
      return data;
    } catch (e) {
      toast.error(e?.response?.data?.message);
    }
  }, []);
  const submitQuestionnaire = useCallback(async (formData: any) => {
    try {
      const res = await axiosService.post(`/list/prometheus/submit`, formData);
      return res;
    } catch (e) {
      toast.error(e?.response?.data?.message);
    }
  }, []);

  const getSubmittedQuestionsList = async (
    pageNum = '1',
    take?: string | number,
    searchQuery?: string,
    startDate?: string,
    endDate?: string,
    isCompleteStatus?: string
  ) => {
    try {
      dispatch(actions.submittedQuestionsList.loading());
      
      let api = `/list/submitted-questionare?page=${pageNum}&take=${take || 10}`
        + (searchQuery ? `&searchQuery=${searchQuery}` : '')
        + (startDate ? `&startDate=${startDate}` : '')
        + (endDate ? `&endDate=${endDate}` : '')
        + (isCompleteStatus !== undefined ? `&status=${isCompleteStatus}` : '');
      
      const { data } = await axiosService?.get(api);
      dispatch(actions.submittedQuestionsList.success(data?.data?.serviceList));
      return data;
      } catch (e) {
      // @ts-ignore
      toast.error(e?.response?.data?.message);
      dispatch(actions.submittedQuestionsList.failure());
    }
    return undefined;
  };

  const getPatientReport = useCallback(async (id: string) => {
    try {
      dispatch(actions.getPatientReport.loading());
      const response = await axios({
        //remove this baseURL after the old base url works fine
        // baseURL: 'https://qa.rch.api-build-release.com/v1',
        url: `/list/download/${id}`,
        method: 'GET',
        responseType: 'blob',
      });

      const url = new Blob([response?.data], { type: 'application/pdf' });
      downloadFile(url, 'Predictive360_Assessment.pdf');
      dispatch(actions.getPatientReport.success(response?.data?.data?.document));
      toast.success('Downloaded Successfully');
      return response?.data?.data?.documentUrl;
    } catch (e) {
      toast.error(e?.response?.data?.message);
      dispatch(actions.getPatientReport.failure());
    }
    return undefined;
  }, []);

  const attachPatientReport = useCallback(
    async (patientId: string, questionniareId: string, appointmentId?: string) => {
      try {
        const response = await axios({
          url: `/list/download-and-attach?${patientId && `patientId=${patientId}`}${
            questionniareId && `&questionniareId=${questionniareId}`
          }${appointmentId && `&appointmentId=${appointmentId}`}`,
          method: 'GET',
          responseType: 'blob',
        });

        const url = new Blob([response?.data], { type: 'application/pdf' });
        downloadFile(url, 'Predictive360_Assessment.pdf');
        toast.success('Downloaded Successfully');
        return response?.data?.data?.documentUrl;
      } catch (e) {
        toast.error(e?.response?.data?.message);
      }
      return undefined;
    },
    [],
  );
  const resendQuestionare = useCallback(async (id) => {
    try {
      dispatch(actions.resendQuestionare.loading());
      const res = await axiosService.post(`/list/resend/${id}`);
      toast.success(res?.data?.message);
      dispatch(actions.resendQuestionare.success());
      return res;
    } catch (e) {
      toast.error(e?.response?.data?.message);
      dispatch(actions.resendQuestionare.failure());
    }
  }, []);

  return {
    state,
    actions: {
      sendQuestionareLink,
      getPatientQuestionareDetails,
      deleteQuestionareForm,
      submitQuestionnaire,
      getSubmittedQuestionsList,
      getPatientReport,
      attachPatientReport,
      resendQuestionare,
      addStatus,
    },
  };
});

export default usePrometheus;
