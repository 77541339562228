import React, { Fragment, useEffect } from 'react';
import { Popover, Transition } from '@headlessui/react';
import { MenuIcon, XIcon } from '@heroicons/react/outline';
import logo from 'assets/icons/Favicon-Dark-20211230.png';
import { Image } from 'atoms/Image/Image';
import dummyProfile from 'assets/images/dummy-profile-image.png';
import { BellIcon } from '@heroicons/react/outline';
import useAuth from 'contexts/AuthenticationContext';
import { Link, NavLink, useHistory } from 'react-router-dom';
import { appRoutes } from 'utils/settings/constants';
import { Text } from 'molecules/Text';

export default function Header() {
  const {
    state: { user },
    actions: { refreshUser },
  } = useAuth();

  // to update notification/chat counts
  useEffect(() => {
    refreshUser();
  }, [refreshUser]);

  const router = useHistory();

  return (
    <>
      <Popover className=" bg-white border-b-2 border-gray-100 w-screen py-2 fixed z-50">
        {({ open }) => (
          <>
            <div className="mx-auto px-10 py-2 max-w-7xl">
              <div className="flex justify-between items-center  lg:space-x-8 md:space-x-2">
                <div className="flex justify-start items-center">
                  <span className="sr-only">LX MEDICAL</span>
                  <Image
                    src={logo}
                    alt="logo"
                    height="40"
                    width="40"
                    className="cursor-pointer"
                    onClick={() => router.push('/')}
                  />
                  <span
                    className="m-auto ml-2 text-xl font-regular text-gray-600 cursor-pointer"
                    onClick={() => router.push('/')}
                  >
                    LX MEDICAL
                  </span>

                  <div className="hidden md:flex space-x-6 pl-10 pt-1">
                    <NavLink
                      exact
                      to={appRoutes?.HOME}
                      className="lg:px-6 md:px-4 text-base font-medium hover:text-primary"
                      activeClassName="text-primary border-b-2 border-primary -mb-5"
                    >
                      <Text id="header.home" />
                    </NavLink>

                    <NavLink
                      exact
                      to={appRoutes?.APPOINTMENTS}
                      isActive={() => router?.location?.pathname.includes(appRoutes?.APPOINTMENTS)}
                      className="lg:px-6 md:px-4 text-base font-medium hover:text-primary"
                      activeClassName="text-primary border-b-2 border-primary -mb-5"
                    >
                      <Text id="header.appointments" />
                    </NavLink>

                    <NavLink
                      exact
                      to={appRoutes?.CHARTS}
                      isActive={() => router?.location?.pathname.includes(appRoutes?.CHARTS)}
                      className="lg:px-6 md:px-4 text-base font-medium hover:text-primary"
                      activeClassName="text-primary border-b-2 border-primary -mb-5"
                    >
                      <Text id="header.charts" />
                    </NavLink>

                    <NavLink
                      exact
                      to={appRoutes?.CHAT}
                      className="lg:px-6 md:px-4 text-base font-medium hover:text-primary"
                      activeClassName="text-primary border-b-2 border-primary -mb-5"
                    >
                      <div className="relative">
                        <Text id="header.chat" />
                        {user?.unreadChatCount && user?.unreadChatCount > 0 ? (
                          <span className="absolute text-center text-sm py-1 -top-2 left-8 w-7 h-7 font-medium rounded-full text-white bg-red-500">
                            {user?.unreadChatCount}
                          </span>
                        ) : null}
                      </div>
                    </NavLink>
                  </div>
                </div>

                <div className="-mr-2 -my-2 md:hidden">
                  <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                    <span className="sr-only">Open menu</span>
                    <MenuIcon className="h-6 w-6" aria-hidden="true" />
                  </Popover.Button>
                </div>

                <div className="hidden md:flex items-center lg:justify-end cursor-pointer">
                  <Link to={appRoutes?.NOTIFICATIONS}>
                    <div className="relative">
                      <BellIcon className="text-gray-600 lg:mr-12 md:mr-4 w-7 h-7" />
                      {user?.unreadNotificationCount && user?.unreadNotificationCount > 0 ? (
                        <span className="absolute text-center text-sm py-1 -top-3 left-3 w-10 h-7 font-medium rounded-full text-white bg-red-500">
                          {Number(user?.unreadNotificationCount) > 99
                            ? '99+'
                            : user?.unreadNotificationCount}
                        </span>
                      ) : null}
                    </div>
                  </Link>

                  <span className="sr-only">Open user menu</span>
                  <Link to="/profile" className="flex m">
                    <div className=" rounded-full p-1 bg-gray-300 ">
                      <Image
                        className="h-7 w-7 rounded-full border border-primary"
                        src={user?.profileImage || dummyProfile}
                      />
                    </div>
                    <span className="hidden lg:block m-auto ml-2 text-base font-medium text-gray-600 capitalize truncate">
                      {user?.firstName + ' ' + user?.lastName}
                    </span>
                  </Link>
                </div>
              </div>
            </div>

            <Transition
              show={open}
              as={Fragment}
              enter="duration-200 ease-out"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="duration-100 ease-in"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Popover.Panel
                focus
                static
                className="absolute top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden z-30"
              >
                <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y-2 divide-gray-50 z-30">
                  <div className="pt-5 pb-6 px-5">
                    <div className="flex items-center justify-between">
                      <div className="flex flex-row">
                        <Image src={logo} alt="logo" height="30" width="30" />
                        <span className="m-auto ml-2 text-base font-medium text-gray-600">
                          LX MEDICAL
                        </span>
                      </div>
                      <div className="-mr-2">
                        <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                          <span className="sr-only">Close menu</span>
                          <XIcon className="h-6 w-6" aria-hidden="true" />
                        </Popover.Button>
                      </div>
                    </div>
                  </div>

                  <div className="flex flex-col pl-8">
                    <NavLink
                      exact
                      to={appRoutes?.HOME}
                      className="text-base font-medium py-2 hover:text-primary"
                      activeClassName="text-primary"
                    >
                      <Text id="header.home" />
                    </NavLink>

                    <NavLink
                      exact
                      to={appRoutes?.APPOINTMENTS}
                      className="text-base font-medium py-2 hover:text-primary"
                      activeClassName="text-primary"
                    >
                      <Text id="header.appointments" />
                    </NavLink>

                    <NavLink
                      exact
                      to={appRoutes?.CHARTS}
                      className="text-base font-medium py-2 hover:text-primary"
                      activeClassName="text-primary"
                    >
                      <Text id="header.charts" />
                    </NavLink>

                    <NavLink
                      exact
                      to={appRoutes?.CHAT}
                      className="text-base font-medium py-2 hover:text-primary"
                      activeClassName="text-primary"
                    >
                      <Text id="header.chat" />
                    </NavLink>
                  </div>

                  <div className="flex flex-col pl-6 py-2">
                    <Link
                      to={appRoutes.NOTIFICATIONS}
                      className="text-base text-gray-600 hover:text-gray-700 flex flex-row space-x-2"
                    >
                      <BellIcon width="25" height="25" className="my-2" />
                      <Text className="font-medium py-2" id="header.notifications" />
                      {user?.unreadNotificationCount && user?.unreadNotificationCount > 0 ? (
                        <span className=" text-center text-sm py-1 mt-1 w-10 h-7 font-medium rounded-full text-white bg-red-500">
                          {Number(user?.unreadNotificationCount) > 99
                            ? '99+'
                            : user?.unreadNotificationCount}
                        </span>
                      ) : null}
                    </Link>
                  </div>
                  <Link to="/profile">
                    <div className="py-4 px-5 flex flex-row">
                      <span className="sr-only">Open user menu</span>

                      <Image
                        className="h-8 w-8 rounded-full"
                        src={user?.profileImage || dummyProfile}
                        alt="pankod"
                        width="20"
                        height="20"
                      />
                      <span className="pl-2 pt-1 m-auto ml-2 text-base font-medium text-gray-600">
                        {user?.firstName + ' ' + user?.lastName}
                      </span>
                    </div>
                  </Link>
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    </>
  );
}
