import React, { Suspense, lazy } from 'react';
import { Switch, Route, Redirect } from 'react-router';

import { Loader } from 'atoms/Loader';
import { appRoutes, ROLES } from 'utils/settings/constants';

// const PatientLogin = lazy(
//   () => import(/* webpackChunkName: 'PatientLogin' */ '../modules/PatientPanel/PatientLogin'),
// );

// const PatientSignup = lazy(
//   () => import(/* webpackChunkName: 'PatientSignup' */ '../modules/PatientPanel/PatientSignup'),
// );

// const PatientForgotPassword = lazy(
//   () =>
//     import(
//       /* webpackChunkName: 'PatientForgotPassword' */ '../modules/PatientPanel/PatientForgotPassword'
//     ),
// );

const AdminLogin = lazy(
  () => import(/* webpackChunkName: 'AdminLogin' */ 'modules/AdminPanel/AdminLogin'),
);

const AdminDashboard = lazy(
  () => import(/* webpackChunkName: 'AdminDashboard' */ '../modules/AdminPanel/AdminDashboard'),
);

const AdminForgotPassword = lazy(
  () =>
    import(
      /* webpackChunkName: 'AdminForgotPassword' */ '../modules/AdminPanel/AdminForgotPassword'
    ),
);

const PrometheusQuestionareForm = lazy(
  () =>
    import(
      /* webpackChunkName: 'AdminForgotPassword' */ '../modules/WebForm/PrometheusQuestionareForm'
    ),
);

const Logout = lazy(() => import(/* webpackChunkName: 'Logout' */ '../pages/logout'));

const Homepage = lazy(
  () => import(/* webpackChunkName: 'Homepage' */ '../modules/PatientPanel/Home'),
);

const PatientAppointments = lazy(
  () =>
    import(
      /* webpackChunkName: 'PatientAppointments' */ '../modules/PatientPanel/PatientAppointments'
    ),
);

const PatientNotifications = lazy(
  () => import(/* webpackChunkName: 'PatientNotifications' */ '../modules/Notifications'),
);

const PatientBookAppointment = lazy(
  () =>
    import(
      /* webpackChunkName: 'PatientBookAppointment' */ '../modules/PatientPanel/PatientAppointments/Components/BookAppointmentFlow'
    ),
);

const PatientAppointmentDetails = lazy(
  () =>
    import(
      /* webpackChunkName: 'PatientAppointmentDetails' */ '../modules/PatientPanel/PatientAppointments/Components/AppointmentDetails'
    ),
);

const Chat = lazy(
  () => import(/* webpackChunkName: 'PatientChat' */ '../modules/PatientPanel/Chat'),
);

const PatientProviderChat = lazy(
  () =>
    import(
      /* webpackChunkName: 'PatientProviderChat' */ '../modules/PatientPanel/PatientAppointments/Components/PatientProviderChat'
    ),
);

const LandingPage = lazy(
  () => import(/* webpackChunkName: 'LandingPage' */ '../modules/LandingPage'),
);

const Charts = lazy(
  () => import(/* webpackChunkName: 'ChartsPage' */ '../modules/PatientPanel/Charts'),
);

const PatientRecordDashboard = lazy(
  () =>
    import(
      /* webpackChunkName: 'PatientRecordDashboard' */ '../modules/PatientPanel/Charts/PatientRecordDashboard/PatientRecordDashboard'
    ),
);

const PastMedicalHistory = lazy(
  () =>
    import(
      /* webpackChunkName: 'PastMedicalHistory' */ '../modules/PatientPanel/Charts/PatientMedicalHistory'
    ),
);

const PatientProfile = lazy(
  () => import(/* webpackChunkName: 'PatientProfile' */ '../modules/PatientPanel/PatientProfile'),
);

export const UnauthenticatedRoutes = () => {
  return (
    <Suspense fallback={<Loader />}>
      <Switch>
        {/* <Route path="/" exact component={LandingPage} />

        <Route path={appRoutes?.LOGIN_PAGE} component={PatientLogin} />

        <Route path={appRoutes?.SIGNUP_PAGE} component={PatientSignup} />

        <Route path={appRoutes?.FORGOT_PASSWORD} component={PatientForgotPassword} /> */}

        <Route path={appRoutes?.ADMIN_FORGOT_PASSWORD} component={AdminForgotPassword} />

        <Route path={`${appRoutes?.PROMETHEUS}/:id`} component={PrometheusQuestionareForm} />

        <Route path="/" exact component={AdminLogin} />
        <Redirect to={'/'} />
      </Switch>
    </Suspense>
  );
};
export interface AdminRoutingsProps {
  codeOfRoles?: string;
}

export const AdminRoutes = ({ codeOfRoles }: AdminRoutingsProps) => {
  return (
    <Suspense fallback={<Loader />}>
      <Switch>
        <Route
          path={codeOfRoles === ROLES?.ADMIN ? appRoutes?.ADMIN : appRoutes?.SUPER_ADMIN}
          render={() => <AdminDashboard codeOfRoles={codeOfRoles} />}
        />
        <Route path={appRoutes?.LOGOUT} component={Logout} />
        <Redirect to={codeOfRoles === ROLES?.ADMIN ? appRoutes?.ADMIN : appRoutes?.SUPER_ADMIN} />
      </Switch>
    </Suspense>
  );
};

export const AuthenticatedRoutes = React.memo(() => {
  return (
    <Suspense fallback={<Loader />}>
      <Switch>
        <Route path="/" exact component={LandingPage} />

        <Route
          path={`${appRoutes?.CHAT_PROVIDER}/:id`}
          render={() => (
            <div className="max-w-5xl mx-auto sm:px-6 pt-20">
              <PatientProviderChat />
            </div>
          )}
        />

        <Route
          path={appRoutes?.CHAT}
          render={() => (
            <div className="max-w-5xl mx-auto sm:px-6 pt-20">
              <Chat />
            </div>
          )}
        />

        <Route
          path={appRoutes?.HOME}
          render={() => (
            <div className="max-w-5xl mx-auto sm:px-6 pt-20">
              <Homepage />
            </div>
          )}
        />

        <Route
          path={`${appRoutes?.APPOINTMENTS_BOOK}/:category?`}
          render={() => (
            <div className="max-w-5xl mx-auto sm:px-6 pt-20">
              <PatientBookAppointment />
            </div>
          )}
        />

        <Route
          path={`${appRoutes?.APPOINTMENT_DETAILS}/:id`}
          render={() => (
            <div className="max-w-5xl mx-auto sm:px-6 pt-20">
              <PatientAppointmentDetails />
            </div>
          )}
        />

        <Route
          path={`${appRoutes?.APPOINTMENTS}`}
          render={() => (
            <div className="max-w-5xl mx-auto sm:px-6 pt-20">
              <PatientAppointments />
            </div>
          )}
        />

        <Route
          path={`${appRoutes?.NOTIFICATIONS}`}
          render={() => (
            <div className="max-w-5xl mx-auto sm:px-6 pt-20">
              <PatientNotifications />
            </div>
          )}
        />

        <Route
          path={`${appRoutes?.CHARTS_HISTORY}/:subURL/:id`}
          render={() => (
            <div className="max-w-5xl mx-auto sm:px-6 pt-20">
              <PatientRecordDashboard />
            </div>
          )}
        />

        <Route
          path={`${appRoutes?.CHARTS}/:subURL/:id`}
          render={() => (
            <div className="max-w-5xl mx-auto sm:px-6 pt-20">
              <PastMedicalHistory baseRoute="" />
            </div>
          )}
        />

        <Route
          path={appRoutes?.CHARTS}
          render={() => (
            <div className="max-w-5xl mx-auto sm:px-6 pt-20">
              <Charts />
            </div>
          )}
        />

        <Route
          path={appRoutes?.PROFILE}
          render={() => (
            <div className="max-w-5xl mx-auto sm:px-6 pt-20">
              <PatientProfile />
            </div>
          )}
        />

        <Route path={appRoutes?.LOGOUT} component={Logout} />

        <Redirect to={appRoutes?.HOME} />
      </Switch>
    </Suspense>
  );
});
