import { PencilIcon } from '@heroicons/react/solid';
import useAdminAppointment from 'contexts/AdminAppointmentContext';
import { LocaleString } from 'locales';
import { Button, CancelButton } from 'molecules/Button';
import { Text } from 'molecules/Text';
import React, { useCallback, useState } from 'react';
import { toast } from 'react-toastify';
import axiosService from 'utils/api/axios';
import { useFormSubmitWithLoading } from 'utils/hooks/useFormSubmitWithLoading';
import parse from 'html-react-parser';
import ChangeSoapNoteModal from 'modules/AdminPanel/AdminDashboard/AdminComponent/AdminAppointmentSection/AdminAppointmentDetails/components/ChangeSoapNoteModal';
import getAdminDashboard from 'contexts/AdminDashboardContext';
import { Image } from 'atoms/Image/Image';
import docxIcon from '../../assets/icons/docx-icon.svg';
import pdfIcon from '../../assets/icons/pdf-icon.svg';

type CommentCardType = {
  comment: string;
  labelId?: LocaleString;
  appointmentId?: any;
  providerId?: string;
  noProvider?: boolean;
  isSoapNote?: boolean;
  appointmentDetails?: any;
  symptomimages?: string[];
  AppointmentDetails?: any;
};

export default function CommentCard({
  comment,
  labelId,
  appointmentId,
  noProvider,
  isSoapNote,
  appointmentDetails,
  symptomimages,
}: CommentCardType) {
  const [state, setState] = useState(false);
  const [soapNoteState, setSoapNoteState] = useState(false);
  const [adminComment, setAdminComment] = useState('');
  const [isEdit, setIsEdit] = useState(false);

  const {
    actions: { changeComment, getAppointmentDetails },
  } = useAdminAppointment();
  const {
    actions: { updateSoapNoteInAppointmentDetails },
  } = getAdminDashboard();
  const onSubmitComment = useCallback(async () => {
    let formData = {
      medicalOrder: adminComment.trim(),
    };
    const data = await changeComment(formData, appointmentId);
    data && setIsEdit(false);
  }, [adminComment, appointmentId, changeComment]);

  const { onSubmitHandler, loading } = useFormSubmitWithLoading(onSubmitComment);

  const [providerSummaryLoading, setProviderSummaryLoading] = useState(false);

  const onSubmitProviderSummary = useCallback(async () => {
    let formData = {
      appointmentId: appointmentDetails?.id,
      commentsForPatient: appointmentDetails?.commentsForPatient,
      providerNotes: appointmentDetails?.providerNotes,
      providerSummary: adminComment.trim(),
    };
    setProviderSummaryLoading(true);
    await axiosService
      ?.post(`/appointment/add-comments`, {
        ...formData,
      })
      .then(
        (res) => {
          toast.success(res?.data?.message);
          setProviderSummaryLoading(false);
          res && getAppointmentDetails(appointmentDetails?.id);
          res && setIsEdit(false);
        },
        (err) => {
          toast.error(err?.response?.data?.message);
          setProviderSummaryLoading(false);
        },
      );
  }, [adminComment, appointmentDetails, getAppointmentDetails]);

  const openImage = (img: string) => {
    if (img) {
      window.open(img, '_blank');
    }
  };

  const onSubmitTemplate = useCallback(
    async (values: any) => {
      const postData = {
        appointmentId: appointmentDetails?.id,
        soapnoteTemplateid: values?.soapNoteTemplateId?.id,
      };
      const data = await updateSoapNoteInAppointmentDetails(postData);
      data && setSoapNoteState(false);
      data && getAppointmentDetails(appointmentDetails?.id);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [appointmentDetails?.id],
  );
  // Provider Summary is Appointment Notes
  return (
    <>
      {soapNoteState && (
        <ChangeSoapNoteModal
          open={soapNoteState}
          onClose={() => setSoapNoteState(false)}
          onSubmit={onSubmitTemplate}
          appointmentDetails={appointmentDetails}
        />
      )}
      <div className="break-all hover:shadow-md font-semibold text-sm bg-white px-3 rounded shadow border-t border-gray-100">
        {labelId && (
          <div
            className={`flex items-center justify-between ${'border-b border-gray-100 p-1.5'} ${
              labelId === 'adminComments.medical.orders' || labelId === 'appointment.notes'
            }`}
          >
            <Text
              id={comment ? labelId : 'adminComments.medical.orders'}
              className="font-medium "
            />
            {(labelId === 'adminComments.medical.orders' || labelId === 'appointment.notes') &&
              !isEdit && (
                <PencilIcon
                  className="w-5 h-5 inline text-primary cursor-pointer"
                  onClick={() => {
                    noProvider !== null ? setAdminComment(comment) : setAdminComment('');
                    setIsEdit(true);
                  }}
                />
              )}
            {isSoapNote && appointmentDetails?.soapNoteTemplateName && (
              <div className="text-base font-semibold italic text-primary">
                {appointmentDetails?.soapNoteTemplateName}
              </div>
            )}
          </div>
        )}
        {!isEdit ? (
          <div>
            {
              <>
                {comment ? (
                  isSoapNote ? (
                    <div className="px-1.5 py-2">
                      {parse(comment?.length > 90 ? comment?.slice(0, 90) : comment)}

                      <span
                        className="text-indigo-600 cursor-pointer whitespace-nowrap"
                        onClick={() => setSoapNoteState(true)}
                      >
                        ...
                        {appointmentDetails?.appointmentStatus === 'SCHEDULED'
                          ? 'detail view/change template'
                          : 'detail view'}
                      </span>
                    </div>
                  ) : (
                    <div className="text-gray-600 text-sm px-1.5 py-2 whitespace-pre-wrap break-normal ">
                      {comment?.length > 90 && !state ? comment?.slice(0, 88) + '...' : comment}

                      {comment?.length > 90 && (
                        <span
                          className="text-indigo-600 cursor-pointer whitespace-nowrap"
                          onClick={() => setState(!state)}
                        >
                          {state ? ' - show less' : 'show more'}
                        </span>
                      )}
                    </div>
                  )
                ) : (
                  <div className="px-1.5 py-2">
                    <Text
                      id="adminComments.noComments"
                      className="font-medium italic text-gray-500"
                    />
                  </div>
                )}

                {labelId === 'provider.soapnote' && symptomimages && symptomimages?.length > 0 && (
                  <>
                    <div className="text-gray-800 text-sm px-1.5 pt-1 border-t border-gray-100 whitespace-pre-wrap break-normal ">
                      Soap Note Images
                    </div>

                    <div
                      className={
                        'border-t border-gray-100 py-1.5 flex flex-wrap gap-4 px-1.5 text-sm'
                      }
                    >
                      {symptomimages?.map((img, i) => (
                        <span
                          key={i}
                          className="border-2 border-gray-300 cursor-pointer hover:shadow-corner"
                        >
                          <Image
                            src={
                              img.endsWith('.pdf')
                                ? pdfIcon
                                : img.endsWith('.png') ||
                                  img.endsWith('.jpg') ||
                                  img.endsWith('.jpeg')
                                ? img.replace(/"([^"]+(?="))"/g, '$1')
                                : docxIcon
                            }
                            className="h-12 w-12"
                            alt={`soap-note-${i + 1}`}
                            onClick={() => openImage(img.replace(/"([^"]+(?="))"/g, '$1'))}
                          />
                        </span>
                      ))}
                    </div>
                  </>
                )}
              </>
            }
          </div>
        ) : (
          <div className="mt-1.5 relative">
            <textarea
              value={adminComment}
              rows={3}
              onChange={(e) => setAdminComment(e.target.value)}
              className={`appearance-none block w-full px-2 py-1.5 border rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 text-xs font-base border-gray-300`}
            />

            <div className="flex py-2 justify-end gap-2">
              {labelId === 'adminComments.medical.orders' ? (
                <Button
                  type="submit"
                  loading={loading}
                  textid="save"
                  onClick={onSubmitHandler}
                  className="w-16 px-2 py-1 text-xs font-base border"
                />
              ) : (
                <Button
                  type="submit"
                  loading={providerSummaryLoading}
                  textid="edit"
                  onClick={onSubmitProviderSummary}
                  className="w-16 px-2 py-1 text-xs font-base border"
                />
              )}

              <CancelButton
                className=" w-16 px-1.5 py-0.5 text-xs font-base border hover:shadow-cornersm"
                type="button"
                onClick={() => {
                  setIsEdit(false);
                }}
              >
                Cancel
              </CancelButton>
            </div>
          </div>
        )}
      </div>
    </>
  );
}
