import { Image } from 'atoms/Image/Image';
import React from 'react';
import logo from 'assets/icons/Favicon-Light-20211230.png';
import { Text } from 'molecules/Text';

function VitalChart() {
  const adultVitals = [
    { vital: 'HR', range: '<50 or >100' },
    { vital: 'RR', range: '<12 or >20' },
    { vital: 'BP', range: '<100/60 or >150/90' },
    { vital: 'SPO2', range: '<92%' },
    { vital: 'Temp', range: '<97 or >100.4' },
  ];

  const pediatricVitals1 = [
    { vital: 'HR', range: '< 110 or > 160' },
    { vital: 'RR', range: '< 25 or > 55' },
    { vital: 'BP', range: '<85/40 or >100/55' },
    { vital: 'SPO2', range: '<95%' },
    { vital: 'Temp', range: '>/= 100.4' },
  ];

  const pediatricVitals2 = [
    { vital: 'HR', range: '<100 or >145' },
    { vital: 'RR', range: '<25 or >42' },
    { vital: 'BP', range: '<87/45 or >105/61' },
    { vital: 'SPO2', range: '<94%' },
    { vital: 'Temp', range: '>/= 100.4' },
  ];

  const pediatricVitals3 = [
    { vital: 'HR', range: '< 85 or >130' },
    { vital: 'RR', range: '< 20 or > 32' },
    { vital: 'BP', range: '<88/45 or  >107/65' },
    { vital: 'SPO2', range: '<93%' },
    { vital: 'Temp', range: '>/= 100.4' },
  ];

  const pediatricVitals4 = [
    { vital: 'HR', range: '< 80 or > 120' },
    { vital: 'RR', range: '<18 or > 27' },
    { vital: 'BP', range: '< 91/55 or  >110/70' },
    { vital: 'SPO2', range: '<93%' },
    { vital: 'Temp', range: '>/= 100.4' },
  ];

  const pediatricVitals5 = [
    { vital: 'HR', range: '<60 or > 105' },
    { vital: 'RR', range: '<15 or >22' },
    { vital: 'BP', range: '<95/60 or >115/78' },
    { vital: 'SPO2', range: '<93%' },
    { vital: 'Temp', range: '>/= 100.4' },
  ];

  const pediatricVitals6 = [
    { vital: 'HR', range: '<50 or >100' },
    { vital: 'RR', range: '<12 or >20' },
    { vital: 'BP', range: '<100/60 or >150/90' },
    { vital: 'SPO2', range: '<92%' },
    { vital: 'Temp', range: '<97 or >100.4' },
  ];

  return (
    <div className="h-screen ">
      <div className="h-screen">
        <div className="bg-primary flex items-center px-4 py-2">
          <Image src={logo} alt="logo" height="20" width="58" />
          <Text className="text-gray-50 font-medium text-lg mx-auto">
            Notify Provider Parameters
          </Text>
        </div>
        <div className="px-4 sm:px-6 py-4">
          <div className="p-4 shadow-corner mb-8 ">
            <Text className="text-center text-lg font-bold text-primary py-4">ADULTS</Text>
            <div className="text-primary text-base font-semibold mb-6">
              Notify provider if the following is noted:
            </div>
            <div className="mb-6 min-w-full">
              <table className="min-w-full leading-normal">
                <thead>
                  <tr className="border-b-2 border-gray-200 bg-gray-100">
                    <th className="pl-4 py-2 text-left text-base font-bold text-gray-700">
                      Vital Sign
                    </th>
                    <th className="px-2 py-2 text-center text-base font-bold text-gray-700">
                      Reference Range
                    </th>
                  </tr>
                </thead>
                <tbody className="font-medium">
                  {adultVitals.map((item) => {
                    return (
                      <tr className="border-b">
                        <td className="pl-4 py-2 text-left text-base font-semibold text-gray-700">
                          {item.vital}
                        </td>

                        <td className="px-2 py-2 text-center text-base font-semibold text-gray-700">
                          {item.range}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>

            <div className="mb-4 mt-8">
              <div className="md:grid md:grid-cols-9 gap-4 mb-4">
                <div className="whitespace-nowrap md:col-span-2 font-semibold text-primary text-lg">
                  Exam Findings :{' '}
                </div>
                <div className="md:col-span-7 font-medium text-sm">
                  altered mental status, abnormal lung sounds, irregular heart rhythm, difficulty
                  breathing, any further abnormal or unexpected findings, intoxicated
                </div>
              </div>

              <div className="md:grid md:grid-cols-9 gap-4 mb-4">
                <div className="whitespace-nowrap md:col-span-2 font-semibold text-primary text-lg">
                  Newly reported :{' '}
                </div>
                <div className="md:col-span-7 font-medium text-sm">
                  chest pain, shortness of breath, dizziness, confusion, extreme fatigue, unknown
                  newly reported symptom
                </div>
              </div>
            </div>
          </div>

          {/* PEDIATRICS */}
          <div className="p-4 shadow-corner mb-8 ">
            <Text className="text-center text-lg font-bold text-primary py-4">PEDIATRICS</Text>
            <div className="text-primary text-base font-semibold mb-6">
              Notify provider if the following is noted:
            </div>
            {/* 0-12 months */}
            <div className="pb-6 border-b">
              <div className="text-primary text-base font-bold mb-3">AGE 0-12 months</div>

              <div className="mb-6 min-w-full">
                <table className="min-w-full leading-normal">
                  <thead>
                    <tr className="border-b-2 border-gray-200 bg-gray-100">
                      <th className="pl-4 py-2 text-left text-base font-bold text-gray-700">
                        Vital Sign
                      </th>
                      <th className="px-2 py-2 text-center text-base font-bold text-gray-700">
                        Reference Range
                      </th>
                    </tr>
                  </thead>
                  <tbody className="font-medium">
                    {pediatricVitals1.map((item) => {
                      return (
                        <tr className="border-b">
                          <td className="pl-4 py-2 text-left text-base font-semibold text-gray-700">
                            {item.vital}
                          </td>

                          <td className="px-2 py-2 text-center text-base font-semibold text-gray-700">
                            {item.range}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>

              <div className="mb-4 mt-8">
                <div className="md:grid md:grid-cols-9 gap-4 mb-4">
                  <div className="whitespace-nowrap md:col-span-2 font-semibold text-primary text-lg">
                    Exam Findings :{' '}
                  </div>
                  <div className="md:col-span-7 font-medium text-sm">
                    High pitched cry or inconsolable, lethargy, retractions/difficulty breathing,
                    adventitious breath sounds, unknown murmur, mottled skin, delayed cap refill,
                    cyanosis, any further abnormal or unexpected findings
                  </div>
                </div>

                <div className="md:grid md:grid-cols-9 gap-4 mb-4">
                  <div className="whitespace-nowrap md:col-span-2 font-semibold text-primary text-lg">
                    Newly reported :{' '}
                  </div>
                  <div className="md:col-span-7 font-medium text-sm">
                    fevers, vomiting, no wet diaper for 6 hours, concerning change in behavior,
                    unknown newly reported symptom
                  </div>
                </div>
              </div>
            </div>

            {/* 13-24 months */}
            <div className="py-6 border-b">
              <div className="text-primary text-base font-bold mb-2">AGE 13-24 months</div>

              <div className="mb-6 min-w-full">
                <table className="min-w-full leading-normal">
                  <thead>
                    <tr className="border-b-2 border-gray-200 bg-gray-100">
                      <th className="pl-4 py-2 text-left text-base font-bold text-gray-700">
                        Vital Sign
                      </th>
                      <th className="px-2 py-2 text-center text-base font-bold text-gray-700">
                        Reference Range
                      </th>
                    </tr>
                  </thead>
                  <tbody className="font-medium">
                    {pediatricVitals2.map((item) => {
                      return (
                        <tr className="border-b">
                          <td className="pl-4 py-2 text-left text-base font-semibold text-gray-700">
                            {item.vital}
                          </td>

                          <td className="px-2 py-2 text-center text-base font-semibold text-gray-700">
                            {item.range}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>

              <div className="mb-4 mt-8">
                <div className="md:grid md:grid-cols-9 gap-4 mb-4">
                  <div className="whitespace-nowrap md:col-span-2 font-semibold text-primary text-lg">
                    Exam Findings :{' '}
                  </div>
                  <div className="md:col-span-7 font-medium text-sm">
                    High pitched cry or inconsolable, lethargy, retractions/difficulty breathing,
                    adventitious breath sounds, unknown murmur, mottled skin, delayed cap refill,
                    any further abnormal or unexpected findings
                  </div>
                </div>

                <div className="md:grid md:grid-cols-9 gap-4 mb-4">
                  <div className="whitespace-nowrap md:col-span-2 font-semibold text-primary text-lg">
                    Newly reported :{' '}
                  </div>
                  <div className="md:col-span-7 font-medium text-sm">
                    fevers, N/V/D, no wet diaper for 6 hours, concerning change in behavior, unknown
                    newly reported symptom
                  </div>
                </div>
              </div>
            </div>

            {/* 2-4 years */}

            <div className="py-6 border-b">
              <div className="text-primary text-base font-bold mb-3">{`AGE 2 to < 4 years`}</div>

              <div className="mb-6 min-w-full">
                <table className="min-w-full leading-normal">
                  <thead>
                    <tr className="border-b-2 border-gray-200 bg-gray-100">
                      <th className="pl-4 py-2 text-left text-base font-bold text-gray-700">
                        Vital Sign
                      </th>
                      <th className="px-2 py-2 text-center text-base font-bold text-gray-700">
                        Reference Range
                      </th>
                    </tr>
                  </thead>
                  <tbody className="font-medium">
                    {pediatricVitals3.map((item) => {
                      return (
                        <tr className="border-b">
                          <td className="pl-4 py-2 text-left text-base font-semibold text-gray-700">
                            {item.vital}
                          </td>

                          <td className="px-2 py-2 text-center text-base font-semibold text-gray-700">
                            {item.range}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>

              <div className="mb-4 mt-8">
                <div className="md:grid md:grid-cols-9 gap-4 mb-4">
                  <div className="whitespace-nowrap md:col-span-2 font-semibold text-primary text-lg">
                    Exam Findings :{' '}
                  </div>
                  <div className="md:col-span-7 font-medium text-sm">
                    Lethargy, retractions/difficulty breathing, adventitious breath sounds, unknown
                    murmur, delayed cap refill, any further abnormal or unexpected findings
                  </div>
                </div>

                <div className="md:grid md:grid-cols-9 gap-4 mb-4">
                  <div className="whitespace-nowrap md:col-span-2 font-semibold text-primary text-lg">
                    Newly reported :{' '}
                  </div>
                  <div className="md:col-span-7 font-medium text-sm">
                    fevers, N/V/D, no urine output for 6 hours, concerning change in behavior,
                    unknown newly reported symptom
                  </div>
                </div>
              </div>
            </div>

            {/* 4-8 years */}

            <div className="py-6 border-b">
              <div className="text-primary text-base font-bold mb-2">{`AGE 4 - < 8 years`}</div>

              <div className="mb-6 min-w-full">
                <table className="min-w-full leading-normal">
                  <thead>
                    <tr className="border-b-2 border-gray-200 bg-gray-100">
                      <th className="pl-4 py-2 text-left text-base font-bold text-gray-700">
                        Vital Sign
                      </th>
                      <th className="px-2 py-2 text-center text-base font-bold text-gray-700">
                        Reference Range
                      </th>
                    </tr>
                  </thead>
                  <tbody className="font-medium">
                    {pediatricVitals4.map((item) => {
                      return (
                        <tr className="border-b">
                          <td className="pl-4 py-2 text-left text-base font-semibold text-gray-700">
                            {item.vital}
                          </td>

                          <td className="px-2 py-2 text-center text-base font-semibold text-gray-700">
                            {item.range}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>

              <div className="mb-4 mt-8">
                <div className="md:grid md:grid-cols-9 gap-4 mb-4">
                  <div className="whitespace-nowrap md:col-span-2 font-semibold text-primary text-lg">
                    Exam Findings :{' '}
                  </div>
                  <div className="md:col-span-7 font-medium text-sm">
                    Lethargy, retractions/difficulty breathing, adventitious breath sounds, unknown
                    murmur, delayed cap refill, unexpected findings?
                  </div>
                </div>

                <div className="md:grid md:grid-cols-9 gap-4 mb-4">
                  <div className="whitespace-nowrap md:col-span-2 font-semibold text-primary text-lg">
                    Newly reported :{' '}
                  </div>
                  <div className="md:col-span-7 font-medium text-sm">
                    fevers, N/V/D, no urine output for 6 hours, concerning change in behavior,
                    unknown newly reported symptom
                  </div>
                </div>
              </div>
            </div>

            {/* 8-12 years */}

            <div className="py-6 border-b">
              <div className="text-primary text-base font-bold mb-3">{`AGE 8 - < 12 years`}</div>

              <div className="mb-6 min-w-full">
                <table className="min-w-full leading-normal">
                  <thead>
                    <tr className="border-b-2 border-gray-200 bg-gray-100">
                      <th className="pl-4 py-2 text-left text-base font-bold text-gray-700">
                        Vital Sign
                      </th>
                      <th className="px-2 py-2 text-center text-base font-bold text-gray-700">
                        Reference Range
                      </th>
                    </tr>
                  </thead>
                  <tbody className="font-medium">
                    {pediatricVitals5.map((item) => {
                      return (
                        <tr className="border-b">
                          <td className="pl-4 py-2 text-left text-base font-semibold text-gray-700">
                            {item.vital}
                          </td>

                          <td className="px-2 py-2 text-center text-base font-semibold text-gray-700">
                            {item.range}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>

              <div className="mb-4 mt-8">
                <div className="md:grid md:grid-cols-9 gap-4 mb-4">
                  <div className="whitespace-nowrap md:col-span-2 font-semibold text-primary text-lg">
                    Exam Findings :{' '}
                  </div>
                  <div className="md:col-span-7 font-medium text-sm">
                    Lethargy, retractions/difficulty breathing, adventitious breath sounds, unknown
                    murmur, delayed cap refill, any further abnormal or unexpected findings
                  </div>
                </div>

                <div className="md:grid md:grid-cols-9 gap-4 mb-4">
                  <div className="whitespace-nowrap md:col-span-2 font-semibold text-primary text-lg">
                    Newly reported :{' '}
                  </div>
                  <div className="md:col-span-7 font-medium text-sm">
                    fevers, N/V/D, no urine output for 6 hours, concerning change in behavior,
                    unknown newly reported symptom
                  </div>
                </div>
              </div>
            </div>

            {/* 12-17 years */}

            <div className="py-6 ">
              <div className="text-primary text-base font-bold mb-2">AGE 12-17 years</div>

              <div className="mb-6 min-w-full">
                <table className="min-w-full leading-normal">
                  <thead>
                    <tr className="border-b-2 border-gray-200 bg-gray-100">
                      <th className="pl-4 py-2 text-left text-base font-bold text-gray-700">
                        Vital Sign
                      </th>
                      <th className="px-2 py-2 text-center text-base font-bold text-gray-700">
                        Reference Range
                      </th>
                    </tr>
                  </thead>
                  <tbody className="font-medium">
                    {pediatricVitals6.map((item) => {
                      return (
                        <tr className="border-b">
                          <td className="pl-4 py-2 text-left text-base font-semibold text-gray-700">
                            {item.vital}
                          </td>

                          <td className="px-2 py-2 text-center text-base font-semibold text-gray-700">
                            {item.range}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>

              <div className="mb-4 mt-8">
                <div className="md:grid md:grid-cols-9 gap-4 mb-4">
                  <div className="whitespace-nowrap md:col-span-2 font-semibold text-primary text-lg">
                    Exam Findings :{' '}
                  </div>
                  <div className="md:col-span-7 font-medium text-sm">
                    abnormal mental status, adventitious lung sounds, irregular heart rhythm,
                    difficulty breathing, any further abnormal or unexpected findings
                  </div>
                </div>

                <div className="md:grid md:grid-cols-9 gap-4 mb-4">
                  <div className="whitespace-nowrap md:col-span-2 font-semibold text-primary text-lg">
                    Newly reported :{' '}
                  </div>
                  <div className="md:col-span-7 font-medium text-sm">
                    chest pain, shortness of breath, dizziness, confusion, extreme fatigue, unknown
                    newly reported symptom
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default VitalChart;
