import * as yup from 'yup';

const passwordRegExp = /^[^ ]*$/;
const onlyNumber = /^[\d ]*$/;
// const phoneNumber = /^[1-9][0-9]*$/;
const hasEmail = /([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9._-]+)/gi;
const hasWeb =
  /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{1,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{1,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{1,}|www\.[a-zA-Z0-9]+\.[^\s]{1,}|[a-zA-Z0-9]+\.[^\s]{2,})/gi;

const hasNumber = /(\(\d{3}\))?[\s-]?\d{2}[\s-]?\d{2}[\s-]?\d{2}[\s-]?\d{2}/gim;
export const nameRegExp = /^(?=.{1,60}$)[a-zA-Z æøåÆØÅ]+(?:[-' ][a-zA-Z æøåÆØÅ]+)*$/;
// const phoneNumberRegExp = /^(\+\d{1,3}[- ]?)?\d{10}$/;
// const phoneNumberRegExp = /^([+]\d{2})?\d{10}$/;
export const formattedPhoneRegExp = /^([+]\d{2})?(?:\(\d{3}\)|\d{3})-\d{3}-\d{4}$/;
export const phoneMatch = /^[0-9]|[(]|[)]|[-]+$/;
export const userIdRegExp = /^[a-zA-Z0-9_-]+$/;
export const userIdStartRegExp = /^[a-zA-Z](.*[a-zA-Z0-9_-])?$/;
export const serviceRateFormat = /^(?:0|[1-9]\d*)(?:\.\d+)?$/;

export const validateMaxReachAddress = () =>
  yup.string().trim().min(5, 'validation.address.minfield').max(90, 'validation.address.maxfield');

export const validateRequiredCodePost = () =>
  yup
    .string()
    .trim()
    .matches(onlyNumber, 'validation.firstname.invalid')
    .required('validation.firstname.required')
    .min(2, 'validation.firstname.min.digit');

export const validateRequiredUserId = () =>
  yup
    .string()
    .trim()
    .required('validation.userid.required')
    .matches(userIdRegExp, 'validation.userid.validate')
    .matches(userIdStartRegExp, 'validation.userid.start.validate')
    .max(30, 'max.validate.userid')
    .min(5, 'min.validate.userid');

export const validateUserId = () =>
  yup
    .string()
    .trim()
    .matches(userIdRegExp, 'validation.userid.validate')
    .matches(userIdStartRegExp, 'validation.userid.start.validate')
    .max(30, 'max.validate.userid')
    .min(5, 'min.validate.userid');

export const validateRequiredEmail = () =>
  yup.string().trim().email('validation.email').required('validation.email.required');

export const validateEmail = () => yup.string().trim().email('validation.email');

export const validateRequiredPhone = () =>
  yup
    .string()
    .required('validation.phone.required')
    .nullable()
    .matches(formattedPhoneRegExp, 'validation.phone.invalid');

export const validatePhone = () =>
  yup.string().nullable().matches(formattedPhoneRegExp, 'validation.phone.invalid');

export const validateRequiredPassword = () =>
  yup
    .string()
    .matches(passwordRegExp, 'validation.password.invalid.space')
    .min(8, 'validation.password.min')
    .required('validation.password.required');

export const validateRequirementRequired = () =>
  yup
    .string()
    .matches(hasEmail, 'validation.password.invalid.space')
    .matches(hasNumber, 'validation.password.invalid.space')
    .matches(hasWeb, 'validation.password.invalid.space')
    .min(8, 'validation.password.min')
    .required('validation.password.required');

export const validateRequiredConfirmPassword = () =>
  yup
    .string()
    .matches(passwordRegExp, 'validation.password.invalid.space')
    .min(8, 'validation.password.min')
    .required('validation.password.required');

export const validateRequiredFirstName = () =>
  yup
    .string()
    .trim()
    .matches(nameRegExp, 'validation.firstname.invalid')
    .required('validation.firstname.required')
    .min(2, 'validation.firstname.min.digit')
    .max(25, 'name.max25');

export const validateRequiredLastName = () =>
  yup
    .string()
    .trim()
    .matches(nameRegExp, 'validation.lastname.invalid')
    .required('validation.lastname.required')
    .min(3, 'validation.lastname.min.digit')
    .max(25, 'name.max25');
export const validateRequiredDOB = () =>
  yup.string().required('validation.dob.required').nullable();

export const validateRequired = () => yup.string().required('validation.required').nullable();

export const validateRequiredCity = () =>
  yup
    .string()
    .matches(nameRegExp, 'validation.city.invalid')
    .required('validation.city.required')
    .nullable();

export const validateRequiredPostalCode = () =>
  yup
    .string()
    .required('validation.postalcode.required')
    .max(5, 'validation.postalcode.max.digit')
    .min(3, 'validation.postalcode.min.digit');

export const validateRequiredAddress = () =>
  yup.string().nullable().required('validation.address.required');

export const validateServiceRate = () => yup.string().matches(serviceRateFormat, 'validation.service.rate').label('Service Rate').required('required');