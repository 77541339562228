import { onMessageListener } from '../../firebase';
import ReactNotificationComponent from 'molecules/Notifications/ReactNotification';
import React, { useState } from 'react';
import useAuth from 'contexts/AuthenticationContext';
import useChatInboxList from 'modules/AdminPanel/AdminDashboard/AdminComponent/AdminInbox/AdminInboxChatContext';

function PushNotification() {
  const [show, setShow] = useState(false);
  const [notification, setNotification] = useState({ title: '', body: '' });
  const {
    actions: { refreshUser },
  } = useAuth();

  const {
    // state: { chatInboxList },
    actions: { getChatInboxList },
  } = useChatInboxList();

  onMessageListener()
    .then((payload) => {
      setShow(true);
      refreshUser();
      getChatInboxList();
      setNotification({
        title: payload.notification.title,
        body: payload.notification.body,
      });
    })
    .catch((err) => console.log('failed: ', err));

  return (
    <>
      {show ? (
        <ReactNotificationComponent title={notification.title} body={notification.body} />
      ) : (
        <></>
      )}
    </>
  );
}

export default PushNotification;
