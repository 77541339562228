import React, { FC } from 'react';

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useFormikContext, useField } from 'formik';
import { CalendarIcon } from '@heroicons/react/outline';

interface Iprops {
  error?: string;
  touched?: boolean | string;
  disabled?: boolean;
  validation?: boolean;
  value: string;
  name: string;
  minDate?: Date;
  maxDate?: Date;
  //   showYearDropdown?: boolean;
  //   scrollableYearDropdown?: boolean;
  //   yearDropdownItemNumber?: number;
}

const InputDateField: FC<Iprops> = ({ ...props }: Iprops) => {
  const { touched, error, disabled, validation } = {
    ...props,
  };

  // const [field] = useField(name);
  // const formik = useFormikContext();
  // const { setFieldTouched, validateField, handleBlur } = useFormikContext();
  // const { setFieldValue } = formik;

  // const handleChange = useCallback(
  //   (date: any) => {
  //     setFieldValue(name, date);
  //   },
  //   [setFieldValue, name],
  // );
  const { setFieldValue, setFieldTouched } = useFormikContext();
  const [field] = useField(props);

  return (
    <>
      <DatePicker
        // calendarClassName={`block w-full`}
        // customInput={<input />}
        className={`appearance-none block w-full px-3 py-2 font-medium border rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm  ${
          (error && touched) || (validation && error) ? ' border-red-300 pr-10' : ' border-gray-300'
        } ${disabled ? 'bg-gray-100' : ''}`}
        {...props}
        {...field}
        disabled={disabled}
        selected={(field.value && new Date(field.value)) || null}
        onChange={(val) => {
          setFieldValue(field.name, val);
        }}
        dateFormat={'MM/dd/yyyy'}
        showYearDropdown={true}
        dropdownMode="select"
        scrollableYearDropdown={true}
        yearDropdownItemNumber={30}
        // onYearChange={(val) => {
        //   setFieldValue(field.name, val);
        // }}
        onMonthChange={(val) => {
          setFieldValue(field.name, val);
        }}
        // onCalendarClose={() => {
        //   // Force-validate onCalendarClose to avoid any potential DatePicker Blur issues
        //   // Didn't help
        //   validateField(field.name);
        // }}
        // onBlur={(e) => {
        //   // Call Formik's handleBlur
        //   // Didn't help
        //   handleBlur(e);
        // }}
        onChangeRaw={(e) => {
          setFieldTouched(field.name, true, true);
        }}
        autoComplete="off"
      />
      <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
        <CalendarIcon className="h-5 w-6 text-gray-700" />
      </div>
    </>
  );
};

export default InputDateField;

// InputDateField.defaultProps = {
//   showYearDropdown: true,
//   scrollableYearDropdown: true,
//   yearDropdownItemNumber: 20,
// };
