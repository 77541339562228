import { useCallback, useReducer } from 'react';
import { toast } from 'react-toastify';
import { createContainer, createReducer, createAsyncActions } from 'utils/context';
import axiosService from '../utils/api/axios';

const initialState = {
  dxCodeList: { data: [], meta: {} },
  dxCodeLoading: false,
};

const actions = {
  updateDxCodeList: createAsyncActions('UPDATE_SERVICES_LIST'),
};

const dxCodeReducer = createReducer({
  [`${actions.updateDxCodeList.loading}`]: (state) => ({
    ...state,
    dxCodeLoading: true,
  }),
  [`${actions.updateDxCodeList.success}`]: (state, { payload }) => ({
    ...state,
    dxCodeList: payload,
    dxCodeLoading: false,
  }),
  [`${actions.updateDxCodeList.failure}`]: (state) => ({
    ...state,
    dxCodeLoading: false,
  }),
});

export const { useContext: useDxCode, Provider: DxCodeProvider } = createContainer(() => {
  const [state, dispatch] = useReducer(dxCodeReducer, initialState);

  const AddDxCode = useCallback(async (formData: any) => {
    try {
      const { data } = await axiosService?.post(`/list/add-DxCode`, formData);
      toast.success(data.message);

      return data;
    } catch (e) {
      toast.error(e?.response?.data?.message);
    }
    return undefined;
  }, []);

  const UpdateDxCode = useCallback(
    async (formData: any, callback?: () => void) => {
      try {
        const { data } = await axiosService?.post(`/list/update-DxCode`, formData);
        toast.success(data?.message);

        const temp_state = state?.dxCodeList?.data?.map((val: any) =>
          val?.id === formData?.id ? { ...val, ...formData } : val,
        );

        dispatch(
          actions.updateDxCodeList.success({ data: temp_state, meta: state?.dxCodeList?.meta }),
        );

        if (data && typeof callback === 'function') {
          callback();
        }

        return data;
      } catch (e) {
        toast.error(e?.response?.data?.message);
      }
      return undefined;
    },
    [state],
  );

  const getdxandcategory = useCallback(async (PageNum: string | number, take?: string | number, query?: string) => {
    try {
      dispatch(actions.updateDxCodeList.loading());

      const { data } = await axiosService?.get(
        `/list/list-DxCodes?page=${Number(PageNum)}&take=${take || 10}&query=${query}`,
      );
      dispatch(actions.updateDxCodeList.success(data?.data?.DxCodeList));

      return data;
    } catch (e) {
      toast.error(e?.response?.data?.message);
      dispatch(actions.updateDxCodeList.failure());
    }
    return undefined;
  }, []);

  const clearData = useCallback(
    () => dispatch(actions.updateDxCodeList.success({ data: [], meta: {} })),
    [],
  );

  const deleteDxCode = useCallback(async (id: string, callback) => {
    try {
      const { data } = await axiosService?.delete(`/list/delete-dxcode/${id}`, {});
      if (data && typeof callback === 'function') {
        toast.error('DX Code Deleted succesfully');
        callback();
      }
      return data;
    } catch (e) {
      toast.error(e?.response?.data?.message);
    }
    return undefined;
  }, []);

  return {
    state,
    actions: {
      getdxandcategory,
      AddDxCode,
      UpdateDxCode,
      clearData,
      deleteDxCode,
    },
  };
});

export default useDxCode;
