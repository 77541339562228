import axios from 'axios';
import { useReducer, useCallback } from 'react';
import { toast } from 'react-toastify';
import { createContainer, createReducer, createAsyncActions } from 'utils/context';
import axiosService from '../utils/api/axios';

const initialState = {
  profileData: {},
  dataLoading: false,
  patientBillingDetails: null,
  patientBillingLoading: false,
  consentList: [],
  consentListLoading: false,
  consentDetails: null,
  consentDetailsLoading: false,
  invoiceList: {},
  invoiceListLoading: false,
  invoiceDetails: {},
  invoiceDetailsLoading: false,
  documentsList: [],
  documentsListLoading: false,
  documentActionLoading: false,
  documentUrl: '',
  uploadDocLoading: false,
  insuranceDetails: [],
  insuranceLoading: false,
};

const actions = {
  getProfileDetail: createAsyncActions('GET_PROFILE_DETAIL'),
  getConsentListData: createAsyncActions('GET_CONSENT_LIST'),
  getInvoiceListData: createAsyncActions('GET_INVOICE_LIST'),
  getInvoiceDetailsData: createAsyncActions('GET_INVOICE_DETAILS'),
  getConsentDetailsData: createAsyncActions('GET_CONSENT_DETAILS'),
  getPatientBillingDetails: createAsyncActions('GET_PATIENT_BILLING'),
  getDocumentsListData: createAsyncActions('GET_DOCUMENTS_LIST'),
  documentAction: createAsyncActions('DOCUMENT_ACTION'),
  uploadDoc: createAsyncActions('UPLOAD_DOC'),
};

const profileReducer = createReducer({
  [`${actions.getProfileDetail.loading}`]: (state) => ({
    ...state,
    dataLoading: true,
  }),
  [`${actions.getProfileDetail.success}`]: (state, { payload }) => ({
    ...state,
    profileData: payload,
    dataLoading: false,
  }),
  [`${actions.getProfileDetail.failure}`]: (state) => ({
    profileData: undefined,
    dataLoading: false,
  }),

  [`${actions.getPatientBillingDetails.loading}`]: (state) => ({
    ...state,
    patientBillingLoading: true,
  }),
  [`${actions.getPatientBillingDetails.success}`]: (state, { payload }) => ({
    ...state,
    patientBillingDetails: payload,
    patientBillingLoading: false,
  }),
  [`${actions.getPatientBillingDetails.failure}`]: (state) => ({
    patientBillingDetails: undefined,
    patientBillingLoading: false,
  }),

  [`${actions.getConsentListData.loading}`]: (state) => ({
    ...state,
    consentListLoading: true,
  }),
  [`${actions.getConsentListData.success}`]: (state, { payload }) => ({
    ...state,
    consentList: payload,
    consentListLoading: false,
  }),
  [`${actions.getConsentListData.failure}`]: (state) => ({
    ...state,
    consentListLoading: false,
  }),

  [`${actions.getConsentDetailsData.loading}`]: (state) => ({
    ...state,
    consentDetailsLoading: true,
  }),
  [`${actions.getConsentDetailsData.success}`]: (state, { payload }) => ({
    ...state,
    consentDetails: payload,
    consentDetailsLoading: false,
  }),
  [`${actions.getConsentDetailsData.failure}`]: (state) => ({
    ...state,
    consentDetailsLoading: false,
  }),

  [`${actions.getInvoiceListData.loading}`]: (state) => ({
    ...state,
    invoiceListLoading: true,
  }),
  [`${actions.getInvoiceListData.success}`]: (state, { payload }) => ({
    ...state,
    invoiceList: payload,
    invoiceListLoading: false,
  }),
  [`${actions.getInvoiceListData.failure}`]: (state) => ({
    ...state,
    invoiceListLoading: false,
  }),

  [`${actions.getInvoiceDetailsData.loading}`]: (state) => ({
    ...state,
    invoiceDetailsLoading: true,
  }),
  [`${actions.getInvoiceDetailsData.success}`]: (state, { payload }) => ({
    ...state,
    invoiceDetails: payload,
    invoiceDetailsLoading: false,
  }),
  [`${actions.getInvoiceDetailsData.failure}`]: (state) => ({
    ...state,
    invoiceDetailsLoading: false,
  }),
  [`${actions.getDocumentsListData.loading}`]: (state) => ({
    ...state,
    documentsListLoading: true,
  }),
  [`${actions.getDocumentsListData.success}`]: (state, { payload }) => ({
    ...state,
    documentsList: payload,
    documentsListLoading: false,
  }),
  [`${actions.getDocumentsListData.failure}`]: (state) => ({
    ...state,
    documentsListLoading: false,
  }),
  [`${actions.documentAction.loading}`]: (state) => ({
    ...state,
    documentActionLoading: true,
  }),
  [`${actions.documentAction.success}`]: (state, { payload }) => ({
    ...state,
    documentUrl: payload,
    documentActionLoading: false,
  }),
  [`${actions.documentAction.failure}`]: (state) => ({
    ...state,
    documentActionLoading: false,
  }),
  [`${actions.uploadDoc.loading}`]: (state) => ({
    ...state,
    uploadDocLoading: true,
  }),
  [`${actions.uploadDoc.success}`]: (state) => ({
    ...state,
    uploadDocLoading: false,
  }),
  [`${actions.uploadDoc.failure}`]: (state) => ({
    ...state,
    uploadDocLoading: false,
  }),
});

export const { useContext: useProfileDetails, Provider: ProfileProvider } = createContainer(() => {
  const [state, dispatch] = useReducer(profileReducer, initialState);

  const getProfileDetails = useCallback(async (userID: string) => {
    try {
      dispatch(actions.getProfileDetail.loading());
      const { data } = await axiosService?.get(`/users/detail/${userID}`);
      dispatch(actions.getProfileDetail.success(data?.data));

      return data;
    } catch (e) {
      // @ts-ignore
      toast.error(e?.response?.data?.message);
      dispatch(actions.getProfileDetail.failure());
    }
    return undefined;
  }, []);

  const getBillingDetails = useCallback(async (id: string) => {
    try {
      dispatch(actions.getPatientBillingDetails.loading());
      const { data } = await axiosService?.get(`/payments/${id}/customer-details-from-stripe`);
      dispatch(actions.getPatientBillingDetails.success(data?.data));
      return data;
    } catch (e) {
      // @ts-ignore
      toast.error(e?.response?.data?.message);
      dispatch(actions.getPatientBillingDetails.failure());
    }
    return undefined;
  }, []);

  const addNewStipeBillingAccount = useCallback(async (bodyData: any) => {
    try {
      const { data } = await axiosService?.post(`users/update-billing-account`, bodyData);
      toast.success(data?.message);
      return data;
    } catch (e) {
      toast.error(e?.response?.data?.message);
    }
    return undefined;
  }, []);

  const getConsentList = useCallback(async (bodyData: any) => {
    try {
      dispatch(actions.getConsentListData.loading());

      const { data } = await axiosService?.get(
        `users/consents?userId=${bodyData.userId}&consentType=${bodyData.consentType}`,
      );
      dispatch(actions.getConsentListData.success(data?.data?.status));
    } catch (e) {
      toast.error(e?.response?.data?.message);
      dispatch(actions.getConsentListData.failure());
    }
    return undefined;
  }, []);

  const getConsentDetails = useCallback(async (id: string) => {
    try {
      dispatch(actions.getConsentDetailsData.loading());

      const { data } = await axios.get(`users/consent-detail/${id}`);
      dispatch(actions.getConsentDetailsData.success(data?.data?.status));
    } catch (e) {
      toast.error(e?.response?.data?.message);
      dispatch(actions.getConsentDetailsData.failure());
    }
    return undefined;
  }, []);
  const clearConsentDetails = () => {
    dispatch(actions.getConsentDetailsData.success(null));
  };

  const userConsentAction = async (formData: any) => {
    try {
      const { data } = await axiosService?.post(`users/consent-action`, {
        ...formData,
      });

      toast.success(data?.message);
      return data;
    } catch (e) {
      // @ts-ignore
      toast.error(e?.response?.data?.message);
    }
    return undefined;
  };
  const sendConsetandRequest = useCallback(async (bodyData: any) => {
    try {
      const { data } = await axiosService?.post(`users/send-consent-request`, bodyData);
      toast.success(data?.message);
      return data;
    } catch (e) {
      toast.error(e?.response?.data?.message);
    }
    return undefined;
  }, []);

  const getPatientWithUserId = useCallback(async (userID: string) => {
    try {
      const { data } = await axiosService?.get(`/users/patient-detail/${userID}`);
      return data;
    } catch (e) {
      // @ts-ignore
      toast.error(e?.response?.data?.message);
    }
    return undefined;
  }, []);

  const clearData = () => {
    dispatch(actions.getProfileDetail.failure());
  };

  const ChangeUserStatus = async (formData: any) => {
    try {
      const { data } = await axiosService?.post(`/users/change-status`, {
        ...formData,
      });

      toast.success(data?.message);
      return data;
    } catch (e) {
      // @ts-ignore
      toast.error(e?.response?.data?.message);
    }
    return undefined;
  };

  const resendSetPassword = useCallback(async (formData: any) => {
    try {
      // const params = new URLSearchParams({
      //   ...formData,
      // }).toString();
      const { data } = await axiosService?.post(
        `/users/send-set-password-email?userId=${formData.userId}`,
        null,
      );

      toast.success(data?.message);
      return data;
    } catch (e) {
      // @ts-ignore
      toast.error(e?.response?.data?.message);
    }
    return undefined;
  }, []);
  const markAsDeceasedPatient = useCallback(async (formData: any) => {
    try {
      // const params = new URLSearchParams({
      //   ...formData,
      // }).toString();
      const { data } = await axiosService?.post(`/users/mark-as-deceased/${formData.userId}`, null);

      toast.success(data?.message);
      return data;
    } catch (e) {
      // @ts-ignore
      toast.error(e?.response?.data?.message);
    }
    return undefined;
  }, []);
  const setnotifications = async (formData: any) => {
    try {
      const { data } = await axiosService?.post(`users/set-notifications`, {
        ...formData,
      });

      toast.success(data?.message);

      return data;
    } catch (e) {
      // @ts-ignore
      toast.error(e?.response?.data?.message);
    }
    return undefined;
  };

  const getInvoiceList = useCallback(
    async (
      patientId: string,
      pageNum = 1,
      itemPerPage = 10,
      searchQuery: String,
      startDate?: string,
      endDate?: string,
      category?: any,
    ) => {
      try {
        dispatch(actions.getInvoiceListData.loading());
        // const { data } = await axiosService?.get(
        //   `appointment/invoices?patientId=${patientId}&take=${itemPerPage}&page=${pageNum}&searchQuery=${searchQuery}`,
        // );
        let api = `appointment/invoices?patientId=${patientId}&take=${itemPerPage}&page=${pageNum}&searchQuery=${searchQuery}`;
        if (startDate) api = api + `&startDate=${startDate}`;
        if (endDate) api = api + `&endDate=${endDate}`;
        if (category) api = api + `&category=${category}`;
        const { data } = await axiosService?.get(api);
        dispatch(actions.getInvoiceListData.success(data?.data?.invoices));
      } catch (e) {
        toast.error(e?.response?.data?.message);
        dispatch(actions.getInvoiceListData.failure());
      }
      return undefined;
    },
    [],
  );
  const getInvoiceDetails = useCallback(async (invoiceId: string) => {
    try {
      dispatch(actions.getInvoiceDetailsData.loading());
      const { data } = await axiosService?.get(`appointment/invoice/${invoiceId}`);
      dispatch(actions.getInvoiceDetailsData.success(data?.data));

      return data;
    } catch (e) {
      // @ts-ignore
      toast.error(e?.response?.data?.message);
      dispatch(actions.getInvoiceDetailsData.failure());
    }
    return undefined;
  }, []);
  const addInvoiceData = useCallback(async (bodyData: any) => {
    try {
      const { data } = await axiosService?.post(`appointment/add-invoice`, bodyData);
      toast.success(data?.message);
      return data;
    } catch (e) {
      toast.error(e?.response?.data?.message);
    }
    return undefined;
  }, []);

  const updateInvoiceData = useCallback(async (bodyData: any) => {
    try {
      const { data } = await axiosService?.post(`appointment/update-invoice`, bodyData);
      toast.success(data?.message);
      return data;
    } catch (e) {
      toast.error(e?.response?.data?.message);
    }
    return undefined;
  }, []);

  const removeInvoiceData = useCallback(async (id: string) => {
    try {
      const { data } = await axiosService?.delete(`/appointment/invoice/${id}`, {});
      toast.success(data?.message);
      return data;
    } catch (e) {
      toast.error(e?.response?.data?.message);
    }
    return undefined;
  }, []);

  const getDocumentsListData = useCallback(
    async (
      pageNum = '1',
      take?: string | number,
      patientId?: string,
      startDate?: string,
      endDate?: string,
      query?: string,
      category?: string,
      sortOrder?: string,
      sortVariable?: string,
    ) => {
      try {
        dispatch(actions.getDocumentsListData.loading());
        let api = `/appointment/list-documents?patientId=${patientId}&page=${pageNum}&take=${
          take || 10
        }
        &startDate=${startDate || ''}&endDate=${endDate || ''}&sortVariable=${
          sortVariable || 'createdAt'
        }&sortOrder=${sortOrder || 'DESC'}`;
        if (query) {
          api = api + `&searchQuery=${query}`;
        }
        if (category) {
          api = api + `&category=${category}`;
        }
        const { data } = await axiosService?.get(api);
        dispatch(actions.getDocumentsListData.success(data.data.listing));
        return data;
      } catch (e: any) {
        toast.error(e?.response?.data?.message);
        dispatch(actions.getDocumentsListData.failure());
      }
      return undefined;
    },
    [],
  );

  const documentAction = useCallback(async (id: string, operation: string) => {
    try {
      dispatch(actions.documentAction.loading());
      const { data } = await axiosService?.get(
        `/appointment/get-document-url/${id}?operation=${operation}`,
      );
      dispatch(actions.documentAction.success(data.data.documentUrl));
      if (operation === 'VIEW') {
        return data.data.documentUrl;
      }
      toast.success('Downloaded Successfully');
      return data.data.documentUrl;
    } catch (e: any) {
      toast.error(e?.response?.data?.message);
      dispatch(actions.documentAction.failure());
    }
    return undefined;
  }, []);

  const uploadDocument = useCallback(async (bodyData: any) => {
    try {
      dispatch(actions.uploadDoc.loading());
      const { data } = await axiosService?.post(`/appointment/upload-document`, bodyData);
      dispatch(actions.uploadDoc.success(data));
      toast.success(data?.message);
      return data;
    } catch (e) {
      dispatch(actions.uploadDoc.failure());
      toast.error(e?.response?.data?.message);
    }
    return undefined;
  }, []);

  const addInsuranceDetails = useCallback(async (bodyData: any) => {
    try {
      const { data } = await axiosService?.post(`/users/add-insurance`, bodyData);
      toast.success(data?.message);
      return data;
    } catch (e) {
      toast.error(e?.response?.data?.message);
    }
    return undefined;
  }, []);

  const editInsuranceDetails = useCallback(async (bodyData: any) => {
    try {
      const { data } = await axiosService?.post(`/users/edit-insurance`, bodyData);
      toast.success(data?.message);
      return data;
    } catch (e) {
      toast.error(e?.response?.data?.message);
    }
    return undefined;
  }, []);

  const addPharmacy = useCallback(async (formData: any) => {
    try {
      const { data } = await axiosService?.post(`users/add-or-update-pharmacy`, formData);
      toast.success(data?.message);
      return data;
    } catch (e) {
      toast.error(e?.response?.data?.message);
    }
    return undefined;
  }, []);

  const deleteInsuranceDetails = useCallback(async (id: string) => {
    try {
      const { data } = await axiosService?.delete(`/users/insurance/${id}`, {});
      toast.success(data?.message);
      return data;
    } catch (e) {
      toast.error(e?.response?.data?.message);
    }
    return undefined;
  }, []);

  return {
    state,
    actions: {
      getProfileDetails,
      getConsentList,
      getConsentDetails,
      userConsentAction,
      sendConsetandRequest,
      getPatientWithUserId,
      resendSetPassword,
      ChangeUserStatus,
      clearData,
      setnotifications,
      markAsDeceasedPatient,
      clearConsentDetails,
      getBillingDetails,
      addNewStipeBillingAccount,
      getInvoiceList,
      removeInvoiceData,
      getInvoiceDetails,
      addInvoiceData,
      updateInvoiceData,
      getDocumentsListData,
      documentAction,
      uploadDocument,
      addInsuranceDetails,
      editInsuranceDetails,
      addPharmacy,
      deleteInsuranceDetails,
    },
  };
});

export default useProfileDetails;
