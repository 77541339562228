import { useCallback, useReducer } from 'react';
import { toast } from 'react-toastify';
import { createContainer, createReducer, createAsyncActions } from 'utils/context';
import axiosService from '../utils/api/axios';

const initialState = {
  paymentsList: { data: [], meta: {} },
  paymentsListLoading: false,
};

const actions = {
  paymentsList: createAsyncActions('GET_FAILED_PAYMENTS'),
};

const paymentsReducer = createReducer({
  // ----- For Failed Payments -----
  [`${actions.paymentsList.loading}`]: (state) => ({
    ...state,
    paymentsListLoading: true,
  }),
  [`${actions.paymentsList.success}`]: (state, { payload }) => ({
    ...state,
    paymentsList: payload,
    paymentsListLoading: false,
  }),
  [`${actions.paymentsList.failure}`]: (state) => ({
    ...state,
    paymentsListLoading: false,
  }),
});

export const { useContext: usePayments, Provider: PaymentsProvider } = createContainer(() => {
  const [state, dispatch] = useReducer(paymentsReducer, initialState);

  const getPaymentsList = async (
    paymentType?: string,
    userId?: string,
    paymentStatus?: string,
    pageNum = '1',
    startDate?: string,
    endDate?: string,
    take?: string | number,
    sortVariable?: string, // Add sortVariable parameter
    sortOrder?: 'asc' | 'desc',
  ) => {
    try {
      dispatch(actions.paymentsList.loading());
      let api = `/list/payments?page=${pageNum}&take=${take || 10}${
        paymentType ? `&paymentType=${paymentType}` : ''
      }${userId ? `&userId=${userId}` : ''}${
        paymentStatus ? `&paymentStatus=${paymentStatus}` : ''
      }${startDate ? `&startDate=${startDate}` : ''}${endDate ? `&endDate=${endDate}` : ''}${
        sortVariable && sortOrder ? `&sortVariable=${sortVariable}&sortOrder=${sortOrder}` : ''
      }`;
      const { data } = await axiosService?.get(api);

      dispatch(actions.paymentsList.success(data?.data?.serviceList));
      return data;
    } catch (e) {
      toast.error(e?.response?.data?.message);

      dispatch(actions.paymentsList.failure());
    }
    return undefined;
  };

  const markPaymentAsPaid = useCallback(async (formData) => {
    try {
      const response = await axiosService?.post(`/payments/mark-as-paid`, formData);
      toast.success(response?.data?.message);
      return response;
    } catch (e: any) {
      toast.error(e?.response?.data?.message);
    }
    return undefined;
  }, []);
  const SyncTransactionWithStripe = useCallback(async (formData) => {
    try {
      const response = await axiosService?.post(`/list/sync-transaction-with-stripe/${formData}`);
      toast.success(response?.data?.message);
      getPaymentsList();
      return response;
    } catch (e: any) {
      toast.error(e?.response?.data?.message);
    }
    return undefined;
  }, []);

  const sendAlternatePaymentLink = useCallback(async (formData) => {
    try {
      const response = await axiosService?.post(`/payments/send-payment-link`, formData);
      toast.success(response?.data?.message);
      return response;
    } catch (e: any) {
      toast.error(e?.response?.data?.message);
    }
    return undefined;
  }, []);

  const sendPaymentRequestText = useCallback(async (formData) => {
    try {
      const response = await axiosService?.post(`/list/send-text`, formData);
      toast.success(response?.data?.message);
      return response;
    } catch (e: any) {
      toast.error(e?.response?.data?.message);
    }
    return undefined;
  }, []);
  const sendPaymentRequestMail = useCallback(async (formData) => {
    try {
      const response = await axiosService?.post(`/list/send-mail`, formData);
      toast.success(response?.data?.message);
      return response;
    } catch (e: any) {
      toast.error(e?.response?.data?.message);
    }
    return undefined;
  }, []);

  return {
    state,
    actions: {
      getPaymentsList,
      markPaymentAsPaid,
      sendAlternatePaymentLink,
      sendPaymentRequestText,
      sendPaymentRequestMail,
      SyncTransactionWithStripe,
    },
  };
});

export default usePayments;
