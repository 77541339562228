import { useReducer, useCallback, useEffect } from 'react';
import { UserData } from 'typings/user';

import { AUTH_KEYS, RoleType, ROLES, API_URL } from 'utils/settings/constants';
import { hydrate, persist } from 'utils/persist';
import { createContainer, createReducer, createAction } from 'utils/context';
import { toast } from 'react-toastify';
import axiosService from '../utils/api/axios';
import { getAccessToken } from 'utils/api/getTokens';

export type AuthState = {
  token?: string;
  user?: UserData;
  isLoggedIn?: boolean;
  authenticating?: boolean;
  loading?: boolean;
  SignupFlow_LoggedIn?: boolean;
  fcmtoken?: string;
};
const initialState: AuthState = {
  isLoggedIn: false,
  authenticating: true,
  loading: true,
  SignupFlow_LoggedIn: false,
  fcmtoken: '',
};
const actions = {
  setAuthenticating: createAction('SET_AUTHENTICATING'),
  setSignupLoggedIn: createAction('SET_LOGGEDIN'),
  updateUserData: createAction('UPDATE_USER_DATA'),
  reupdateUserInfo: createAction('RESET_USER_DATA'),
  setAuthState: createAction('SET_AUTH_STATE'),
  setLoading: createAction('SET_LOADING'),
  updateUserProfile: createAction('UPDATE_USER_PROFILE'),
  setFCMToken: createAction('SET_FCM_TOKEN'),
};

const authReducer = createReducer<AuthState>({
  [actions.setSignupLoggedIn.toString()]: (state, { payload }) => ({
    ...state,
    SignupFlow_LoggedIn: payload,
  }),
  [actions.updateUserData.toString()]: (state, { payload }) => ({
    ...state,
    user: { ...state.user, ...payload },
  }),
  [actions.reupdateUserInfo.toString()]: (state) => ({
    ...state,
    user: undefined,
    isLoggedIn: false,
    authenticating: false,
  }),
  [actions.setAuthenticating.toString()]: (state, { payload }) => ({
    ...state,
    authenticating: payload,
  }),
  [actions.setAuthState.toString()]: (state, { payload }) => ({
    ...state,
    ...payload,
  }),
  [actions.setLoading.toString()]: (state, { payload }) => ({
    ...state,
    loading: payload,
  }),
  [actions.setFCMToken.toString()]: (state, { payload }) => ({
    ...state,
    fcmtoken: payload,
  }),
});

export const { useContext: useAuth, Provider: AuthProvider } = createContainer(() => {
  const [state, dispatch] = useReducer(authReducer, initialState);

  const logout = useCallback(() => {
    localStorage.clear();
    sessionStorage.clear();

    dispatch(actions.reupdateUserInfo());
  }, []);
  const setUserInfo = useCallback(
    (userData?: UserData) => {
      const token = getAccessToken();
      if (userData && token) {
        persist(AUTH_KEYS.USER_DATA, userData, 'localStorage');
        const userInfo: AuthState = {
          token,
          isLoggedIn: true,
          authenticating: false,
          user: {
            ...userData,
          },
        };
        dispatch(actions.setAuthState(userInfo));
      } else {
        logout();
      }
    },
    [logout],
  );

  useEffect(() => {
    const user = hydrate(AUTH_KEYS.USER_DATA, 'localStorage') as UserData | undefined;
    setUserInfo(user);
  }, [setUserInfo]);

  const postalCode = useCallback(async (zipcode: string) => {
    try {
      const { data } = await axiosService.get(`/auth/zipcode?zipcode=${zipcode}`);

      return data;
    } catch (e) {
      return { error: e?.response?.data?.message };
    }
  }, []);

  const refreshUser = useCallback(async () => {
    try {
      dispatch(actions.setLoading(true));
      const { data } = await axiosService.get(API_URL?.USER_INFO);
      setUserInfo(data?.data);
    } catch (e) {
      console.error(e);
      logout();
    } finally {
      dispatch(actions.setLoading(false));
    }
  }, [setUserInfo, logout]);

  const getAdminLoginVerificationOtp = useCallback(
    async (
      values: { email: string; password: string; remember_me: boolean },
      role?: RoleType,
      SignupLoggedIn?: boolean,
    ) => {
      let userDataForOtp;

      // @ts-ignore
      delete values.remember_me;
      let apiUrl = '';
      if (role === ROLES?.ADMIN) {
        apiUrl = API_URL?.ADMIN_LOGIN;
      } else if (role === ROLES?.PATIENT) {
        apiUrl = API_URL?.PATIENT_LOGIN;
      }
      try {
        const { data } = await axiosService.post(apiUrl, {
          ...values,
          fcmtoken: state?.fcmtoken,
        });
        userDataForOtp = data;
      } catch (e) {
        toast.error(e?.response?.data?.message || 'Either email or password is wrong');
      }
      return userDataForOtp;
    },
    [state?.fcmtoken],
  );
  const authenticateAdminLogin = useCallback(
    async (values: { userId: string; OTP: string; remember_me: boolean }) => {
      const { remember_me = false } = values;
      // @ts-ignore
      delete values.remember_me;

      try {
        const { data } = await axiosService.post('auth/authenticate-admin', {
          ...values,
        });
        if (data) {
          if (remember_me === true) {
            localStorage.setItem(AUTH_KEYS?.AUTH_TOKEN_COOKIE, data.data.token.accessToken);
          } else {
            sessionStorage.setItem(AUTH_KEYS?.AUTH_TOKEN_COOKIE, data.data.token.accessToken);
          }
          setUserInfo(data.data.user);
        }
        return data;
      } catch (e) {
        toast.error(e?.response?.data?.message || 'Either email or password is wrong');
      }
      return undefined;
    },
    [setUserInfo],
  );
  const loginUser = useCallback(
    async (
      values: { email: string; password: string; remember_me: boolean },
      role?: RoleType,
      SignupLoggedIn?: boolean,
    ) => {
      const { remember_me = false } = values;
      // @ts-ignore
      delete values.remember_me;
      let apiUrl = '';
      if (role === ROLES?.ADMIN) {
        apiUrl = API_URL?.ADMIN_LOGIN;
      } else if (role === ROLES?.PATIENT) {
        apiUrl = API_URL?.PATIENT_LOGIN;
      }
      try {
        const { data } = await axiosService.post(apiUrl, {
          ...values,
          fcmtoken: state?.fcmtoken,
        });
        if (data) {
          if (remember_me === true) {
            localStorage.setItem(AUTH_KEYS?.AUTH_TOKEN_COOKIE, data.data.token.accessToken);
          } else {
            sessionStorage.setItem(AUTH_KEYS?.AUTH_TOKEN_COOKIE, data.data.token.accessToken);
          }

          SignupLoggedIn && dispatch(actions.setSignupLoggedIn(true));

          setUserInfo(data.data.user);
        }
      } catch (e) {
        toast.error(e?.response?.data?.message || 'Either email or password is wrong');
      }
    },
    [setUserInfo, state?.fcmtoken],
  );

  const updateUserProfile = useCallback(
    async (formData) => {
      try {
        const { data } = await axiosService?.put(`/users/update`, {
          ...formData,
        });

        toast.success(data?.message);
        data && refreshUser();

        return data;
      } catch (e) {
        toast.error(e?.response?.data?.message);
      }
      return undefined;
    },
    [refreshUser],
  );

  const AddFamilyMember = useCallback(
    async (formData, callback) => {
      try {
        const { data } = await axiosService?.post(`/patient/add-family`, {
          ...formData,
        });

        toast.success(data.message);
        if (state?.user?.role === ROLES?.ADMIN) {
          if (typeof callback === 'function') {
            callback();
          }
        } else {
          refreshUser();
        }

        return data;
      } catch (e) {
        toast.error(e?.response?.data?.message);
      }
      return undefined;
    },
    [refreshUser, state?.user?.role],
  );
  const AddExistingMember = useCallback(
    async (formData, callback) => {
      try {
        const { data } = await axiosService?.post(`/patient/add-existing-patient-in-household`, {
          ...formData,
        });

        toast.success(data.message);
        if (state?.user?.role === ROLES?.ADMIN) {
          if (typeof callback === 'function') {
            callback();
          }
        } else {
          refreshUser();
        }

        return data;
      } catch (e) {
        toast.error(e?.response?.data?.message);
      }
      return undefined;
    },
    [refreshUser, state?.user?.role],
  );

  const EditFamilyMember = useCallback(
    async (formData, callback) => {
      try {
        const { data } = await axiosService?.put(`/users/update`, {
          ...formData,
        });

        toast.success(data.message);
        if (state?.user?.role === ROLES?.ADMIN) {
          if (typeof callback === 'function') {
            callback();
          }
        } else {
          refreshUser();
        }
        return data;
      } catch (e) {
        toast.error(e?.response?.data?.message);
      }
      return undefined;
    },
    [refreshUser, state?.user?.role],
  );

  const DeleteFamilyMember = useCallback(
    async (id: string, callback) => {
      try {
        const { data } = await axiosService?.delete(`/patient/delete/${id}`, {});

        toast.success(data.message);
        if (state?.user?.role === ROLES?.ADMIN) {
          if (typeof callback === 'function') {
            callback();
          }
        } else {
          refreshUser();
        }

        return data;
      } catch (e) {
        toast.error(e?.response?.data?.message);
      }
      return undefined;
    },
    [refreshUser, state?.user?.role],
  );

  const changePassword = async (formData: any, callback?: () => void) => {
    try {
      const { data } = await axiosService?.post(API_URL?.CHANGE_PASSWORD, {
        ...formData,
      });
      if (data) {
        if (typeof callback === 'function') {
          callback();
        }
      }
      toast.success(data.message);
      return data;
    } catch (e) {
      toast.error(e?.response?.data?.message);
    }
    return undefined;
  };
  // for send otp during reset password
  const sendVerificationCode = useCallback(async (values, callback?: () => void) => {
    try {
      const { data } = await axiosService.post(API_URL?.SEND_OTP, values);
      if (data?.data?.status) {
        if (typeof callback === 'function') {
          callback();
        }

        toast.success(data.message);
      } else {
        toast.error('User not found');
      }
    } catch (e) {
      console.error(e);
      toast.error(e?.response?.data?.message);
    }
  }, []);
  // for otp verification during reset password
  const verifyUserOTP = useCallback(async (values) => {
    try {
      const { data } = await axiosService.post(API_URL?.VERIFY_OTP, {
        ...values,
      });

      return data;
    } catch (e) {
      console.error(e);
      return null;
    }
  }, []);

  const resetPassword = useCallback(
    async (values: { newPassword: string; token: string }, callback?: () => void) => {
      try {
        const { data } = await axiosService.post(API_URL?.RESET_PASSWORD, {
          ...values,
        });
        toast.success(data.message);

        if (callback && typeof callback === 'function') {
          callback();
        }
      } catch (e) {
        console.error(e);
        toast.error(e.message);
        return null;
      }
    },
    [],
  );

  const toggleSignupLoggedIn = (val: boolean) => dispatch(actions.setSignupLoggedIn(val));

  const patientSignup = useCallback(
    async (values, callback?: () => void) => {
      try {
        const { data } = await axiosService.post(API_URL?.PATIENT_REGISTER, {
          ...values,
        });
        toast.success(data.message);
        loginUser(
          { email: values?.email, password: values?.password, remember_me: false },
          ROLES?.PATIENT,
          true,
        );

        if (typeof callback === 'function') {
          callback();
        }
      } catch (e) {
        toast.error(e?.response?.data?.message);
      }
    },
    [loginUser],
  );

  const sendPatientVerificationCode = useCallback(async (formData: any, callback?: () => void) => {
    try {
      const { data } = await axiosService.post(API_URL?.PATIENT_SEND_OTP, {
        ...formData,
      });
      if (data?.data?.status) {
        if (typeof callback === 'function') {
          callback();
        }

        toast.success(data?.message);
      }
    } catch (e) {
      console.error(e);
      toast.error(e?.response?.data?.message);
    }
  }, []);

  const verifyPatientUserOTP = useCallback(async (values: { OTP: string; email: string }) => {
    try {
      const { data } = await axiosService.post(API_URL?.PATIENT_VERIFY_OTP, {
        ...values,
      });

      return data;
    } catch (e) {
      console.error(e);
      return null;
    }
  }, []);
  const setFCMToken = (val: string) => dispatch(actions.setFCMToken(val));

  return {
    state,
    actions: {
      AddFamilyMember,
      AddExistingMember,
      EditFamilyMember,
      refreshUser,
      DeleteFamilyMember,
      postalCode,
      logout,
      loginUser,
      updateUserProfile,
      changePassword,
      sendVerificationCode,
      verifyUserOTP,
      verifyPatientUserOTP,
      resetPassword,
      patientSignup,
      sendPatientVerificationCode,
      toggleSignupLoggedIn,
      setFCMToken,
      getAdminLoginVerificationOtp,
      authenticateAdminLogin,
    },
  };
});

export default useAuth;
