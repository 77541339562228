import React, { useState } from 'react';
import { ChevronRightIcon } from '@heroicons/react/outline';
import { Text } from 'molecules/Text';
import docxIcon from '../../assets/icons/docx-icon.svg';
import pdfIcon from '../../assets/icons/pdf-icon.svg';

type AppointmentPatientCardProps = {
  handleClick?: (e: any) => any;
  relation: string;
  fullname: string;
  symptoms?: string;
  symptomimage1?: any | string;
  symptomimage2?: any | string;
};

const AppointmentPatientCard = ({
  handleClick,
  relation,
  fullname,
  symptoms,
  symptomimage1,
  symptomimage2,
}: AppointmentPatientCardProps) => {
  //
  const [showsymptoms, setShowSymptoms] = useState(false);

  const imagepop1 = (symptomimage1: string) => {
    if (symptomimage1) {
      window.open(symptomimage1, '_blank');
    }
  };

  const imagepop2 = (symptomimage2: string) => {
    if (symptomimage2) {
      window.open(symptomimage2, '_blank');
    }
  };

  return (
    <>
      <div className="break-all border-t border-gray-100 font-semibold bg-white rounded shadow hover:shadow-md">
        <div
          className={`flex justify-between items-center p-3 py-2 ${
            handleClick && 'cursor-pointer'
          }`}
          onClick={handleClick}
        >
          <span className="text-primary capitalize w-3/4">{fullname}</span>

          <div className="flex items-center">
            <span className="text-gray-600 capitalize text-sm">{relation}</span>
            {handleClick && <ChevronRightIcon className="w-6 h-6 ml-2" />}
          </div>
        </div>

        <div className="border-t p-3 text-sm">
          {symptoms ? (
            <Text id="patient.primary.symptoms" className="font-medium" />
          ) : (
            <Text id="patient.no.primary.symptoms" className="font-medium italic text-gray-500" />
          )}

          <div className="text-gray-600 text-sm mt-2">
            {symptoms && symptoms?.length > 120 && !showsymptoms
              ? symptoms?.slice(0, 100) + '...'
              : symptoms}

            {symptoms && symptoms?.length > 120 && (
              <span
                className="text-blue-400 cursor-pointer ml-2 "
                onClick={() => setShowSymptoms(!showsymptoms)}
              >
                {showsymptoms ? 'show less' : 'show more'}
              </span>
            )}
          </div>
        </div>
        {(symptomimage1 || symptomimage2) && (
          <div
            className={`${symptomimage1 || symptomimage2 ? 'border-t' : ''} flex gap-4 p-3 text-sm`}
          >
            {symptomimage1 ? (
              <span className="border-2 border-gray-300 cursor-pointer hover:shadow-corner">
                <img
                  src={
                    symptomimage1.endsWith('.pdf')
                      ? pdfIcon
                      : symptomimage1.endsWith('.png') ||
                        symptomimage1.endsWith('.jpg') ||
                        symptomimage1.endsWith('.jpeg')
                      ? symptomimage1.replace(/"([^"]+(?="))"/g, '$1')
                      : docxIcon
                  }
                  className="h-12 w-12 "
                  alt=""
                  onClick={() => imagepop1(symptomimage1.replace(/"([^"]+(?="))"/g, '$1'))}
                />
              </span>
            ) : (
              <span></span>
            )}
            {symptomimage2 ? (
              <span className="border-2 border-gray-300 cursor-pointer hover:shadow-corner">
                <img
                  src={
                    symptomimage2.endsWith('.pdf')
                      ? pdfIcon
                      : symptomimage2.endsWith('.png') ||
                        symptomimage2.endsWith('.jpg') ||
                        symptomimage2.endsWith('.jpeg')
                      ? symptomimage2.replace(/"([^"]+(?="))"/g, '$1')
                      : docxIcon
                  }
                  className="h-12 w-12 "
                  alt=""
                  onClick={() => imagepop2(symptomimage2.replace(/"([^"]+(?="))"/g, '$1'))}
                />
              </span>
            ) : (
              <span></span>
            )}
          </div>
        )}
      </div>
    </>
  );
};
export default AppointmentPatientCard;
