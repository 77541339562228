import React, { useCallback, useEffect, useMemo } from 'react';
import { debounce } from 'lodash';
import { Formik, Form } from 'formik';
import { SearchIcon } from '@heroicons/react/outline';
import { InputEvent } from 'typings/syntheticEvents';
import { useSearchQuery } from 'utils/hooks/useSearchQuery';
import { useLocationQuery, useLocationQuerySet } from 'utils/hooks/useLocationQuery';
import { LocaleString } from 'locales';
import { useIntl } from 'react-intl';

export const SearchBar = ({ placeholder }: { placeholder: LocaleString }) => {
  const query = useSearchQuery();
  const searchParams = useLocationQuery();
  const setQuery = useLocationQuerySet();

  const changeSearchQuery = useCallback(
    (value?: string) => {
      searchParams.page = '1';
      if (value) {
        searchParams.q = value;
      } else {
        delete searchParams.q;
      }
      setQuery(searchParams);
    },
    [setQuery, searchParams],
  );

  const debouncedInput = useMemo(() => debounce(changeSearchQuery, 500), [changeSearchQuery]);

  useEffect(() => {
    return debouncedInput.cancel();
  }, [debouncedInput]);

  const handleChange = useCallback(
    (e: InputEvent) => {
      debouncedInput(e.currentTarget.value);
    },
    [debouncedInput],
  );

  const onSubmitHandler = useCallback(
    ({ search }) => changeSearchQuery(search),
    [changeSearchQuery],
  );
  const intl = useIntl();

  return (
    <Formik onSubmit={onSubmitHandler} initialValues={{ search: query }}>
      {({ values: { search }, setFieldValue }) => (
        <Form>
          <div className="bg-white shadow p-1 mb-5  rounded-full border-grey-light border flex">
            <button type="submit" className="ml-1 mr-2">
              <SearchIcon className=" text-primary w-4" />
            </button>
            <input
              className="w-full rounded pr-0.5 py-2 outline-none text-sm"
              autoComplete="off"
              placeholder={placeholder ? intl.formatMessage({ id: placeholder }) : undefined}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                handleChange(e as InputEvent);
                setFieldValue('search', e.target.value);
              }}
              type="search"
              name="search"
              value={search}
            />
          </div>
        </Form>
      )}
    </Formik>
  );
};
