import React from 'react';
import Modal from 'molecules/ConfirmModalTemplate';
import { useFormSubmitWithLoading } from 'utils/hooks/useFormSubmitWithLoading';
import { Button, CancelButton } from 'molecules/Button';
import { Text } from 'molecules/Text';
import { capitalize } from 'lodash';

type ConsentConfirmActionModalProps = {
  onClose: () => any;
  open: boolean;
  action?: string;
  mode?: string;
  onSubmit?: () => void;
};

const ConsentConfirmActionModal = ({
  onClose,
  open,
  onSubmit,
  action,
  mode,
}: ConsentConfirmActionModalProps) => {
  const { onSubmitHandler, loading } = useFormSubmitWithLoading(onSubmit);
  const mainAction =
    action === 'APPROVED' ? 'Approve' : action === 'REJECTED' ? 'Reject' : 'Withdraw';
  return (
    <Modal headerTextId="confirmation" open={open} onClose={onClose}>
      {/* <div className="w-full mt-6 shadow-corner">
        <PatientProfileCard patientDetails={patientDetails} deleteFamilyMember />
      </div> */}
      <div className="mx-auto">
        <div className="flex justify-center items-center my-12">
          <Text className="flex justify-center text-gray-700 font-bold text-base">
            Are you sure, you want to {mainAction} this {capitalize(mode)}?
          </Text>
        </div>

        <div className="flex sm:flex-row flex-col justify-center gap-3 sm:gap-6 mt-4">
          <Button
            textid="confirm"
            loading={loading}
            onClick={onSubmitHandler}
            className="px-0 justify-center w-auto sm:px-16 sm:py-3"
          />
          <CancelButton
            type="button"
            textid="cancel"
            onClick={() => {
              onClose();
            }}
            className="px-0 justify-center w-auto sm:px-16 sm:py-3"
          ></CancelButton>
        </div>
      </div>
    </Modal>
  );
};

export default ConsentConfirmActionModal;
