import { useCallback, useReducer } from 'react';
import { toast } from 'react-toastify';
import { createContainer, createReducer, createAsyncActions } from 'utils/context';
import axiosService from '../utils/api/axios';

const initialState = {
  AdminDashboardData: '',
  adminDashboardLoading: false,
  soapNoteTemplatesList: [],
  soapNoteTemplatesLoading: false,
};
const actions = {
  adminDashboardData: createAsyncActions('DASHBOARD_DETAILS'),
  soapNoteTemplatesData: createAsyncActions('SOAP_NOTE_TEMPLATE'),
};

const dashboardReducer = createReducer({
  [`${actions.adminDashboardData.loading}`]: (state) => ({
    ...state,
    adminDashboardLoading: true,
  }),
  [`${actions.adminDashboardData.success}`]: (state, { payload }) => ({
    ...state,
    AdminDashboardData: payload,
    adminDashboardLoading: false,
  }),
  [`${actions.adminDashboardData.failure}`]: (state) => ({
    ...state,
    adminDashboardLoading: false,
  }),

  [`${actions.soapNoteTemplatesData.loading}`]: (state) => ({
    ...state,
    soapNoteTemplatesLoading: true,
  }),
  [`${actions.soapNoteTemplatesData.success}`]: (state, { payload }) => ({
    ...state,
    soapNoteTemplatesList: payload,
    soapNoteTemplatesLoading: false,
  }),
  [`${actions.soapNoteTemplatesData.failure}`]: (state) => ({
    ...state,
    soapNoteTemplatesLoading: false,
  }),
});

export const { useContext: getAdminDashboard, Provider: AdminDashboardProvider } = createContainer(
  () => {
    const [state, dispatch] = useReducer(dashboardReducer, initialState);

    const getAdminDashboardData = async () => {
      try {
        dispatch(actions.adminDashboardData.loading());

        const { data } = await axiosService?.get(`/appointment/admin-dashboard`);
        dispatch(actions.adminDashboardData.success(data?.data));

        return data?.data;
      } catch (e) {
        // @ts-ignore
        toast.error(e?.response?.data?.message);
        dispatch(actions.adminDashboardData.failure());
      }
      return undefined;
    };
    const getSoapNoteTemplates = useCallback(async () => {
      try {
        dispatch(actions.soapNoteTemplatesData.loading());

        const { data } = await axiosService?.get(`/appointment/list-soapnote-template`);
        dispatch(actions.soapNoteTemplatesData.success(data?.data?.status));
        return data?.data;
      } catch (e) {
        // @ts-ignore
        toast.error(e?.response?.data?.error);
        dispatch(actions.soapNoteTemplatesData.failure());
      }
      return undefined;
    }, []);

    const createSoapNoteTemplate = useCallback(async (values: any) => {
      try {
        const { data } = await axiosService?.post(`/appointment/add-soapnote-template`, values);
        return data;
      } catch (e) {
        // @ts-ignore
        toast.error(e?.response?.data?.error);
      }
      return undefined;
    }, []);

    const updateSoapNoteInAppointmentDetails = useCallback(async (values: any) => {
      try {
        const { data } = await axiosService?.put(`/appointment/update-soapNoteTemplate`, values);
        return data;
      } catch (e) {
        // @ts-ignore
        toast.error(e?.response?.data?.error);
      }
      return undefined;
    }, []);

    const editSoapNoteTemplate = useCallback(async (templateId: string, values: any) => {
      try {
        const { data } = await axiosService?.post(`/appointment/update-soapnote-template`, {
          ...values,
          id: templateId,
        });
        return data;
      } catch (e) {
        // @ts-ignore
        toast.error(e?.response?.data?.error);
      }
      return undefined;
    }, []);

    const deleteSoapNoteTemplate = useCallback(async (templateId: string) => {
      try {
        const { data } = await axiosService?.delete(
          `appointment/soapnote-template/${templateId}`,
          {},
        );
        return data;
      } catch (e) {
        toast.error(e?.response?.data?.error);
      }
      return undefined;
    }, []);

    return {
      state,
      actions: {
        getAdminDashboardData,
        createSoapNoteTemplate,
        deleteSoapNoteTemplate,
        getSoapNoteTemplates,
        editSoapNoteTemplate,
        updateSoapNoteInAppointmentDetails,
      },
    };
  },
);

export default getAdminDashboard;
