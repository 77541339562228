import React from 'react';
import axios from 'axios';

import { BrowserRouter as Router } from 'react-router-dom';
import './styles/global.scss';
import 'tailwindcss/tailwind.css';
import ScrollToTop from 'utils/scrollToTop';
import { composeComponents } from 'utils/component';
import { AuthGate } from 'RoutesSettings/AuthGate';
import { LanguageProvider } from 'locales/LanguageProvider';
import { AuthProvider } from 'contexts/AuthenticationContext';
import { MedicalHistoryProvider } from 'contexts/ChartsContext';
import { AdminProviderProvider } from 'contexts/AdminProviderContext';
import { AdminServices } from 'contexts/AdminServicesContext';
import { AppointmentProvider } from 'contexts/AppointmentsContext';
import { NotificationProvider } from 'contexts/NotificationContext';
import { AdminPatientProvider } from 'contexts/AdminPatientContext';
import { ProfileProvider } from 'contexts/ProfileDetailContext';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Notifications from 'molecules/Notifications/Notifications';
import { AdminAppointmentProvider } from 'contexts/AdminAppointmentContext';
import { AdminDashboardProvider } from 'contexts/AdminDashboardContext';
import PushNotification from 'molecules/Notifications/PushNotification';
import { ChatInboxListProvider } from 'modules/AdminPanel/AdminDashboard/AdminComponent/AdminInbox/AdminInboxChatContext';
import { DxCodeProvider } from 'contexts/DxCodesContext';
import { PaymentCardsProvider } from 'contexts/PaymentCardContext';
import { SuperAdminProvider } from 'contexts/SuperAdminContext';
import { PaymentsProvider } from 'contexts/PaymentsContext';
import { PaymentByBBPOSProvider } from 'contexts/PaymentByPOSContext';
import { PrometheusProvider } from 'contexts/PrometheusContext';

axios.defaults.baseURL = process.env.REACT_APP_API_HOST;

function App() {
  const Providers = composeComponents(
    NotificationProvider,
    PaymentCardsProvider,
    AppointmentProvider,
    AuthProvider,
    AdminProviderProvider,
    AdminServices,
    AdminPatientProvider,
    ProfileProvider,
    MedicalHistoryProvider,
    AdminAppointmentProvider,
    AdminDashboardProvider,
    ChatInboxListProvider,
    DxCodeProvider,
    SuperAdminProvider,
    PaymentsProvider,
    PaymentByBBPOSProvider,
    PrometheusProvider,
  );

  return (
    <>
      <Providers>
        <LanguageProvider>
          <ToastContainer hideProgressBar autoClose={2000} limit={1} />

          <Router>
            <ScrollToTop />
            <AuthGate />
            <PushNotification />
            <Notifications />
          </Router>
        </LanguageProvider>
      </Providers>
    </>
  );
}

export default App;
